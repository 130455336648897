export enum keywordsTypes {
    'BALL' = 1,
    'SURFACE' = 2,
    'SPORT' = 3,
    'TOURNAMENT_TYPE' = 4,
}

export enum fieldTypesEnum {
    'KEYWORD',
    'PADDLE',
    'SPONSOR',
    'TOUR',
}