import i18n from 'i18next';
import { en, fr, es } from '../../translations';
import { initReactI18next } from 'react-i18next';
import { getLang } from '../../utils/getLang';

const resources = {
    en: {
        translation: en,
    },
    es: {
        translation: es,
    },
    fr: {
        translation: fr,
    },
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    lng: getLang(),
    keySeparator: false,
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
});
