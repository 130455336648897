import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';

import { Team } from '../../../../../types/tournament';
import { RoundedAvatar } from '../../../../../components';

interface TeamPlayCardProps {
    onClick?: (arg: string | number) => void;
    team: Team;
}

export const TeamPlayCard: FC<TeamPlayCardProps> = (props) => {
    const {
        onClick,
        team: { id, name, logo, seed },
    } = props;

    return (
        <div
            style={{ minHeight: '43px' }}
            className="creative-tour-detail__gray-item d-flex flex-lg-grow-0 flex-grow-1 justify-content-lg-center justify-content-between align-items-center mx-1 border my-2 br-16"
>
        {(logo && (<RoundedAvatar
                        name="flag"
                        avatar={logo}
                        alt="Team logo"
                    />)) || (<div className="raqt__seed-holder">
                                {seed}
                            </div>)}
            <div className="d-flex flex-grow-1">
                <span className="px-2">{seed !== undefined ? `${name} (${seed})` : name}</span>
            </div>
            {onClick && (
                <Button
                    className="border-0 btnDeleteMember"
                    variant="outline-dark"
                    onClick={() => onClick(id)}
                >
                    <CloseCircle />
                </Button>
            )}
        </div>
    );
};
