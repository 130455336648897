import { SvgIcon } from '../../components';

interface OpenIconProps {
    w?: string;
    h?: string;
    f?: string;
    s?: string;
}


export const OpenIcon = ({ w, h, f, s }: OpenIconProps) => {
    return (
        <SvgIcon width={w || '18'} height={h || '22'} viewBox="0 0 18 22">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M0.839844 10.9199V18.9199C0.839844 19.4504 1.05056 19.9591 1.42563 20.3341C1.8007 20.7092 2.30941 20.9199 2.83984 20.9199H14.8398C15.3703 20.9199 15.879 20.7092 16.2541 20.3341C16.6291 19.9591 16.8398 19.4504 16.8398 18.9199V10.9199M12.8398 4.91992L8.83984 0.919922M8.83984 0.919922L4.83984 4.91992M8.83984 0.919922L8.83984 13.9199"
                fill={f || 'none'}
                stroke={s || '#FAFAFA'} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
