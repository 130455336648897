import React, { useEffect } from 'react';
import ScaleText from "react-scale-text";
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchTournaments } from '../../features/tournaments/actionCreators';
import { TypeError } from '../../types/enums';
import { createTournament } from '../../apis/tournament';
import {
    clearTournamentsNotifications,
    tournamentsFetchingError,
} from '../../features/tournaments/tournamentsSlice';
import { CustomButton, CustomSpinner } from '../../components';
import { clearClass } from '../../features/class/classSlice';
import { Notification } from '../../hooks/useNotification';
import { getIsLoading, getOrganizationStats, useGetCurrentOrganization } from '../../features/organizations/organizationsSelectors';

import './CreativeDashboard.scss';
import { getReadableDate } from '../../utils/getStringDate';
import { AddIcon } from '../../assets/icons/AddIcon';
import { SettingsIcon } from '../../assets/icons/SettingsIcon';
import { selectTournaments } from '../../features/tournaments/tournamentsSelectors';

export const CreativeDashboard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const currentOrganization = useGetCurrentOrganization();
    const organizationIsLoading = useAppSelector(getIsLoading);

    if(!organizationIsLoading && !currentOrganization) {
        history.push('/communities')
    }

    const { data, isLoading, error, message } = useAppSelector(selectTournaments);

    const stats = useAppSelector(getOrganizationStats);

    const prefix = 'creative-';

    useEffect(() => {
        dispatch(clearClass());
        currentOrganization && dispatch(fetchTournaments(currentOrganization.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganization?.id]);

    useEffect(() => {
        dispatch(clearTournamentsNotifications());
    }, [dispatch, error, message]);

    if(isLoading) {
        return (
            <div className="px-lg-5 px-sm-2 d-flex flex-grow-1 flex-column">
                <CustomSpinner />
            </div>
        );
    }

    if (error) {
        Notification(TypeError.error, error);
    }

    if (message) {
        Notification(TypeError.success, t(message));
    }

    const goToTournament = (id: number | string) => currentOrganization && history.push(`/community/${currentOrganization.id}/tournament/${id}`);

    const createNewTournament = async () => {
        if(!currentOrganization) return;

        try {
            const tournament = await createTournament(currentOrganization.id);
            goToTournament(tournament.id);
        } catch (e: any) {
            dispatch(tournamentsFetchingError(e.message));
            history.push(`/community/${currentOrganization.id}`);
        }
    };

    return (
        <div className="creative-dashboard">
            {currentOrganization && <div className="creative-dashboard__community-name">
                { currentOrganization.name }
            </div>}
            {currentOrganization && <div className="creative-dashboard__community-settings">
                <div className="creative-dashboard__actionButtons">
                    <CustomButton 
                        className="creative-dashboard__community-settings-button" 
                        btnText={t(prefix + 'settings')}
                        onClick={() => history.push(`/community/${currentOrganization.id}/settings`)}>
                            <SettingsIcon h="22" w="22" />
                    </CustomButton> 

                    <CustomButton 
                        className="creative-dashboard__community-settings-button" 
                        btnText={t(prefix + 'createTournament')}
                        onClick={createNewTournament}>
                            <AddIcon h="22" w="22" />
                    </CustomButton> 
                </div>
            </div>} 

            {/*<div className="creative-dashboard__create-instances">
                 <div role="button" onClick={createNewTournament} className="creative-dashboard__row creative-dashboard__card-wrapper">
                    <div className="creative-dashboard__card">
                        <div className="creative-dashboard__card-first">
                            <h5><strong>{t(prefix + 'createTournament')}</strong></h5>
                            
                        </div>
                        <div className="creative-dashboard__card-second_to-bottom">
                            <AddIcon h="30" w="30"/>
                        </div>
                    </div>           
                </div>
                <div className="creative-dashboard__row creative-dashboard__card-wrapper creative-dashboard__card-disabled">
                    <div className="creative-dashboard__card">
                        <div className="creative-dashboard__card-first">
                            <h5><strong>{t(prefix + 'createEvent')}</strong></h5>
                            <p>{t(prefix + 'eventDesc')}</p>
                        </div>
                        <div className="creative-dashboard__card-second_to-bottom">
                            <AddIcon h="30" w="30"/>
                        </div>
                    </div>           
                </div>
                <div className="creative-dashboard__row creative-dashboard__card-wrapper creative-dashboard__card-disabled">
                    <div className="creative-dashboard__card">
                        <div className="creative-dashboard__card-first">
                            <h5><strong>{t(prefix + 'createTour')}</strong></h5>
                            <p>{t(prefix + 'tourDesc')}</p>
                        </div>
                        <div className="creative-dashboard__card-second_to-bottom">
                            <AddIcon h="30" w="30"/>
                        </div>
                    </div>           
                </div> 
            </div>*/}
            {stats && <div className="container-fluid">
                {/* <div className="creative-dashboard__section-title">{t(prefix + 'stats')}</div> */}
                <div className="creative-dashboard__stats">
                    <div className="creative-dashboard__stats-item">
                        <div className="creative-dashboard__stats-quantity">
                            <ScaleText maxFontSize={32}>
                                {stats.totalFollowers}
                            </ScaleText>
                        </div>
                        <div className="creative-dashboard__stats-value-name">
                            {t(prefix + 'statsFollowers')}
                        </div>
                    </div>
                    <div className="creative-dashboard__stats-item">
                        <div className="creative-dashboard__stats-quantity">
                            <ScaleText maxFontSize={32}>
                                {stats.totalTournaments}
                            </ScaleText>
                        </div>
                        <div className="creative-dashboard__stats-value-name">
                            {t(prefix + 'statsTournaments')}
                        </div>
                    </div>
                    <div className="creative-dashboard__stats-item">
                        <div className="creative-dashboard__stats-quantity">
                            <ScaleText maxFontSize={32}>
                                {stats.totalTickets}
                            </ScaleText>
                        </div>
                        <div className="creative-dashboard__stats-value-name">
                            {t(prefix + 'statsTickets')}
                        </div>
                    </div>
                    <div className="creative-dashboard__stats-item">
                        <div className="creative-dashboard__stats-quantity">
                            <ScaleText maxFontSize={32}>
                                {stats.totalMatches}
                            </ScaleText>
                        </div>
                        <div className="creative-dashboard__stats-value-name">
                            {t(prefix + 'statsMatches')}
                        </div>
                    </div>
                </div>
            </div>}
            <div>
                <div className="creative-dashboard__section-title">{t(prefix + 'tournaments')} & Events</div>
                <div className="creative-dashboard__tournaments-holder">
                    {data.length ? (
                        data.map((tournament) => 
                            <div role="button" onClick={() => goToTournament(tournament.id)} key={`tournament-key-${tournament.id}`} className="creative-dashboard__tournaments-card">
                                <div>
                                    <img className="creative-dashboard__tournament-img" src={tournament.img || require('../../assets/images/noImage.png')} alt="" />
                                </div>
                                <div className="creative-dashboard__tournamentName">{tournament.name}</div>
                                <div className="creative-dashboard__date">{tournament.startDate && getReadableDate(tournament.startDate)}</div>
                            </div>)
                    ) : (
                        <h4>{t(prefix + 'noTour')}</h4>
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};
