import React, { FC, useState } from 'react';
import { useAccordionButton } from 'react-bootstrap';

interface CustomAccordionBtnProps {
    children: React.ReactNode;
    eventKey: string;
}

export const CustomAccordionHeader: FC<CustomAccordionBtnProps> = (props) => {
    const { children, eventKey } = props;

    const [click, setClick] = useState(true);

    const decoratedOnClick = useAccordionButton(eventKey, () => {
        setClick((prevState) => !prevState);
    });

    return (
        <div className="d-flex justify-content-between align-items-center py-2 px-2">
            <div className="w-75">{children}</div>
            <div
                onClick={decoratedOnClick}
                className={click ? 'collapsed accordion-button' : 'accordion-button'}
            />
        </div>
    );
};
