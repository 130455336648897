import { FC } from 'react';

import { TournamentCardHeadSection } from '../TournamentCardHeadSection';
import { TournamentCardScoreSection } from '../TournamentCardScoreSection';
import { TournamentCardTeamSection } from '../TournamentCardTeamSection';
import { TournamentPrintCardSignatureSection } from '../TournamentPrintCardSignatureSection';

import './TournamentPrintCardTeam.scss';

interface TournamentPrintCardTeamProps {
    matchId: string;
    team: number;
    court?: string;
    player1?: string;
    player2?: string;
}

export const TournamentPrintCardTeam: FC<TournamentPrintCardTeamProps> = ({team, matchId, court, player1, player2}) => (        
<div className="tournament-print-card-team__card-wrapper">
    <TournamentCardHeadSection matchId={matchId} court={court}/>
    <TournamentCardTeamSection team={team} player1={player1} player2={player2}/>
    <TournamentCardScoreSection />
    <TournamentPrintCardSignatureSection team={team}/>
</div>);