import { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import { ActionMeta, StylesConfig } from 'react-select';
import Select from 'react-select';
import { useAppSelector } from '../../../../../hooks/redux';
import { fetchTeamsData } from '../../../../../apis/tournament';
import { ModalBox, RoundedAvatar } from '../../../../../components';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ClassTeam } from '../../../TournamentClasses/components/ClassesTag/ClassesTag';

type Option = { value: number; label: string; avatar: string | null };

export const customStyles: StylesConfig = {
    control: (base) => ({ ...base, borderRadius: '16px', minHeight: '50px' }),
};

interface TeamModalProps extends ModalProps {
    selected?: ClassTeam[];
}

const teamsToOptions = (teams: ClassTeam[]): Option[] => {
    return teams.map((team) => ({
        value: Number(team.id),
        label: team.name,
        avatar: team.logo,
    }));
};

export const TeamModal = ({ selected = [], ...props }: TeamModalProps) => {
    // State hooks
    const { data: modalData } = useAppSelector((state) => state.modals);
    const [selectedTeams, setSelectedTeams] = useState<ClassTeam[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    // This is to start the loading state as soon as we start typing, instead of waiting until
    // the debounced search fires (and updates the useQuery loading state)
    const [loading, setLoading] = useState(true);
    const { data, fetchStatus } = useQuery({
        queryKey: ['teams', searchTerm],
        queryFn: () => fetchTeamsData(modalData.communityId, searchTerm),
        placeholderData: keepPreviousData,
    });

    // We set loading to false after query is done (idle)
    useEffect(() => {
        if (fetchStatus === 'idle') {
            setLoading(false);
        }
    }, [fetchStatus]);

    // Derived state
    const options: Option[] = useMemo(() => {
        return teamsToOptions(data || []);
    }, [data]);

    // Translation hooks
    const { t } = useTranslation();

    const inputPrefix = 'input-';

    // Callback hooks
    const handleSelect = useCallback(
        (newValue: readonly Option[], _actionMeta: ActionMeta<Option>) => {
            const selectedTeams: ClassTeam[] = newValue
                .map((option) => data?.find((team) => team.id === option.value))
                .filter((team) => team !== undefined) as ClassTeam[];
            setSelectedTeams(selectedTeams);
        },
        [data]
    );

    const debouncedSetSearch = useMemo(
        () =>
            debounce((searchValue: string) => {
                setSearchTerm(searchValue);
            }, 500),
        []
    );

    const inputChange = useCallback(
        (value: string) => {
            setLoading(true);
            debouncedSetSearch(value);
        },
        [debouncedSetSearch]
    );

    const handleSave = useCallback(() => {
        const newSelections = selectedTeams.filter(
            (team) => !((modalData.selected as ClassTeam[]) || []).find((om) => om.id === team.id)
        );
        newSelections.forEach((team) => {
            modalData.push({
                id: team.id,
                name: team.name,
                users: team.users || [],
                captain: team.captain || null,
                logo: team.logo || null,
                seed: team.seed || 0,
            });
        });
        props.onHide && props.onHide();
    }, [modalData, selectedTeams, props]);

    return (
        <ModalBox {...props} title={modalData?.title} handleSave={handleSave}>
            <Select
                isLoading={loading}
                isMulti
                name="members"
                className="basic-multi-select holder-select"
                classNamePrefix="select"
                options={options}
                placeholder={t(inputPrefix + 'placeholderSelect') + '...'}
                onChange={handleSelect}
                onInputChange={(value) => inputChange(value)}
                formatOptionLabel={(data) => (
                    <div className="d-flex align-items-center">
                        <RoundedAvatar avatar={data.avatar} alt="User avatar" name="user" />
                        {data.label}
                    </div>
                )}
            />
        </ModalBox>
    );
};
