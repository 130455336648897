import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBox } from '../../../../../components';
import { Form, ModalProps } from 'react-bootstrap';
import { newClassName, setCurrentName } from '../../../../../features/class/classSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import uniqId from 'uniqid';
import { initialData } from '../../../../../constant/class';

export const ClassModal = (props: ModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { classCollection } = useAppSelector((state) => state.class);
    const { data } = useAppSelector((state) => state.modals);
    const inputName = useRef('');

    const inputPrefix = 'creativeDetail-';

    const handleSave = useCallback(() => {
        let newData;
        if (data) {
            const { isTicketSales, isShowSalesButton, stateOfClass, ...rest } = data;
            //TO DO: customer forgot 2 fields in default setting, these are: roundScoring and isTicketSales in classes tag
            // in future it must be taken from "Default settings for classes" and set it up for creating new classes
            newData = {
                classId: uniqId(),
                name: inputName.current,
                isOnSale: isShowSalesButton.toString(),
                stateOfClass,
                rankingType: '300',
                ...rest,
            };
        } else {
            newData = { classId: uniqId(), name: inputName.current };
        }
        dispatch(newClassName({ ...initialData, ...newData }));
        !classCollection.length && dispatch(setCurrentName(newData.classId));
        props.onHide && props.onHide();
    }, [classCollection, data, dispatch, props]);

    return (
        <ModalBox {...props} title={t(inputPrefix + 'className')} handleSave={handleSave}>
            <Form.Control
                placeholder={t(inputPrefix + 'className')}
                ref={inputName as any}
                onChange={(e) => {
                    inputName.current = e.target.value;
                }}
            />
        </ModalBox>
    );
};
