import React, { FC } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import './index.scss';

interface ButtonIconProps extends ButtonProps {
    children: React.ReactElement;
}

export const ButtonIcon: FC<ButtonIconProps> = (props) => {
    const { children, ...rest } = props;

    return (
        <Button {...rest} variant="outline-dark">
            {children}
        </Button>
    );
};
