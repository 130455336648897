import { useField } from 'formik';
import { CheckIcon } from '../../../../../assets/icons/CheckIcon';

interface CustomValueCheckboxProps {
    name: string;
    value: string | number;
    dayKey: number;
}

export const CustomValueCheckbox = ({ name, value, dayKey }: CustomValueCheckboxProps) => {
    const [field] = useField({ name: name, value: value, type: 'checkbox' });

    /*
    The label makes clicking the entire div check the checkbox.
    The input is invisible, but allows formik to perform the computations
    */
    return (
        <div className="checkbox__wrapper">
            <label htmlFor={`${value}-${dayKey}`} style={{ display: 'flex', margin: '0px' }}>
                <div className="checkbox">
                    <input
                        id={`${value}-${dayKey}`}
                        type="checkbox"
                        {...field}
                        style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
                    />
                    {(field.checked && <CheckIcon f="#2C2F30" h="35" w="35" />) || null}
                </div>
            </label>
        </div>
    );
};
