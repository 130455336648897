import React, { FC } from 'react';
import { SvgIcon } from '../../components';

interface CheckCircleIconProps {
    w?: string;
    h?: string;
    f?: string;
}

export const CheckCircleIcon: FC<CheckCircleIconProps> = ({ w='25', h='25', f='#34C765' }) => {
    return (
        <SvgIcon width={w} height={h} viewBox="0 0 50 50">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M25 4.16669C13.5416 4.16669 4.16663 13.5417 4.16663 25C4.16663 36.4584 13.5416 45.8334 25 45.8334C36.4583 45.8334 45.8333 36.4584 45.8333 25C45.8333 13.5417 36.4583 4.16669 25 4.16669ZM20.8333 35.4167L10.4166 25L13.3541 22.0625L20.8333 29.5209L36.6458 13.7084L39.5833 16.6667L20.8333 35.4167Z"
                fill={f}
            />
        </SvgIcon>
    );
};
