import { FC } from 'react';

import { BubbleTabItem } from '../BubbleTabItem';

import './BubbleTabController.scss';

interface BubbleTabControllerProps {
    selected: any;
    data: any[];
    keyToShow?: string;
    callback: (e: any) => void;
}

export const BubbleTabController: FC<BubbleTabControllerProps> = ({selected, data = [], keyToShow, callback }) => {
return (<div className="bubble-tab-controller">
    {data.map((item: any, index: number) => 
        <BubbleTabItem className="bubble-tab-controller__item-wrapper" onClick={() => callback(item)} key={`bubble-key-${index}`} active={selected === item}>
            {keyToShow ? item[keyToShow] : item}
        </BubbleTabItem>)
    }
</div>)};