import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

import { CustomSpinner } from '../../../components';
import {  fetchTournamentClassListPure } from '../../../features/class/classActions';
import { PlayTag } from './components/PlayTag';
import { selectClass } from '../../../features/class/classSelectors';
import { fetchTournamentInfo } from '../../../features/tournament/actionCreators';

export const TournamentPlay = () => {
    const { id } = useParams<{ id: string }>();
    const { isLoading } = useAppSelector(selectClass);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTournamentInfo(+id));
        dispatch(fetchTournamentClassListPure(+id));
    }, [dispatch, id]);

    return <div>
        {(isLoading && (<CustomSpinner />)) || <PlayTag />}
    </div>
};