import { useField } from "formik";
import { FC } from "react";

import './Toggle.scss';

interface ToggleProps {
    name: string;
    disabled?: boolean;
}

export const Toggle: FC<ToggleProps> = ({ name, disabled }) => {
    const [ , meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    const onToggleSelected = () => {
        setValue(!value);
    };
    
    return (
        <div className="toggle-wrapper">
            {disabled && <div className="toggle-wrapper__disabled"></div>}
            <div className={`toggle-container ${value ? "" : "off-container"}`} onClick={onToggleSelected}>
            <div className={`dialog-button ${value ? "" : "off"}`}></div>
            </div>
        </div>
    );
}