import React from 'react';
import { SvgIcon } from '../../components';

interface EditIconProps {
    w?: string;
    h?: string;
    f?: string;
}

export const EditIcon = (props: EditIconProps) => {
    const { w, h, f } = props;

    return (
        <SvgIcon width={w || '25'} height={h || '25'} viewBox="0 0 50 50">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M43.1458 14.6667C43.9583 13.8542 43.9583 12.5 43.1458 11.7292L38.2708 6.85419C37.5 6.04169 36.1458 6.04169 35.3333 6.85419L31.5 10.6667L39.3125 18.4792L43.1458 14.6667ZM6.25 35.9375V43.75H14.0625L37.1042 20.6875L29.2917 12.875L6.25 35.9375Z"
                fill={f || 'black'}
            />
        </SvgIcon>
    );
};
