import { useTranslation } from 'react-i18next';
import { IFreeSlotItem } from '../../../../../types/schedule';
import { getHhmmTimeString } from '../schedule.helpers';
import { useAppSelector } from '../../../../../hooks/redux';
import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';

interface FreeSlotItemProps {
    item: IFreeSlotItem;
}

export const FreeSlotItem = ({ item }: FreeSlotItemProps) => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const { currentFilters } = useAppSelector(selectSchedule);

    const transparentWhenScheduleIsFiltered = currentFilters.length > 0 ? ' opacity-0' : '';
    const fadedWhenScheduleIsFiltered = currentFilters.length > 0 ? ' opacity-25' : '';

    return (
        <div className={'text-light ' + fadedWhenScheduleIsFiltered}>
            <div className="card-header bg-secondary" style={{ height: '41px' }}>
                <div className="row">
                    <div className={'col fs-7' + transparentWhenScheduleIsFiltered}>
                        {t(prefix + 'freeScheduleSlot')}
                    </div>
                </div>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <div className="row">
                        <div className={'col' + transparentWhenScheduleIsFiltered}>
                            {getHhmmTimeString(item.scheduleDate)}
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col opacity-0">-</div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col opacity-0">-</div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col opacity-0">-</div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col fs-7 opacity-0">-</div>
                    </div>
                </li>
            </ul>
        </div>
    );
};
