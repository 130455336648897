import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigItemCard } from './ConfigItemCard';
import { ConfigField } from '../../../types/adminConfiguration';

interface ConfigurationItemProps {
    data: Array<{ id: number | string; value: string; description?: string }>;
    title: string;
    handleDeleteConfigItem: (field: ConfigField, id: number | string) => void;
    name: ConfigField;
}

export const ConfigurationItem: FC<ConfigurationItemProps> = ({
    data,
    title,
    handleDeleteConfigItem,
    name,
}) => {
    const { t } = useTranslation();

    const prefix = 'configuration-';

    return (
        <div className="d-block d-lg-flex align-items-center my-3">
            <div className="col-lg-4 col pb-0 pb-lg-3 pb-lg-0 ps-0">
                <span>{t(prefix + title.toLowerCase())}</span>
            </div>
            <div className="col px-0">
                <div className="d-flex flex-wrap">
                    {data.length ? (
                        data.map((el) => (
                            <React.Fragment key={el.id.toString()}>
                                <ConfigItemCard
                                    title={
                                        name === 'tours'
                                            ? el.value +
                                              ' - ' +
                                              (el.description || 'No description')
                                            : el.value
                                    }
                                    handleDeleteConfigItem={handleDeleteConfigItem.bind(
                                        null,
                                        name,
                                        el.id
                                    )}
                                />
                            </React.Fragment>
                        ))
                    ) : (
                        <div className="d-flex justify-content-end fw-lighter">
                            <p>
                                {t(`${prefix}noData`) +
                                    ' ' +
                                    t(prefix + title.toLowerCase()) +
                                    '...'}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
