import React, { FC } from 'react';
import classnames from 'classnames';

interface RoundedAvatarProps {
    name: 'user' | 'flag';
    avatar: string | null;
    alt: string;
    className?: string;
    noMargin?: boolean;
    height?: string | number;
    width?: string | number;
}

export const RoundedAvatar: FC<RoundedAvatarProps> = ({ avatar, alt, name, height='25', width='25', noMargin, className }) => (
    <img
        className={classnames('bg-white rounded-circle d-block', {'mx-2': !noMargin, 'my-1 ': !noMargin }, className)}
        src={
            avatar
                ? avatar
                : name === 'user'
                ? require('../../assets/images/user.png')
                : require('../../assets/images/noFlag.png')
        }
        width={width}
        height={height}
        alt={alt}
        style={{ objectFit: 'cover' }}
    />
);
