import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { CheckIcon } from '../../assets/icons/CheckIcon';
import { CustomButton } from './Button';

interface SaveButtonProps {
    isLoading: boolean;
    form?: string;
}

export const SaveButton: FC<SaveButtonProps> = ({ isLoading, ...rest }) => {
    const { t } = useTranslation();

    const buttonPrefix = 'button-';

    return (
        <div className=" mb-lg-0 d-flex justify-content-center justify-content-lg-start">
            <CustomButton
                disabled={isLoading}
                type="submit"
                btnText={isLoading ? t(buttonPrefix + 'updating') : t(buttonPrefix + 'save')}
                variant="primary"
                className="d-flex align-items-center justify-content-center"
                { ...rest }
            >
                {isLoading ? (
                    <Spinner size="sm" animation="border" variant="dark" />
                ) : (
                    <CheckIcon />
                )}
            </CustomButton>
        </div>
    );
};
