import { FC } from 'react';

import { IStandingRound } from '../../TournamentScorecardPage.types';
import { TournamentScorecardPageMatchScore } from '../TournamentScorecardPageMatchScore';

import './TournamentScorecardPageMatches.scss';

type TournamentScorecardPageGroupInfoProps = Omit<IStandingRound, 'isGroup' | 'round'>

export const TournamentScorecardPageMatches: FC<TournamentScorecardPageGroupInfoProps> = ({ name, matches = []}) => 
    (<div className="tournament-scorecard-page-matches">
        <div className="tournament-scorecard-page-matches__title">
            { name }
        </div>
        {matches.map((match, index) => (<div key={`tournament-match-index-${index}`} className="tournament-scorecard-page-matches__match-holder">
            <TournamentScorecardPageMatchScore {...match}/>
        </div>))}
    </div>);