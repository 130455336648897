import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'react-bootstrap';
import { useAppSelector } from '../../../../../hooks/redux';
import { ModalBox, RoundedAvatar } from '../../../../../components';
import Select from 'react-select';
import { customStyles } from '../../../../../components/modal/MemberModal';
import { IMatch } from '../PlayTag/PlayTag';
import { Team } from '../../../../../types/tournament';
import { imgOptionTeamLabel } from '../../../../../utils/imgOptionTeamLabel';
import { ClassTypeEnum } from '../../../../../types/enums';
import { isString } from '../../../../../utils/common.helpers';

const oppositeSideTeam = {
    homeTeam: 'awayTeam',
    awayTeam: 'homeTeam'
};

const sideToPlayersRelation = {
    homeTeam: ['homePlayer1', 'homePlayer2'],
    awayTeam: ['awayPlayer1', 'awayPlayer2'],
}

// TO DO: Remade this modal to one of the options - use store or reduce logic as much as possible
export const MatchTeamModal = (props: ModalProps) => {
    const { t } = useTranslation();
    
    const dontKnowText = t('select-dontKnow');

    const dontKnowTeamYet = useMemo(() => ({
        value: 0,
        label: dontKnowText,
        logo: null,
        id: 0,
        name: dontKnowText
    }), [dontKnowText]);

    const { classCollection, currentClassId } = useAppSelector((state) => state.class);
    const { matches, setMatches, matchId, fieldName } = useAppSelector(
        (state) => state.modals.data
    );
    const currentMatch = (Object.values(matches) || []).find(({ id }: any) => id === matchId) || {};
    const [selections, setSelections] = useState<Team>({} as Team);
    const classData = classCollection.find((el) => el.classId === currentClassId);
    const teamOptions = [dontKnowTeamYet, ...(classData?.teams.filter( item => {
        //@ts-ignore-next-line   
        const oppositSideId = (currentMatch[oppositeSideTeam[fieldName]] || {}).id || currentMatch[oppositeSideTeam[fieldName]];
        return oppositSideId !== item.id;
    }).map((team) => imgOptionTeamLabel(team)) || [])];
    //@ts-ignore
    const tournamentMatches = Object.values(matches).map((match: Match) =>
        match.id === matchId ? { ...match, [fieldName]: selections } : match
    );
    const prefix = 'modal-';
    const inputPrefix = 'input-';

    const handleSelect = useCallback(
        (option: { value: string | number; label: string }) => {
            let team;
            if(option.value === dontKnowTeamYet.value) {
                team = Object.assign({}, dontKnowTeamYet);
            } else {
                team = classData?.teams.find((team) => team.id === option.value);
            }

            typeof team?.id === 'number' &&
                setSelections({ id: team.id, logo: team.logo, name: team.name });
        },
        [classData, dontKnowTeamYet]
    );

    const handleSave = () => {
        setMatches(
            'tournamentMatches',
            tournamentMatches.reduce((acc: any, item: any) => {
                if(item.id === matchId) {
                    sideToPlayersRelation[fieldName as 'awayTeam' | 'homeTeam']?.forEach((fieldToChange, index) => {
                        // TO DO: After demo make it properly
                        const user = classData?.teams.find(team => team.id === selections.id)?.users[index] || null;

                        if(ClassTypeEnum.DOUBLES === classData?.type || ClassTypeEnum.TEAMS === classData?.type ||  index === 0) {
                            item[fieldToChange] = user || null;
                        } else {
                            item[fieldToChange] = null;
                        }
                    });

                    if(!item[fieldName].id) {
                        if(!isString(item[`${fieldName}Placeholder`])) {
                            item[`${fieldName}Placeholder`] = '';
                        }

                        item[fieldName] = null;
                    } 
                    // else {
                    //     item[`${fieldName}Placeholder`] = null;
                    // }
                }
                acc[`${item.id}`] = item as IMatch;
                return acc;
            }, {})
        );
        props.onHide && props.onHide();
    };

    return (
        <ModalBox {...props} title={t(prefix + 'addTeam')} handleSave={handleSave}>
            <Select
                styles={customStyles}
                name="matchTeam"
                placeholder={t(inputPrefix + 'placeholderSelect')}
                options={teamOptions}
                className="basic-single holder-select"
                classNamePrefix="select"
                // @ts-ignore
                onChange={handleSelect}
                formatOptionLabel={(data: any) => (
                    <div className="d-flex align-items-center">
                        {(data.logo && (<RoundedAvatar
                        name="flag"
                        avatar={data.logo}
                        alt="Flag image"
                    />)) || (<div className="raqt__seed-holder">
                                {data.seed}
                            </div>)}
                        {data.label}
                    </div>
                )}
            />
        </ModalBox>
    );
};
