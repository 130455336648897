import { TournamentState } from '../types/tournament';
import { api } from './api.config';

export interface IOrganization {
    id: number;
    name: string;
    img?: string;
    type?: number;
    country?: number;
    verified?: boolean;
    description?: string;
}

export interface IOrganizationStats {
    totalTournaments: number,
    totalTickets: number,
    totalMatches: number,
    totalFollowers: number,
}

export interface IOrganizationWithStatsAndTournaments {
    organization: Pick<IOrganization, 'id' | 'name'>,
    stats: IOrganizationStats,
    tournaments: TournamentState[],
}

export interface IFetchOrganizationAllAdminsResponse {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    avatar: string;
    role: number;
}

export interface IAddRemoveAdminsProps {
    userIds: number[],
    organizationId:number
}

// verify-availability isAvailable
export const fetchOrganizationsListAPI = async (): Promise<{organizations: IOrganization[]}> => {
    const response = await api.get('organization/list');
    return response.data;
};

export const fetchOrganizationAPI = async (id: number | string): Promise<IOrganizationWithStatsAndTournaments> => {
    const response = await api.get(`organization/${ id }`);
    return response.data;
};

export const fetchOrganizationAllAdminsAPI = async (id: number | string): Promise<IFetchOrganizationAllAdminsResponse[]> => {
    const response = await api.get(`organization/${ id }/admins`);
    return response.data;
};

export const addAdminsToOrganisationAPI = async (values: IAddRemoveAdminsProps): Promise<IFetchOrganizationAllAdminsResponse[]> => {
    const response = await api.post(`organization/set-admins`, values);
    return response.data;
};

export const removeAdminsFromOrganisationAPI = async (values: IAddRemoveAdminsProps): Promise<IFetchOrganizationAllAdminsResponse[]> => {
    const response = await api.delete(`organization/remove-admins`, { 
        data: values
     });
    return response.data;
};

export const verifyAvailabilityOrganizationAPI = async (value: { name: string }): Promise<{isAvailable: boolean}> => {
    const response = await api.get('organization/verify-availability', {params: value});
    return response.data;
}

export const createOrganizationAPI = async (value: Omit<IOrganization, 'id'>): Promise<IOrganization> => {
    const response = await api.post('organization/create', value);
    return response.data;
}

export const updateOrganizationAPI = async (value: IOrganization): Promise<IOrganization> => {
    const response = await api.patch('organization/update', value);
    return response.data;
}
