import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { fetchPlayersInfoAPI} from '../../apis/tournament';
import { RaqtTable, Switcher } from '../../components';
import { IRaqtTableConfig } from '../../components/table/RaqtTable';
import { selectClassList } from '../../features/class/classSelectors';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { BackIcon } from '../../assets/icons/BackIcon';
import { fetchTournamentClassListPure } from '../../features/class/classActions';

import './TournamentPlayersStats.scss';

interface IPlayerList {
    id: number;
    name: string;
    teamName: string;
    seed: number;
}

export const TournamentPlayersStats = () => {
    const prefix = 'tournamentStatsPlayers-';

    const { t } = useTranslation();
    const [playerList, setPlayerList] = useState<IPlayerList[]>([]);
    const dispatch = useAppDispatch();
    // const [isLoading, setIsloading] = useState(false);
    const [classId, setClassId] = useState<number | null>(null);
    const { id , communityId } = useParams<{ id: string, communityId: string }>();
    const classes = useAppSelector(selectClassList);

    const classList = [
        {label: 'All', value: null},
        ...classes.map(({classId, name}) => ({
            value: classId,
            label: name,
        }))
    ];

    const getMatches = async () => {
        // setIsloading(true);

        try {
            const { playersInfo } = await fetchPlayersInfoAPI(+id);

            const data = playersInfo.reduce((acc, value) => {
                const formatedData = value.teams.map(({name, seed}) => ({
                    id: value.id,
                    name: value.name,
                    teamName: name,
                    seed,
                }))
                return [...acc, ...formatedData];
            }, [] as IPlayerList[]);
            setPlayerList(data);
        } catch (e) {
            setPlayerList([]);
        }

        // setIsloading(false);
    }

    const dataToShow = useMemo(() => {
        if(classId === null) return playerList;

        return playerList.filter(({id}) => id === classId);
    },[classId, playerList])

    const tableConfig: IRaqtTableConfig = {
        blankHead: true,
        columns: [
            {
                label: t(prefix + 'class'),
                key: 'name',
                position: 'left',
            },
            {
                label: t(prefix + 'team'),
                key: 'teamName',
                position: 'left',
                columnSize: 'big'
            },
            {
                label: t(prefix + 'rank'),
                key: 'seed',
                position: 'left',
                columnSize: 'small',
            },
        ]
    }

    useEffect(() => {
        getMatches();
        !classes.length && dispatch(fetchTournamentClassListPure(+id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, classId, classes]) 
    
    return <div className="tournament-players-stats">
        <div className="tournament-players-stats__title">{t(prefix + 'title')}</div>
        <div className="tournament-players-stats__back-link">
            <NavLink to={`/community/${communityId}/tournament/${id}/tags/dashboard`} >
                <BackIcon w="10" h="16" f="#0474EF" /> <span className="tournament-players-stats__back-text">{t('tournamentDashboard-backButton')}</span>
            </NavLink>
        </div>
        <Switcher switches={classList} onSelect={setClassId} selectedValue={classId} />
        <div className="tournament-players-stats__table-holder">
            <RaqtTable config={tableConfig} data={dataToShow} />
        </div>
    </div>;
}
