import { api } from './api.config';

export const createBulkTours = async (data: any[]): Promise<any[]> => {
    const response = await api.post(`tour/bulk`, {bulk: data});
    return response.data;
};

export const getAllTours = async (): Promise<any[]> => {
    const response = await api.get(`tour`);
    return response.data;
};

export const removeTour = async (id: number): Promise<any[]> => {
    const response = await api.delete(`tour/${id}`);
    return response.data;
};