import React, { ChangeEvent, FC } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { MatchResult } from '../../../../../types/tournament';
import './index.scss';
import { ButtonIcon } from '../../../../../components';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';
import { useTranslation } from 'react-i18next';

interface ResultCardProps {
    data: MatchResult;
    results: MatchResult[];
    setResult: (arg: MatchResult[]) => void;
    handleDeleteCard: (arg: number | string | undefined) => void;
}

export const ResultCard: FC<ResultCardProps> = ({ data, results, setResult, handleDeleteCard }) => {
    const index = results.findIndex((result) => result.id === data.id);
    const newResults = [...results];
    const prefix = 'resultCard-';
    const prefixCreative = 'creativeDetail-'
    const prefixValidation = 'validation-';

    const { t } = useTranslation();

    const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        newResults[index] = {
            ...data,
            [e.currentTarget.name]: Number(e.currentTarget.value.trim() || 0),
        };
        setResult(newResults);
    };

    return (
        <div className="container py-0 py-lg-3 my-4 my-lg-0 px-0">
            <div className="row">
                <div className="col-lg-1 d-flex justify-content-start justify-content-lg-center">
                    <div style={{ height: '50px' }} className="my-2 my-lg-0">
                        <ButtonIcon
                            className="h-100"
                            onClick={() => {
                                handleDeleteCard(data.id);
                            }}
                        >
                            <CloseCircle />
                        </ButtonIcon>
                    </div>
                </div>
                <div className="col">
                    <InputGroup size="sm">
                        <InputGroup.Text className="fs-6">{t(prefixCreative + 'homeTeam')}</InputGroup.Text>
                        <Form.Control
                            autoComplete="off"
                            defaultValue={isNaN(data.homeTeam) ? 0 : data.homeTeam}
                            isInvalid={isNaN(data.homeTeam)}
                            isValid={!isNaN(data.homeTeam)}
                            name="homeTeam"
                            placeholder={t(prefix + 'scorePlaceholder')}
                            onChange={handleInput}
                        />
                    </InputGroup>
                    <div className="d-flex justify-content-end text-danger">
                        <i style={{ minHeight: '2rem' }}>
                            {isNaN(data.homeTeam) && <small>{t(prefixValidation + 'mustBeANumberLabel')}</small>}
                        </i>
                    </div>
                </div>
                <div className="col">
                    <InputGroup size="sm">
                        <InputGroup.Text className="fs-6">{t(prefixCreative + 'awayTeam')}</InputGroup.Text>
                        <Form.Control
                            autoComplete="off"
                            defaultValue={isNaN(data.awayTeam) ? 0 : data.awayTeam}
                            isInvalid={isNaN(data.awayTeam)}
                            isValid={!isNaN(data.awayTeam)}
                            name="awayTeam"
                            placeholder={t(prefix + 'scorePlaceholder')}
                            onChange={handleInput}
                        />
                    </InputGroup>
                    <div className="d-flex justify-content-end text-danger">
                        <i style={{ minHeight: '2rem' }}>
                            {isNaN(data.awayTeam) && <small>{t(prefixValidation + 'mustBeANumberLabel')}</small>}
                        </i>
                    </div>
                </div>
            </div>
        </div>
    );
};
