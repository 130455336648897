import React from 'react';
import { SvgIcon } from '../../components';
import { SvgIconProps } from '../../components/icon/SvgIcon';

export const InfoIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M27.0833 18.75H22.9166V14.5834H27.0833V18.75ZM27.0833 35.4167H22.9166V22.9167H27.0833V35.4167ZM25 4.16669C22.2641 4.16669 19.555 4.70556 17.0274 5.75253C14.4998 6.7995 12.2031 8.33408 10.2686 10.2686C6.36156 14.1756 4.16663 19.4747 4.16663 25C4.16663 30.5254 6.36156 35.8244 10.2686 39.7314C12.2031 41.666 14.4998 43.2005 17.0274 44.2475C19.555 45.2945 22.2641 45.8334 25 45.8334C30.5253 45.8334 35.8243 43.6384 39.7314 39.7314C43.6384 35.8244 45.8333 30.5254 45.8333 25C45.8333 22.2641 45.2944 19.5551 44.2475 17.0274C43.2005 14.4998 41.6659 12.2032 39.7314 10.2686C37.7968 8.33408 35.5002 6.7995 32.9725 5.75253C30.4449 4.70556 27.7358 4.16669 25 4.16669V4.16669Z"
                fill="#5D6465"
            />
        </SvgIcon>
    );
};
