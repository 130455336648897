import { FC, useRef, useState } from 'react';
import { Field, Formik, Form, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { CustomButton, FormTextField, PhoneInput } from '../../../../components';

import './ChecksAndPasswordFormGroup.scss';
import { checkCodeApi, checkPhoneApi } from '../../../../apis/auth';
import { ChevronIcon } from '../../../../assets/icons/ChevronIcon';
import { CheckIcon } from '../../../../assets/icons/CheckIcon';


type PhoneFormModel = {phone: string};
type MFACodeFormModel = {mfaCode: number};

interface IOnSubmitProps {
    phone: string;
    mfaCode: number;
    password: string;
}

interface IChecksAndPasswordFormGroupProps {
    onSubmit?: (values: IOnSubmitProps) => void;
}

export const ChecksAndPasswordFormGroup: FC<IChecksAndPasswordFormGroupProps> = ({onSubmit}) => {
    const prefixValidation = 'validation-'
    const prefix = 'checks-'

    const { t } = useTranslation();
    const [phone, setPhone] = useState<string | null>(null);
    const [mfaCode, setMfaCode] = useState<number | null>(null);

    const phoneForm = {
        phone: ''
    };
    const phoneSchema = Yup.object().shape({
        phone: Yup.string().required(t(prefixValidation + 'phoneRequired')),
    });
    const phoneFormRef = useRef<FormikProps<PhoneFormModel>>(null);
    const phoneHandleForm = async ({ phone }: PhoneFormModel) => {
        try {
            const { valid } = await checkPhoneApi({ phone });
            !valid && phoneFormRef.current?.setFieldError('phone', t(prefix + 'phoneIncorrect'))
            setPhone(valid ? phone : null);
        } catch (e) {

        }
    };

    const mfaCodeForm = {
        mfaCode: ''
    } as unknown as MFACodeFormModel;
    const mfaCodeSchema = Yup.object().shape({
        mfaCode: Yup.number().required(t(prefixValidation + 'mfaCodeIncorrect')),
    });
    const mfaCodeHandleForm = async ({ mfaCode }: MFACodeFormModel) => {
        if(!phone) return;
        
        try {
            const { valid } = await checkCodeApi({ phone, mfaCode: +mfaCode });
            setMfaCode(valid ? +mfaCode : null);
        } catch (e) {

        }
    };
    const mfaCodeFormRef = useRef<FormikProps<MFACodeFormModel>>(null);

    const passwordForm = {
        password: '',
        confirmPassword: '',
    };
    const passwordSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
          .required('Confirm password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

return <div className="checks-password-form-group">
            <Formik validationSchema={phoneSchema}  initialValues={phoneForm} onSubmit={phoneHandleForm} innerRef={phoneFormRef}>
                <Form className="checks-password-form-group__single-item" onChange={() => {
                    if(phone) {
                        setPhone(null);
                        mfaCodeFormRef.current?.setValues(mfaCodeForm);
                        mfaCodeFormRef.current?.resetForm();
                        mfaCodeFormRef.current?.setTouched({
                            mfaCode: false
                        });
                        setMfaCode(null);
                    }
                }}>
                    <div>
                        <Field name="phone" component={PhoneInput} label={t(prefix + 'phonePlaceholder')}/>
                    </div>
                    <div className="checks-password-form-group__single-item_submit-go">
                        <CustomButton disabled={!!phone} btnText={t(prefix + 'phoneSubmit')} type="submit" variant="outline-dark">
                            <ChevronIcon isClick={false} width="50px" height="25px" fill="#000"/>
                        </CustomButton>
                    </div>
                </Form>
            </Formik>
            <Formik validationSchema={mfaCodeSchema}  initialValues={mfaCodeForm}  onSubmit={mfaCodeHandleForm} innerRef={mfaCodeFormRef}>
                <Form className="checks-password-form-group__single-item" onChange={() => {mfaCode && setMfaCode(null)}}>
                    <div className="checks-password-form-group__single-item_text-field">
                        <FormTextField
                            name="mfaCode"
                            type="text"
                            disabled={!phone}
                            label={t(prefix + 'mfaCodePlaceholder')}
                        />
                    </div>
                    <div className="checks-password-form-group__single-item_submit">
                        <CustomButton disabled={!!mfaCode || !phone} btnText={t(prefix + 'mfaCodeSubmit')} type="submit" variant="outline-dark">
                            <CheckIcon w="50px" h="25px" f="#000"/>
                        </CustomButton>
                    </div>
                </Form>
            </Formik>
            {<Formik validationSchema={passwordSchema}  initialValues={passwordForm} onSubmit={({ password }) => {
                if(onSubmit && phone && mfaCode){
                    onSubmit({
                        phone,
                        mfaCode,
                        password,
                    });
                }
            }}>
                <Form>
                    <div className="checks-password-form-group__double-item">
                        <FormTextField
                            name="password"
                            type="password"
                            disabled={!phone || !mfaCode}
                            label={t(prefix + 'passwordPlaceholder')}
                        />
                    </div>
                    <div className="checks-password-form-group__double-item">
                        <FormTextField
                            name="confirmPassword"
                            type="password"
                            disabled={!phone || !mfaCode}
                            label={t(prefix + 'passwordConfirmPlaceholder')}
                        />

                        <div className="checks-password-form-group__double-item_submit">
                            <CustomButton btnText={t(prefix + 'submit')} type="submit"/>
                        </div>
                    </div>
                </Form>
            </Formik>}
        </div>
};