import { FC } from 'react';
import { IStandingGroup } from '../../TournamentScorecardPage.types'

import './TournamentScorecardPageGroupInfo.scss';

type TournamentScorecardPageGroupInfoProps = Omit<IStandingGroup, 'isGroup'>

export const TournamentScorecardPageGroupInfo: FC<TournamentScorecardPageGroupInfoProps> = ({name, teams}) => (<div className="tournament-scorecard-page-group-info">
    <div className="tournament-scorecard-page-group-info__head">
        <div className="tournament-scorecard-page-group-info__number">#</div>
        <div className="tournament-scorecard-page-group-info__team">{ name }</div>
        <div className="tournament-scorecard-page-group-info__matches">M</div>
        <div className="tournament-scorecard-page-group-info__difference">+/-</div>
        <div className="tournament-scorecard-page-group-info__gw">GW</div>
        <div className="tournament-scorecard-page-group-info__wins">W</div>
    </div>
    <div className="tournament-scorecard-page-group-info__body">
        {teams.map((item, index) => (<div key={`group-team-${index}`} className="tournament-scorecard-page-group-info__body-row">
            <div className="tournament-scorecard-page-group-info__number">{ index + 1 }</div>
            <div className="tournament-scorecard-page-group-info__team">{item.name}</div>
            <div className="tournament-scorecard-page-group-info__matches">{item.countMatches}</div>
            <div className="tournament-scorecard-page-group-info__difference">{item.points}</div>
            <div className="tournament-scorecard-page-group-info__gw">{item.gameWin}</div>
            <div className="tournament-scorecard-page-group-info__wins">{item.matchWin}</div>
        </div>))}
    </div>
</div>);