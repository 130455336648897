import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IMatchBoardItem } from '../../../../apis/tournament';
import { ItemWrapper } from '../../../../components';
import { TournamentMatchStatusEnumTranslation } from '../../../../types/enums';
import { getTime } from '../../../../utils/getStringDate';
import './TournamentMatchListTable.scss';

interface TournamentMatchListTableProps {
    matchList: IMatchBoardItem[];
}

export const TournamentMatchListTable: FC<TournamentMatchListTableProps> = ({matchList = []}) => {
    const prefix = 'tournamentMatchList-';
    const { t } = useTranslation();

    //TO DO: Refactor this to new table component.

    return (<div className="tournament-match-list-table">
                <div className="tournament-match-list-table__wrapper">
                    <div className="tournament-match-list-table__head">
                        <div className="tournament-match-list-table__row-wrapper">
                            <div className="tournament-match-list-table__class">
                                <ItemWrapper className="tournament-match-list-table__head-item">
                                    {t(prefix + 'class')}
                                </ItemWrapper>
                            </div>
                            {/* <div className="tournament-match-list-table__match-id">
                                <ItemWrapper className="tournament-match-list-table__head-item tournament-match-list-table_centered">
                                    {t(prefix + 'matchId')}
                                </ItemWrapper>
                            </div> */}
                            <div className="tournament-match-list-table__court">
                                <ItemWrapper className="tournament-match-list-table__head-item tournament-match-list-table_centered">
                                    {t(prefix + 'court')}
                                </ItemWrapper>
                            </div>
                            <div className="tournament-match-list-table__time">
                                <ItemWrapper className="tournament-match-list-table__head-item tournament-match-list-table_centered">
                                    {t(prefix + 'startTime')}
                                </ItemWrapper>
                            </div>
                            {/* <div className="tournament-match-list-table__time">
                                <ItemWrapper className="tournament-match-list-table__head-item tournament-match-list-table_centered">
                                    {t(prefix + 'endTime')}
                                </ItemWrapper>
                            </div> */}
                            <div className="tournament-match-list-table__team">
                                <ItemWrapper className="tournament-match-list-table__head-item">
                                    {t(prefix + 'team1')}
                                </ItemWrapper>
                            </div>
                            <div className="tournament-match-list-table__team">
                                <ItemWrapper className="tournament-match-list-table__head-item">
                                    {t(prefix + 'team2')}
                                </ItemWrapper>
                            </div>
                            <div className="tournament-match-list-table__result">
                                <ItemWrapper className="tournament-match-list-table__head-item tournament-match-list-table_centered">
                                    {t(prefix + 'result')}
                                </ItemWrapper>
                            </div>
                            <div className="tournament-match-list-table__status">
                                <ItemWrapper className="tournament-match-list-table__head-item tournament-match-list-table_centered">
                                    {t(prefix + 'status')}
                                </ItemWrapper>
                            </div>
                        </div>
                    </div>
                    <div className="tournament-match-list-table__body">
                        {matchList.map((v, index) => (<div key={`table-body-row-${index}`} className="tournament-match-list-table__row-wrapper">
                            <div className="tournament-match-list-table__class">
                                <ItemWrapper white={true}>
                                    {v.tournamentClassName}
                                </ItemWrapper>
                            </div>
                            {/* <div className="tournament-match-list-table__match-id">
                                <ItemWrapper white={true} className="tournament-match-list-table_centered">
                                    { v.matchIdToShow }
                                </ItemWrapper>
                            </div> */}
                            <div className="tournament-match-list-table__court">
                                <ItemWrapper white={true}>
                                { v.courtName }
                                </ItemWrapper>
                            </div>
                            <div className="tournament-match-list-table__time">
                                <ItemWrapper white={true} className="tournament-match-list-table_centered">
                                    { getTime(v.startDate) }
                                </ItemWrapper>
                            </div>
                            {/* <div className="tournament-match-list-table__time">
                                <ItemWrapper white={true} className="tournament-match-list-table_centered">
                                    { getTime(v.endDate) }
                                </ItemWrapper>
                            </div> */}
                            <div className="tournament-match-list-table__team">
                                <ItemWrapper white={true}>
                                    {v.homeTeamName}
                                </ItemWrapper>
                            </div>
                            <div className="tournament-match-list-table__team">
                                <ItemWrapper white={true}>
                                    {v.awayTeamName}
                                </ItemWrapper>
                            </div>
                            <div className="tournament-match-list-table__result">
                                <ItemWrapper white={true}>
                                    {v.results.filter(Boolean).join(',')}
                                </ItemWrapper>
                            </div>
                            <div className="tournament-match-list-table__status">
                                <ItemWrapper white={true}>
                                    {t(TournamentMatchStatusEnumTranslation[v.status]).toLocaleUpperCase()}
                                </ItemWrapper>
                            </div>
                        </div>))}
                    </div>
                </div>
            </div>)
};