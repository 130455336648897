import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';
import { setScheduleItemScaleMultiplier } from '../../../../../features/schedule/scheduleSlice';

export function ScheduleItemScaleSlider() {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const dispatch = useAppDispatch();

    const { scheduleItemScaleMultiplier } = useAppSelector(selectSchedule);

    return (
        <>
            <label htmlFor="scheduleItemScale" className="form-label">
                {t(prefix + 'scheduleItemScaleLabel')}
            </label>
            <input
                className="form-range shadow-none"
                id="scheduleItemScale"
                type="range"
                min="1"
                max="20"
                value={scheduleItemScaleMultiplier}
                onChange={(e) => dispatch(setScheduleItemScaleMultiplier(+e.target.value))}
            />
        </>
    );
}
