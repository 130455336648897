import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    ButtonFormikTab,
    Checkbox,
    CustomButton,
    FormTextField,
    ReactSelectForm,
} from '../../../../../components';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { openModal } from '../../../../../features/modal/modalSlice';
import { ModalAction } from '../../../../../types/modal';
import {
    classFormatData,
    classTypesData,
    SaleStateClassOptionWithLabel,
} from '../../../../../constant/class';
import { boolOption, rankingOption } from '../../../../../constant/setting';

import { TeamSection } from '../TeamSection';
import { IMatch } from '../../../TournamentPlay/components/PlayTag/PlayTag';
import { Group, User, TournamentCategory } from '../../../../../types/tournament';
import { singleClassTagTypeConverter } from '../../../../../utils/classTagTypeConverter';
import { traslateItemWithLabel } from '../../../../../utils/traslation.helpers';
import { SettingBox } from '../../../common/components/SettingBox';
import { selectClass } from '../../../../../features/class/classSelectors';
import { TagIdsEnum } from '../../../../../constant/navigation';
import { selectTournament } from '../../../../../features/tournament/tournamentSelectors';
import { updateClassById } from '../../../../../features/class/classActions';
import { createAndSortOptionsForClassSelect } from '../../../CreativeTourDetail.helpers';
import { classSetSingleClass, setCurrentName } from '../../../../../features/class/classSlice';
import { fetchClassesByTournamentIdAndClassId } from '../../../../../apis/tournament';
import { keywordsTypes } from '../../../../adminConfiguration/adminCounfiguration';
import { ClassFormatEnum, ClassGroupDraw, EliminationDrawEnum } from '../../../../../types/enums';

import './ClassesTag.scss';
import { generateCategories } from '../../../../../features/tournament/actionCreators';
import { createClassData } from '../../../../../apis/class';
import { WarningIcon } from '../../../../../assets/icons/WarningIcon';
import { InfoIcon } from '../../../../../assets/icons/InfoIcon';

//TO DO: ClassValues create some place for all types and interfaces.
export type ClassTeam = {
    readonly id: number | string;
    name: string;
    users: Array<User>;
    logo: null | string;
    seed: number;
    shuffled?: boolean;
    captain: Array<User>;
};

export interface ClassValues {
    readonly classId: string | number;
    name: string;
    type: number;
    minPlayers: number;
    maxPlayers: number;
    surface: number;
    ball: number;
    isRanking: string;
    rankingType: string;
    keywordSurface: number;
    keywordBall: number;
    isOnSale: string;
    isDupr: boolean;
    format: number;
    roundScoring: string;
    price: number | null;
    vat: number | null;
    salesStatus: string;
    stateOfClass: string;
    numberOfGroups: number | string;
    teams: Array<ClassTeam>;
    tournamentGroups: Array<Group>;
    tournamentCategories: Array<TournamentCategory>;
    tournamentMatches: Array<IMatch>;
    eliminationDraw: EliminationDrawEnum;
    isHasSubClasses: boolean;
    parentClassId: number;
    subClasses: ClassValues[];
}

export const ClassesTag = () => {
    const { t } = useTranslation();
    const prefixValidation = 'validation-';
    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';
    const buttonPrefix = 'button-';

    const { data } = useAppSelector(selectTournament);
    const { currentClassId, classCollection } = useAppSelector(selectClass);

    const classCollectionMain = useMemo(() => {
        return classCollection.reduce((acc, {...item}) => {
            if(!item.parentClassId) {
                item.subClasses = classCollection.filter(({classId, parentClassId}) => {
                    return classId === item.classId || item.classId === parentClassId;
                });
                acc.push(item);
            }
            return acc;
        }, [] as ClassValues[]);
    }, [classCollection]);

    const {listKeywords } = useAppSelector((state) => state.settings);

    const surfaceOption = listKeywords.filter(({ type }) => type === keywordsTypes.SURFACE).map(({id, name}) => ({value: id, label: name}));
    const ballOption = listKeywords.filter(({ type }) => type === keywordsTypes.BALL).map(({id, name}) => ({value: id, label: name}));
    
    const [currentClass, setCurrentClass] = useState<ClassValues>(null as unknown as any);
    const [loading, setLoading] = useState<boolean>(true);


    const ClassSchema = Yup.object().shape({
        name: Yup.string().required(),
        isOnSale: Yup.string(),
        minPlayers: Yup.number()
            .when("type", {
                is: (type: number) => type !== 4,
                then: Yup.number().required(t(prefixValidation + 'required'))
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .positive(t(prefixValidation + 'positive')),
            }),
        maxPlayers: Yup.number()
            .when("type", {
                is: (type: number) => type !== 4,
                then: Yup.number().required(t(prefixValidation + 'required'))
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .positive(t(prefixValidation + 'positive')),
            }),
        ...((currentClass?.isOnSale && {price: Yup.number().when("isOnSale", {
            is: 'true',
            then: Yup.number()
            .typeError(t(prefixValidation + 'mustBeANumber'))
            .required(t(prefixValidation + 'required'))
            .positive(t(prefixValidation + 'positive'))
            .nullable(true),
        }),
        vat: Yup.number().when("isOnSale", {
            is: 'true',
            then: Yup.number()
            .typeError(t(prefixValidation + 'mustBeANumber'))
            .required(t(prefixValidation + 'required'))
            .min(0, t(prefixValidation + 'positive'))
            .nullable(true),
        }),
        })|| {}),
        teams: Yup.array(
            Yup.object({
                name: Yup.string().required(t(prefixValidation + 'required')),
                seed: Yup.number()
                    .typeError(t(prefixValidation + 'mustBeANumber'))
                    .required(t(prefixValidation + 'required'))
                    .min(0, t(prefixValidation + 'positive')),
            })
        ),
        tournamentCategories: Yup.array(
            Yup.object({
                isActive: Yup.boolean().required(t(prefixValidation + 'required')),
                numberOfMatches: Yup.number()
                    .typeError(t(prefixValidation + 'mustBeANumber'))
                    .required(t(prefixValidation + 'required'))
            })
        ),
    });

    const dispatch = useAppDispatch();

    const mainClass = useMemo(() => classCollectionMain.find(i => {
        return i.subClasses.find(({ classId }: {classId: string | number}) => currentClassId === classId);
    }), [classCollectionMain, currentClassId]) as ClassValues;

    const classOptions = createAndSortOptionsForClassSelect(classCollectionMain);
    const mainClassOptions = useMemo(
        () => createAndSortOptionsForClassSelect((mainClass?.subClasses  || [])
                .sort((a: ClassValues, b: ClassValues) => 
                    a.classId.toLocaleString().localeCompare(
                        b.classId.toLocaleString()
                    )
                )), [mainClass]);

    const selectInitValues = {mainClassId:  mainClass?.classId || null, classId: currentClassId || null};

    const handleOpenModal = useCallback(() => {
        dispatch(
            openModal({
                currentModal: ModalAction.ADD_TOURNAMENT_CLASS,
                modalOpen: true,
                data: data?.classSettings,
            })
        );
    }, [data, dispatch]);

    const submit = useCallback((values: ClassValues) => {
        data &&
        dispatch(
            updateClassById(
                data.id,
                currentClass.classId,
                singleClassTagTypeConverter(values, classCollection), 
                values.format !== currentClass.format,
                (values.isHasSubClasses !== currentClass.isHasSubClasses || typeof values.classId === 'string')
            )
        );
    },[data, dispatch, classCollection, currentClass]);

    const getClassData = async (classId: number | null = null) => {
        if(typeof data?.id === 'number' && (typeof currentClassId === 'number' || typeof classId === 'number')) {
            try {
                setLoading(true);
                const classData = await fetchClassesByTournamentIdAndClassId(Number(classId) || Number(currentClassId));

                dispatch(classSetSingleClass(classData));
                setCurrentClass({...classData, classId: classData.id, isHasSubClasses: Boolean(classData.isHasSubClasses), teams: [
                    ...classData.teams.filter(i => Boolean(i.seed)),
                    ...classData.teams.filter(i => !Boolean(i.seed)),
                ].map(i => ({...i, shuffled: false})), tournamentCategories: [
                    ...classData.tournamentCategories
                ], type: classData.type } as unknown as ClassValues);
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
        }   
    };

    const newClassObj = (mainClassId?: number | string | null) => {
        const classObj = classCollection.find(({classId}) => classId === (mainClassId || currentClassId))
        classObj && setCurrentClass(classObj);
        setLoading(false);
    };

    const handleClassTypeChange = async (type: number) => {
        if (type === 4 && data && (currentClass.tournamentCategories.length <= 0 || !currentClass.tournamentCategories)) {
            setLoading(true);
            // await dispatch(generateCategories(data.id ,currentClassId, {...currentClass, ...{type: 4, isOnSale: currentClass.isOnSale === 'true', roundScoring: currentClass.roundScoring === 'true'}}))
            // getClassData();
            // setLoading(false);
            if (typeof currentClassId === 'number') {
                await dispatch(generateCategories(data.id ,currentClassId, {...currentClass, teams: [], ...{type: 4, isOnSale: currentClass.isOnSale === 'true', roundScoring: currentClass.roundScoring === 'true'}}))
                getClassData();
                setLoading(false);
            } 
            else {
                const classData = await createClassData(data.id, {...currentClass, teams: [], ...{type: 4, isOnSale: currentClass.isOnSale === 'true', roundScoring: currentClass.roundScoring === 'true'}})
                await dispatch(generateCategories(data.id ,classData.id, classData))
                getClassData(classData.id);
                setLoading(false);
            }
        }
        // We remove all teams from the class, since we can't ensure that the team will be valid
        setCurrentClass({...currentClass, teams: [], type: type});
    }

    const getFormatOptions = () => {
        let options = classFormatData;

        if(currentClass.parentClassId) {
            options = classFormatData.filter(({ isGroup }) => !isGroup)
        }
        
        return options.map(item => traslateItemWithLabel(t, item));
    };

    useEffect(() => {
        if(typeof currentClassId === 'number') {
            getClassData();
        } else {
            newClassObj();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id, currentClassId]);

    return (
        <div className="px-2">
            <div className="row my-3">
                <div className="col d-flex align-items-center">
                    <h4 className="font-weight-bold mb-0">{t(prefix + 'classesTitle')}</h4>
                </div>
                <div className="col d-flex justify-content-end">
                    <CustomButton
                        cb={handleOpenModal}
                        btnText={t(buttonPrefix + 'addClass')}
                        className="d-flex justify-content-between align-items-center"
                        variant="outline-dark"
                    >
                        <AddIcon />
                    </CustomButton>
                </div>
            </div>
            {mainClass?.subClasses?.length ? (
                <>
                    <Formik onSubmit={console.log} enableReinitialize={true} initialValues={selectInitValues}>
                        {
                            () => {
                                return (<form>
                                            <div className="row-cols-lg-2 d-md-flex">
                                                <div className="col-lg-4 col px-0">
                                                    <ReactSelectForm
                                                        options={classOptions}
                                                        name="mainClassId"
                                                        label={t(prefix + 'className')}
                                                        onChangeCb={(value: string | number) => dispatch(setCurrentName(value as unknown as number))}
                                                    />
                                                </div>
                                                {mainClassOptions?.length > 1 && <div className="col-lg-4 col px-0 ms-lg-3 ms-md-3">
                                                    <ReactSelectForm
                                                        options={mainClassOptions}
                                                        name="classId"
                                                        label={t(prefix + 'subclassSelector')}
                                                    />
                                                </div>}
                                            </div>
                                            <h4 className="font-weight-bold my-3">
                                                {currentClass?.name}
                                            </h4>
                                        </form>)
                            }
                        }
                    </Formik>
                    {(!loading && mainClass && currentClass && (<Formik
                        validationSchema={ClassSchema}
                        enableReinitialize={true}
                        validateOnChange={false}
                        validateOnBlur={true}
                        validateOnMount={false}
                        initialValues={currentClass}
                        onSubmit={submit}
                    >
                        {({ values, setFieldValue }) => {
                            
                            if(values.type === 4 && values.numberOfGroups !== 5) {
                                setFieldValue('numberOfGroups', 5);
                            } else {
                                if(values.numberOfGroups !== '' && (typeof values.numberOfGroups === 'number' && values.numberOfGroups < 1)) {
                                    setFieldValue('numberOfGroups', 1);
                                } else if (typeof values.numberOfGroups === 'number' && values.numberOfGroups > 16) {
                                    setFieldValue('numberOfGroups', 16);
                                }
                            }
                            return (
                                <Form autoComplete="off" id={TagIdsEnum.CLASSES}>
        
                                    <div className="row-cols-lg-2 d-md-flex">
                                        <div className="col-lg-4 col px-0">
                                            <FormTextField
                                                name="name"
                                                placeholder={t(inputPrefix + 'placeholderName')}
                                                label={t(prefix + 'name')}
                                            />
                                        </div>
                                        <div className="col-lg-4 col px-0 px-lg-3 px-md-3 px-sm-3">
                                            <ReactSelectForm
                                                options={classTypesData.map(item => traslateItemWithLabel(t, item))}
                                                name="type"
                                                preselect={true}
                                                disabled={!!values.parentClassId}
                                                withConfirm={currentClass.tournamentMatches?.length !== 0}
                                                onChangeCb={(value: number) => {
                                                    setFieldValue('type', value);
                                                    handleClassTypeChange(value)
                                                }}
                                                label={t(prefix + 'type')}
                                            />
                                            {currentClass.teams?.length > 0 && (
                                                <div className="alert alert-warning rounded" role="alert">
                                                    <div className="d-flex align-items-end">
                                                        <WarningIcon h='24' w='24' f='#D4A017'/>
                                                        <h6 className='font-weight-bold mb-0 ml-2'>{t(prefix + 'typeChangeWarningTitle')}</h6>
                                                    </div>
                                                    {t(prefix + 'typeChangeWarningDescription')}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {
                                        values.type === 4 ? (
                                            <>
                                                {values.tournamentCategories?.length > 0 && (
                                                    <div className="row py-4 my-4 border-top border-bottom">
                                                        <div className="row my-3">
                                                            <div className="col d-flex align-items-start">
                                                                <h5 className="font-weight-semibold mb-0">{t(prefix + 'categoriesTitle')}</h5>
                                                            </div>
                                                        </div>
                                                        
                                                        {
                                                        values.tournamentCategories.map((item, index) => (
                                                        
                                                            <div className="row mb-3 align-items-center" key={item.id}>
                                                                <div className="col-lg-8 col-12 px-0 align-items-center py-3">
                                                                <SettingBox title={item.name}>
                                                                    <>
                                                                    <ButtonFormikTab
                                                                        currValue={item.isActive !== null && typeof item.isActive !== 'number' ? item.isActive : item.isActive === 1 ? true : false}
                                                                        items={boolOption}
                                                                        name={`tournamentCategories.${index}.isActive`}
                                                                        disabled={!!values.parentClassId}
                                                                        />
                                                                    </>
                                                                </SettingBox>
                                                                </div>
                                                                <div className={"col-lg-2 col-12 px-0 align-items-center"}>
                                                                    <FormTextField
                                                                    name={`tournamentCategories.${index}.numberOfMatches`}
                                                                    disabled={!!values.parentClassId}
                                                                    placeholder={t(
                                                                        inputPrefix + 'placeholderNumber'
                                                                    )}
                                                                    label={<div className="d-flex align-items-center gap-2">
                                                                        {t(prefix + 'numberOfMatches')}
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>{t(prefix + 'numberOfMatchesTooltip')}</Tooltip>}
                                                                        >
                                                                            <Button variant="link" className="p-0">
                                                                                <InfoIcon width={24} height={24}/>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </div>}
                                                                    />
                                                                    
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="row-cols-lg-2 d-sm-flex"> 
                                                    <div className="col-lg-4 col px-0">
                                                        <ReactSelectForm
                                                            options={getFormatOptions()}
                                                            name="format"
                                                            preselect={true}
                                                            withConfirm={currentClass.tournamentMatches?.length !== 0}
                                                            label={t(prefix + 'format')}
                                                        />
                                                    </div>
                                                    {(!values.parentClassId && 
                                                            (values.format === ClassFormatEnum.GROUP || values.format === ClassFormatEnum.GROUP_BRACKET) &&
                                                            <div className="classes-tag__subclass-toggle">
                                                                <Checkbox name="isHasSubClasses"/>
                                                                <p>{t(prefix + 'subclassToggle')}</p>
                                                            </div>
                                                    )|| null}
                                                </div>

                                                {(values.format === ClassFormatEnum.GROUP || values.format === ClassFormatEnum.GROUP_BRACKET) 
                                                && (<div className="row">
                                                    <SettingBox title="eliminationDraw">
                                                        <ButtonFormikTab
                                                            currValue={values.eliminationDraw || 1}
                                                            items={ClassGroupDraw.map(item => traslateItemWithLabel(t, item))}
                                                            name="eliminationDraw"
                                                        />
                                                    </SettingBox>
                                                    <div className="d-flex justify-content-between">
                                                            <div className="pe-2" onBlur={() => {
                                                                if(!values.numberOfGroups) {
                                                                    setFieldValue('numberOfGroups', 1);
                                                                } else if (isNaN(values.numberOfGroups as number)) {
                                                                    setFieldValue('numberOfGroups', 1);
                                                                }
                                                            }}>
                                                                <FormTextField
                                                                    name="numberOfGroups"
                                                                    pattern="\d*"
                                                                    placeholder={t(
                                                                        inputPrefix + 'numberOfGroups'
                                                                    )}
                                                                    label={t(prefix + 'numberOfGroups')}
                                                                />
                                                            </div>
                                                        </div>
                                                </div>)}
                                            </>
                                        )
                                    }
                                    
                                    
                                    <div className="row-cols-lg-2 d-md-flex">
                                        {
                                            values.type !== 4 ? (
                                                <div className="col-lg-4 col px-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="w-50 pe-2">
                                                            <FormTextField
                                                                name="minPlayers"
                                                                disabled={!!values.parentClassId}
                                                                placeholder={t(
                                                                    inputPrefix + 'placeholderNumber'
                                                                )}
                                                                label={t(prefix + 'minplayers')}
                                                            />
                                                        </div>
                                                        <div className="w-50 ps-2">
                                                            <FormTextField
                                                                name="maxPlayers"
                                                                disabled={!!values.parentClassId}
                                                                placeholder={t(
                                                                    inputPrefix + 'placeholderNumber'
                                                                )}
                                                                label={t(prefix + 'maxplayers')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                          ) : null
                                         } 
                                        <div className={`col-lg-4 col px-0 ${values.type !== 4 ? 'px-0 px-lg-3 px-md-3 px-sm-3' : 'mr-lg-3 mr-md-3 mr-sm-3' }`}>
                                            <ReactSelectForm
                                                options={surfaceOption}
                                                name="keywordSurface"
                                                preselect={true}
                                                label={t(prefix + 'surface')}
                                            />
                                        </div>
                                        <div className="col-lg-4 col px-0">
                                            <ReactSelectForm
                                                options={ballOption}
                                                name="keywordBall"
                                                preselect={true}
                                                label={t(prefix + 'ball')}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        {
                                            values.type !== 4 ? (
                                                <SettingBox title="Scoring">
                                                    <ButtonFormikTab
                                                        currValue={values.roundScoring}
                                                        items={boolOption}
                                                        name="roundScoring"
                                                    />
                                                </SettingBox>
                                        ) : null }
                                        <SettingBox title="State">
                                            <ButtonFormikTab
                                                currValue={values.stateOfClass}
                                                items={SaleStateClassOptionWithLabel.map(item => traslateItemWithLabel(t, item))}
                                                name="stateOfClass"
                                            />
                                        </SettingBox>
                                        <SettingBox title="onSale">
                                            <ButtonFormikTab
                                                currValue={values.isOnSale}
                                                items={boolOption}
                                                name="isOnSale"
                                                disabled={!!values.parentClassId}
                                            />
                                        </SettingBox>
                                        <SettingBox title="setranking">
                                            <ButtonFormikTab
                                                currValue={values.isRanking}
                                                items={boolOption}
                                                name="isRanking"
                                            />
                                        </SettingBox>
                                        <SettingBox title="RankingType">
                                            <ButtonFormikTab
                                                currValue={values.rankingType || 300}
                                                items={rankingOption}
                                                name="rankingType"
                                            />
                                        </SettingBox>
                                        <SettingBox title="isDupr">
                                            <ButtonFormikTab
                                                currValue={values.isDupr}
                                                items={boolOption}
                                                name="isDupr"
                                            />
                                        </SettingBox>
                                        
                                        {values.isOnSale.toString() === 'true' ? (
                                            <>
                                                <SettingBox title="salesStatus">
                                                    <ButtonFormikTab
                                                        currValue={values.salesStatus}
                                                        items={SaleStateClassOptionWithLabel.map(item => traslateItemWithLabel(t, item))}
                                                        name="salesStatus"
                                                        disabled={!!values.parentClassId}
                                                    />
                                                </SettingBox>
                                                <div className="col-lg-8 px-2">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="w-50 pe-2">
                                                            <FormTextField
                                                                name="price"
                                                                placeholder={t(
                                                                    inputPrefix +
                                                                        'placeholderNumber'
                                                                )}
                                                                label={t(prefix + 'pricePerTeam')}
                                                            />
                                                        </div>
                                                        <div className="w-50 ps-2">
                                                            <FormTextField
                                                                name="vat"
                                                                placeholder={t(
                                                                    inputPrefix +
                                                                        'placeholderNumber'
                                                                )}
                                                                label={t(prefix + 'vatpercentage')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="container-fluid px-0">
                                        <TeamSection
                                            values={values}
                                            setField={setFieldValue}
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>)) || <div>Loading...</div>}
                </>
            ) : null}
        </div>
    );
};
