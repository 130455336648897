import { FC } from "react";
import { useTranslation } from "react-i18next";
import { RoundedAvatar } from "../../../../components"
import { TournamentScorecardPageSection } from "../TournamentScorecardPageSection";

import { User } from '../../../../types/tournament';
 
import './PlayerTournamentList.scss';

interface PlayerTournamentListProps {
    players: User[];
}

export const PlayerTournamentList: FC<PlayerTournamentListProps> = ({ players = [] }) => {
    const prefix = 'tournamentInfo-';
    const { t } = useTranslation();

    return (<div className="player-tournament-list">
                <TournamentScorecardPageSection title={t(prefix + 'players')}>
                        <div className="player-tournament-list__players-holder">
                            <div className="player-tournament-list__players-sub-holder">
                                {players.map((player: User, index) =>(<div className="player-tournament-list__player-wrapper"  key={`avatar-${index}`} >
                                    <div className="player-tournament-list__avatar-holder">
                                        <RoundedAvatar avatar={player.avatar} alt="User avatar" name="user" height='50' width='50' noMargin={true} />
                                    </div>
                                    <div className="player-tournament-list__name-holder">
                                        <div>{player.firstName}</div>
                                        <div>{player.lastName}</div>
                                    </div>
                                </div>  
                                ))}
                            </div>
                        </div>
                </TournamentScorecardPageSection>
            </div>)
};