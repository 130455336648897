import React, { FC } from 'react';
import { SvgIcon } from '../../components';

interface ChevronIconProps {
    isClick: boolean;
    height?: string;
    width?: string;
    fill?: string
}
export const ChevronIcon: FC<ChevronIconProps> = (props) => {
    const { isClick, fill, ...rest } = props;
    return (
        <SvgIcon {...rest} transform={isClick ? 'rotate(180)' : ''}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M15.4375 32.1041L25 22.5625L34.5625 32.1041L37.5 29.1666L25 16.6666L12.5 29.1666L15.4375 32.1041Z"
                fill={fill || '#2C2F30'}
            />
        </SvgIcon>
    );
};
