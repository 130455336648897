import { FC } from 'react';
import classnames from 'classnames';
import './ItemWrapper.scss';

interface ItemWrapperProps {
    children?: JSX.Element | string | number;
    className?: string;
    padding?: boolean;
    overflowHidden?: boolean;
    white?: boolean;
}

export const ItemWrapper: FC<ItemWrapperProps> = ({ 
    children = '',
    className = '',
    padding = true,
    overflowHidden,
    white = false
}) => 
(<div className={classnames('item-wrapper',
                            {'item-wrapper_padding': padding,
                             'item-wrapper_overflow-hidden': overflowHidden,
                             'creative-tour-detail__gray-item': !white },
                             className)}>
    {children}
</div>);