import React from 'react';
import { SvgIcon } from '../../components';

interface ShuffleIconProps {
    w?: string;
    h?: string;
    s?: string;
}

export const ShuffleIcon = (props: ShuffleIconProps) => {
    const { w, h, s } = props;
    return (
        <SvgIcon width={w || '50'} height={h || '50'} viewBox="0 0 19 20">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M13.3601 0.880859H18.3601M18.3601 0.880859V5.88086M18.3601 0.880859L1.36011 17.8809M18.3601 13.8809V18.8809M18.3601 18.8809H13.3601M18.3601 18.8809L12.3601 12.8809M1.36011 1.88086L6.36011 6.88086"
                fill="none"
                stroke={s || '#FCFCFC'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
