import React, { FC } from 'react';
import { SvgIcon } from '../../components';

interface CheckIconProps {
    w?: number | string;
    h?: number | string;
    f?: string;
}

export const CheckIcon: FC<CheckIconProps> = ({ h, w, f }) => {
    return (
        <SvgIcon height={h || '20'} width={w || '20'} viewBox="0 0 50 50">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M43.75 14.5834L18.75 39.5834L7.29163 28.125L10.2291 25.1875L18.75 33.6875L40.8125 11.6459L43.75 14.5834Z"
                fill={f || '#FFF'}
            />
        </SvgIcon>
    );
};
