import { FC } from 'react';
import classnames from 'classnames';

import { RoundedAvatar } from '../../../../components';
import { IMatch } from '../../../tourDetails/TournamentPlay/components/PlayTag/PlayTag';

import { ClassTypeEnum } from '../../../../types/enums';

import './MatchTournamentItem.scss';
import { leadZero } from '../../../../utils/common.helpers';
import { getTime } from '../../../../utils/getStringDate';
import { useTranslation } from 'react-i18next';

interface MatchTournamentItemProps {
    match: IMatch;
    onClick: () => void;
}

export const MatchTournamentItem: FC<MatchTournamentItemProps> = ({ match, onClick }) => {
    const prefix = 'tournamentInfo-'
    const { t } = useTranslation();

    return (<div className="match-tournament-item" onClick={onClick}>
                <div className="match-tournament-item__teams">
                    <div className="match-tournament-item__team-holder">
                        <div className={classnames('match-tournament-item__avatars-wrapper')}>
                            <RoundedAvatar 
                                className="match-tournament-item__avatar_first" 
                                avatar={match.homePlayer1?.avatar || ''} 
                                alt="User avatar" 
                                name="user" 
                                height="18" 
                                width="18" 
                                noMargin={true}
                            />
                            {ClassTypeEnum.DOUBLES === match.type && (<RoundedAvatar className="match-tournament-item__avatar_second"                     
                                avatar={match.homePlayer2?.avatar || ''} 
                                alt="User avatar" 
                                name="user" 
                                height="18" 
                                width="18" 
                                noMargin={true}/>)}
                        </div>
                        <div className="match-tournament-item__team-name">
                            {match.homeTeam?.name || match.homeTeamPlaceholder}
                        </div>
                    </div>
                    <div className="match-tournament-item__team-holder">
                        <div className={classnames('match-tournament-item__avatars-wrapper')}>
                            <RoundedAvatar className="match-tournament-item__avatar_third"                      
                                avatar={match.awayPlayer1?.avatar || ''} 
                                alt="User avatar" 
                                name="user" 
                                height="18" 
                                width="18" 
                                noMargin={true}
                            />
                            {ClassTypeEnum.DOUBLES === match.type && (<RoundedAvatar className="match-tournament-item__avatar_fourth"                      
                                avatar={match.awayPlayer2?.avatar || ''} 
                                alt="User avatar" 
                                name="user" 
                                height="18" 
                                width="18" 
                                noMargin={true}
                            />)}
                        </div>
                        <div className="match-tournament-item__team-name">
                            {match.awayTeam?.name || match.awayTeamPlaceholder}
                        </div>
                    </div>
                </div>
                <div className="match-tournament-item__footer">
                    <div className="match-tournament-item__footer-number">
                        <div className="match-tournament-item__footer-header">#</div>
                        <div className="match-tournament-item__footer-body">{leadZero(match?.sortKey || 0)}</div>
                    </div>
                    <div className="match-tournament-item__footer-time">
                        <div className="match-tournament-item__footer-header">{t(prefix + 'matchTime')}</div>
                        <div className="match-tournament-item__footer-body">{getTime(match.startDate)}</div>
                    </div>
                    <div className="match-tournament-item__footer-court">
                        <div className="match-tournament-item__footer-header">{t(prefix + 'matchCourt')}</div>
                        <div className="match-tournament-item__footer-body">{(match.court as any)?.name || '-'}</div>
                    </div>
                </div>
            </div>)
}