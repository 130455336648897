import { Checkbox, FormTextField } from '../../../../../components';
import { Class, Court } from '../../../../../types/tournament';
import { useField, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { CustomValueCheckbox } from './CustomValueCheckbox';

type OptInCheckBoxArrayProps = {
    dayKey: number;
} & (OptInClassProps | OptInCourtProps);

type OptInClassProps = {
    name: 'class';
    data: Class[];
    courts: Court[];
};

type OptInCourtProps = {
    name: 'court';
    data: Court[];
};

export const OptInCheckBoxArray = (props: OptInCheckBoxArrayProps) => {
    const { name, dayKey, data } = props;

    const { t } = useTranslation();
    const prefix = 'creativeDetail-';

    const allOptionsFieldId = `all-${name}-${dayKey}`;
    const optInsGroupFieldId = `${name}-day-${dayKey}`;
    const classOptionCourtsGroupId = `class-on-court-day-${dayKey}`;

    const [allEntitiesField] = useField(allOptionsFieldId);
    const [optInEntitiesField] = useField(optInsGroupFieldId);

    return (
        <div className="container">
            <h4>{t(prefix + name + 'Title')}</h4>
            <div className="col">
                <div className="row align-items-center mb-2 pb-1">
                    <div className="col-md-1 col-2">
                        <Checkbox name={allOptionsFieldId} />
                    </div>
                    <div className="col">{t(prefix + name + 'OptIn')}</div>
                </div>

                {!allEntitiesField.value &&
                    data.map((elem) => (
                        <div className="border-bottom mb-2 pb-1" key={elem.id}>
                            <div key={`${name}-${elem.id}-day-${dayKey}`} className="row">
                                <div className="col-md-1 col-2">
                                    {/* Multiple checkboxes with the same name attribute, but different
                                value attributes will be considered a "checkbox group". Formik automatically
                                binds the checked values to a single array. */}

                                    <CustomValueCheckbox
                                        name={optInsGroupFieldId}
                                        value={`${elem.id}`}
                                        dayKey={dayKey}
                                    />
                                </div>
                                <div className="col">{elem.name}</div>
                                {name === 'class' &&
                                    (optInEntitiesField.value as string[]).includes(
                                        elem.id.toString()
                                    ) && (
                                        <>
                                            <h5 className="mb-0 mt-4">
                                                {t(prefix + 'classCourts')}
                                            </h5>
                                            <div className="d-flex flex-wrap justify-content-evenly border rounded pt-2 mt-2">
                                                {props.courts.map((court) => {
                                                    return (
                                                        <div
                                                            key={`${name}-${elem.id}-day-${dayKey}-court-${court.id}`}
                                                        >
                                                            <div className="d-flex justify-content-center">
                                                                <CustomValueCheckbox
                                                                    name={classOptionCourtsGroupId}
                                                                    value={`${elem.id}-${court.id}`}
                                                                    dayKey={dayKey}
                                                                />
                                                            </div>
                                                            <div className="col">{court.name}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    )}
                            </div>
                            {name === 'class' &&
                                (optInEntitiesField.value as string[]).includes(
                                    elem.id.toString()
                                ) && (
                                    <>
                                        <h5 className="mb-0 mt-4">
                                            {t(prefix + 'classMatchLengths')}
                                        </h5>
                                        <div className="row">
                                            <div className="col-sm">
                                                <FormTextField
                                                    type="number"
                                                    name={`defaultMatchLength-class-${elem.id}-day-${dayKey}`}
                                                    label={t(prefix + 'defaultMatchLengthLabel')}
                                                />
                                            </div>
                                            <div className="col-sm">
                                                <FormTextField
                                                    type="number"
                                                    name={`QF-matchLength-class-${elem.id}-day-${dayKey}`}
                                                    label={t(prefix + 'qf-matchLengthLabel')}
                                                />
                                            </div>
                                            <div className="col-sm">
                                                <FormTextField
                                                    type="number"
                                                    name={`SF-matchLength-class-${elem.id}-day-${dayKey}`}
                                                    label={t(prefix + 'sf-matchLengthLabel')}
                                                />
                                            </div>
                                            <div className="col-sm">
                                                <FormTextField
                                                    type="number"
                                                    name={`F-matchLength-class-${elem.id}-day-${dayKey}`}
                                                    label={t(prefix + 'f-matchLengthLabel')}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                        </div>
                    ))}
            </div>
        </div>
    );
};

interface OptInErrorProps {
    errors: FormikErrors<any>;
}
export const OptInError = ({ errors }: OptInErrorProps) => {
    return (
        <>
            {Object.keys(errors).map((key) => {
                if (key.startsWith('all-court-') || key.startsWith('all-class-')) {
                    return <p key={key}>{`${errors[key]}`}</p>;
                }
                return null;
            })}
        </>
    );
};
