import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'react-bootstrap';
import { ModalBox } from './ModalBox';
import { useAppSelector } from '../../hooks/redux';

export const DialogModal = (props: ModalProps) => {
    const { t } = useTranslation();
    const onClick = useAppSelector((state) => state.modals.data);

    const prefix = 'modal-';

    return (
        <ModalBox
            {...props}
            handleSave={() => {
                onClick();
                props.onHide && props.onHide();
            }}
            btnText={t(prefix + 'apply')}
        >
            <div className="d-flex justify-content-center pt-4 text-center">
                <h2>{t(prefix + 'sure')}</h2>
            </div>
        </ModalBox>
    );
};
