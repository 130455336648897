import React from 'react';
import { SvgIcon } from '../../components';

export const CloseCircle = () => {
    return (
        <SvgIcon width="25" height="25" viewBox="0 0 50 50">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M25 4.16667C36.5208 4.16667 45.8333 13.4792 45.8333 25C45.8333 36.5208 36.5208 45.8333 25 45.8333C13.4791 45.8333 4.16663 36.5208 4.16663 25C4.16663 13.4792 13.4791 4.16667 25 4.16667ZM32.4791 14.5833L25 22.0625L17.5208 14.5833L14.5833 17.5208L22.0625 25L14.5833 32.4792L17.5208 35.4167L25 27.9375L32.4791 35.4167L35.4166 32.4792L27.9375 25L35.4166 17.5208L32.4791 14.5833Z"
                fill="red"
            />
        </SvgIcon>
    );
};
