import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import './index.scss';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectAccessToken } from '../../features/currentUser/currentUserSelectors';
import { logout } from '../../features/currentUser/currentUserSlice';
import { useState } from 'react';

export const Header = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const token = useAppSelector(selectAccessToken);
    const dispatch = useAppDispatch();

    const [isNavOpened, setIsNavOpened] = useState(false);

    const prefix = 'header-';
    const navigationConfig = [
        {
            title: t(prefix + 'myCommunities'),
            navLink: '/communities',
            auth: true,
        },
        // {
        //     title: t(prefix + 'configuration'),
        //     navLink: '/admin-configuration',
        //     auth: true
        // },
        {
            title: t(prefix + 'logout'),
            action: () => {
                dispatch(logout());
            },
            auth: true
        },
        {
            title: t(prefix + 'signIn'),
            navLink: '/sign-in',
            auth: false
        },
        // {
        //     title: t(prefix + 'home'),
        //     navLink: 'admin',
        //     show: false
        // },
        // {
        //     title: t(prefix + 'discover'),
        //     navLink: 'discover',
        //     show: false
        // },
        // {
        //     title: t(prefix + 'adminDashboard'),
        //     navLink: 'adminDashboard',
        //     show: false
        // },
    ].filter(i => (i.auth === !!token));

    return (
        <div className="navContainer">
            <Navbar
                collapseOnSelect={true}
                expand="lg"
                variant="dark"
                onToggle={setIsNavOpened}
                onSelect={(eventKey) => {
                    eventKey && history.push(eventKey);
                }}
            >
                <Container fluid>
                    <Navbar.Brand>
                    { !isNavOpened && <div className="header-logo"
                            role="button" 
                            onContextMenu={(e) => {
                                e.preventDefault();
                            }}
                        >
                        </div>}
                            <img
                                className={classnames(['raqt-logo d-block mobile-logo', {
                                    hidden: isNavOpened,
                                    /* eslint-disable-next-line */
                                    hide: location.pathname === '/'
                                }])}
                                src={require('../../assets/images/raqt-slogan.png')}
                                alt="logo"
                                width="70"
                                height="39"
                                draggable={false}
                                style={{ cursor: 'default' }} // Add this line to set the cursor style

                            />

                    </Navbar.Brand>
                    {<>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav>
                               
                                
                                {navigationConfig.map((nav, index) => (
                                    <Nav.Link
                                        key={index}
                                        href={nav.navLink}
                                        onClick={(e) => {
                                                e.preventDefault()
                                                nav.action && nav.action();
                                            }
                                        }
                                    >
                                        {nav.title.toLocaleUpperCase()}
                                    </Nav.Link>
                                ))}
                            </Nav>
                            {/* <div className="avatarContainer d-flex justify-content-end">
                                <img
                                    className="rounded-circle"
                                    src={require('../../assets/images/user.png')}
                                    alt="logo"
                                    width="50"
                                    height="50"
                                />
                            </div> */}
                        </Navbar.Collapse>
                    </>}
                </Container>
            </Navbar>
        </div>
    );
};
