import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchClassStandingsByClassId } from '../../../../apis/tournament';
import { CustomSpinner } from '../../../../components';
import { TournamentState } from '../../../../types/tournament';
import { IMatch } from '../../../tourDetails/TournamentPlay/components/PlayTag/PlayTag';

import { MatchTournamentList } from '../MatchTournamentList';
import { PlayerTournamentList } from '../PlayerTournamentList';
import { StandingsTournametList } from '../StandingsTournametList';
import { TournamentScorecardPageMatchModal } from '../TournamentScorecardPageMatchModal';
import { TournamentScorecardPageSection } from '../TournamentScorecardPageSection';

import './TournamentScorecardPageContent.scss';

interface TournamentScorecardPageContentProps {
    classId: number;
    tournament?: TournamentState;
    selectedClassName?: string;
}

export const TournamentScorecardPageContent: FC<TournamentScorecardPageContentProps> = ({tournament, classId, selectedClassName }) => {
    const prefix = 'tournamentInfo-';

    const { t } = useTranslation();
    const [selectedClassData, setSelectedClassData] = useState(null as any);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showingModal, setShowingModal] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState<IMatch>();
    
    const getClassData = useCallback(async () => {
        if(!classId) return;

        setError(false)
        setLoading(true);

        try {
            const data = await fetchClassStandingsByClassId(classId);
            setSelectedClassData(data);
        } catch (e) {
            setError(true);
        }

        setLoading(false);
    }, [classId])

    useEffect(() => {
        classId && getClassData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classId]);

    const openModal = (item: IMatch) => {
        setSelectedMatch(item);
        setShowingModal(true);
    };

    return (<>
        {(selectedClassData && !error && (<div className="tournament-scorecard-page-content">
            <PlayerTournamentList players={selectedClassData?.players || []} />
            <TournamentScorecardPageSection title={t(prefix + 'upcoming')}>
                <MatchTournamentList matches={selectedClassData?.matches.upcoming || []} onClick={openModal}/>
            </TournamentScorecardPageSection>
            <TournamentScorecardPageSection title={t(prefix + 'ongoing')}>
                <MatchTournamentList matches={selectedClassData.matches.ongoing || []} onClick={openModal}/>
            </TournamentScorecardPageSection>
            <TournamentScorecardPageSection title={t(prefix + 'completed')}>
                <MatchTournamentList matches={selectedClassData.matches.completed || []} onClick={openModal}/>
            </TournamentScorecardPageSection>
            <TournamentScorecardPageMatchModal 
                size="xl" 
                show={showingModal} 
                onHide={() => setShowingModal(false)}
                tournament={tournament}
                match={selectedMatch}
                selectedClassName={selectedClassName}
            />
            <TournamentScorecardPageSection title={t(prefix + 'standings')}>
                <StandingsTournametList format={selectedClassData.format} matches={[
                    ...selectedClassData.roundStandings,
                    ...selectedClassData.groupStandings,
                ]}/>
            </TournamentScorecardPageSection>
        </div>))} 
        {loading && (<div className="tournament-scorecard-page-content__loading">
            <div className="d-flex justify-content-center align-items-center">
                <CustomSpinner />
            </div>
        </div>)}
        {error && (<div className="tournament-scorecard__error" onClick={getClassData} role="button">
                        <h1>{t(prefix + 'error')}</h1>
                        <h2>{t(prefix + 'reload')}</h2>
                    </div>)}
    </>)
}