import { ChangeEvent, useCallback, useState } from 'react';
import { Form, ModalProps } from 'react-bootstrap';
import { ButtonImagePreview, ModalBox } from '../../../components';
import { useAppSelector } from '../../../hooks/redux';
import uniqId from 'uniqid';
import { ConfigData } from '../../../types/adminConfiguration';
import { useTranslation } from 'react-i18next';
import { uploadImage } from '../../../apis/tournament';
import { ImageListType } from 'react-images-uploading';

export const TourModal = (props: ModalProps) => {
    const setConfigData = useAppSelector((state) => state.modals.data);
    const { t } = useTranslation();
    const prefix = 'tourModal-';

    const [tour, setTour] = useState({
        description: '',
        value: '',
        colorTop: '#2C2F30',
        colorBottom: '#909798',
        avatar: '',
        logo: '',
    });

    const handleSave = useCallback(() => {
        setConfigData((prevSate: ConfigData) => ({
            ...prevSate,
            tours: [...prevSate.tours, { id: uniqId(), ...tour }],
        }));
        props.onHide && props.onHide();
    }, [props, setConfigData, tour]);

    const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTour((prevState) => ({
            ...prevState,
            [e.target.name]:
                e.target.name === 'value'
                    ? e.target.value.trim().toUpperCase()
                    : e.target.value.trim(),
        }));
    };

    const handleColorInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const regex = /[0-9A-Fa-f]/g;
        const maxHexLength = 7;

        let color = e.target.value || '#';
        
        if(color[0] !== '#') {
            color = `#${color}`;
        }

        color = color.replace('##', '#').slice(0,maxHexLength);

        setTour((prevState) => ({
            ...prevState,
            [e.target.name]: (regex.test(color) || color === '#') ? color : prevState[e.target.name as 'colorTop' | 'colorBottom'] as string,
        }));
    };

    const onChange = useCallback(
        (imageList: ImageListType, key: 'logo' | 'avatar') => {
            const formData = new FormData();
            formData.append('file', imageList[0]?.file || '');
            imageList[0].file &&
                uploadImage(formData, key)
                    .then((data) => {
                        setTour((prevState) => ({ ...prevState, [key]: data.url }));
                    })
                    .catch((e) => {
                        console.log(e);
                    });
        },
        []
    );

    return (
        <ModalBox
            {...props}
            handleSave={handleSave}
            isDisabled={!Boolean(tour.value && tour.description)}
        >
            <div className="d-flex flex-column">
                <p className="font-weight-normal mb-1">{t(prefix + 'nameLabel')}</p>
                <Form.Control
                    autoComplete="off"
                    name="value"
                    placeholder={t(prefix + 'namePlaceholder')}
                    onChange={handleInput}
                    maxLength={50}
                />
                <p className="font-weight-normal my-1">{t(prefix + 'descriptionLabel')}</p>
                <Form.Control
                    autoComplete="off"
                    name="description"
                    placeholder={t(prefix + 'descriptionPlaceholder')}
                    onChange={handleInput}
                />
                <p className="font-weight-normal my-1">{t(prefix + 'colorTop') + ':'}</p>
                <div className="tour-modal__color-wrapper">
                    <Form.Control
                        autoComplete="off"
                        name="colorTop"
                        value={tour.colorTop}
                        placeholder={t(prefix + 'colorTop')}
                        onChange={handleColorInput}
                    />
                    <Form.Control
                        autoComplete="off"
                        name="colorTop"
                        type="color"
                        value={tour.colorTop}
                        defaultValue="#909798"
                        placeholder={t(prefix + 'colorTop')}
                        onChange={handleInput}
                    />
                </div>
                <p className="font-weight-normal my-1">{t(prefix + 'colorBottom') + ':'}</p>
                <div className="tour-modal__color-wrapper">
                    <Form.Control
                        autoComplete="off"
                        name="colorBottom"
                        value={tour.colorBottom}
                        placeholder={t(prefix + 'colorBottom')}
                        onChange={handleColorInput}
                    />
                    <Form.Control
                        autoComplete="off"
                        name="colorBottom"
                        type="color"
                        value={tour.colorBottom}
                        defaultValue="#909798"
                        placeholder={t(prefix + 'colorBottom')}
                        onChange={handleInput}
                    />
                </div>
                <ButtonImagePreview
                    title={t(prefix + 'avatar') + ':'}
                    onChange={(i: ImageListType) => onChange(i, 'avatar')}
                    src={tour.avatar}
                    alt="Avatar"
                    btnTxt={t(prefix + 'addAvatar')}
                />
                <ButtonImagePreview
                    title={t(prefix + 'logo') + ':'}
                    onChange={(i: ImageListType) => onChange(i, 'logo')}
                    src={tour.logo}
                    alt="Logo"
                    btnTxt={t(prefix + 'addLogo')}
                />
            </div>
        </ModalBox>
    );
};
