import { api } from './api.config';

export const createRegistrationForm = async (data: any): Promise<any> => {
    const response = await api.post('registration-form', data);
    return response.data;
};

export const getAllRegistrationForm = async (): Promise<any> => {
    const response = await api.get('registration-form');
    return response.data;
};