import React from 'react';
import { SvgIcon } from '../../components';

interface AddIconProps {
    w?: string;
    h?: string;
    f?: string;
}

export const AddIcon = (props: AddIconProps) => {
    const { w, h, f } = props;
    return (
        <SvgIcon width={w || '14'} height={h || '14'} viewBox="0 0 50 50">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M39.5833 27.0834H27.0833V39.5834H22.9166V27.0834H10.4166V22.9167H22.9166V10.4167H27.0833V22.9167H39.5833V27.0834Z"
                fill={f || 'black'}
            />
        </SvgIcon>
    );
};
