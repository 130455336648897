import { Form, Formik, Field, FieldProps, FormikProps, FormikErrors } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import classnames from 'classnames';
import { createOrganizationAPI, verifyAvailabilityOrganizationAPI } from "../../apis/organizations";

import { CheckIcon } from "../../assets/icons/CheckIcon";
import { PictureIcon } from "../../assets/icons/PictureIcon";
import { CustomButton, FormTextField } from "../../components";
import { InfoImageUpload } from "../tourDetails/Information/components/InfoImageUpload";

import './OrganisationCreate.scss';

interface IOrganisationName {
    name: string;
}

interface IOrganisation {
    img?: string;
    type?: number; // set to 1
    country?: number; // set to 1
    verified?: boolean; // Don't know. Let it be 1.
    description?: string;
}


export const OrganisationCreate = () => {
    const prefix = 'communityCreate-';
    const [nameAfterValidation, setNameAfterValidation] = useState<string | null>(null);

    const { t } = useTranslation();
    const history = useHistory();

    const nameForm = {
        name: '',
    };
    const nameSchema = Yup.object().shape({
        name: Yup.string().required(),
    });
    const nameFormRef = useRef<FormikProps<IOrganisationName>>(null);
    const handleNameForm = async (value: IOrganisationName) => {
        if(!value.name) return;
        const { isAvailable } = await verifyAvailabilityOrganizationAPI(value);

        isAvailable && setNameAfterValidation(value.name);

        if(!isAvailable) nameFormRef.current?.setFieldError('name', t(prefix + 'nameIsNotAvaliable'));
    };
    
    
    const organisationForm = {
        img: '',
        description: '',
        country: 1,
        verified: true,
        type: 1,
    };
    const organisationSchema = Yup.object().shape({
        img: Yup.string(),
        description: Yup.string(),
    });
    const handleOrganisationForm = async (value: IOrganisation) => {
        const errors = (await nameFormRef.current?.validateForm(nameFormRef.current.values)) as FormikErrors<IOrganisationName>;

        if(Object.keys(errors).length) {
            nameFormRef.current?.setTouched({name: true});
            nameFormRef.current?.setErrors(errors);
            return;
        }

        if(!nameAfterValidation) {
            nameFormRef.current?.setFieldError('name', t(prefix + 'nameIsNotChecked'));
            return;
        }

        const organisation = await createOrganizationAPI({name: nameAfterValidation, ...value});

        history.push(`/community/${organisation.id}`);
    }
    
    const handleCancel = () => history.push(`/communities`);

return <div className="organisation-create">
            <div className="organisation-create__title">{t(prefix + 'title')}</div> 
            <Formik validationSchema={nameSchema}  initialValues={nameForm}  onSubmit={handleNameForm} innerRef={nameFormRef}>
                <Form className="organisation-create__single-item" onChange={() => setNameAfterValidation(null)}>
                    <div className={classnames('organisation-create__single-item_text-field', {
                        valid: !!nameAfterValidation
                    })}>
                        <FormTextField
                            name="name"
                            type="text"
                            className=" "
                            label={t(prefix + 'commName')}
                        />
                        {/* <ErrorMessage component="div" name="name" className="field-error text-danger fs-7" /> */}
                    </div>
                    <div className="organisation-create__single-item_submit">
                        <CustomButton btnText={t(prefix + 'verfiyAvalibility')} type="submit" variant="outline-dark">
                            <CheckIcon w="50px" h="25px" f="#000"/>
                        </CustomButton>
                    </div>
                </Form>
            </Formik>
            <Formik validationSchema={organisationSchema}  initialValues={organisationForm}  onSubmit={handleOrganisationForm}>
                <Form>
                    <div>
                        <FormTextField
                            name="description"
                            type="text"
                            label={t(prefix + 'commDesc')}
                        />
                    </div>
                    <div>
                        <div>
                        <Field name="img">
                            {({ field, form }: FieldProps) => {
                                return (
                                    <InfoImageUpload
                                        title={t(prefix + 'logo')}
                                        setFieldValue={form.setFieldValue}
                                        imgUrl={field.value || require('../../assets/images/noImage.png')}
                                        savePath="organization"
                                        settingData={{ name: field.name }}
                                    >
                                        <div className="organisation-create__image-button-section">
                                            <div className="organisation-create__image-button-wrapper">
                                                <CustomButton btnText={t(prefix + 'imageButton')} variant="outline-dark">
                                                    <PictureIcon w="30"/>
                                                </CustomButton>
                                            </div>
                                            <div className="organisation-create__image-text-wrapper" role="textbox" onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}>
                                                {t(prefix + 'imageDesc')}
                                            </div>
                                        </div>
                                    </InfoImageUpload>
                                );
                            }}
                        </Field>
                        </div>
                    </div>
                    <div className="organisation-create__button-wrapper">
                        <CustomButton btnText={t(prefix + 'createCancel')} variant="outline-dark" onClick={handleCancel}/>
                       
                        <CustomButton className="organisation-create__submit-form-button" btnText={t(prefix + 'createSubmit')} type="submit">
                            <CheckIcon w="50px" h="25px" f="#000"/>
                        </CustomButton>
                    </div>
                </Form>
            </Formik>
</div>
};