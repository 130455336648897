import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, ModalProps } from 'react-bootstrap';
import { ModalBox } from '../../../components';
import { customStyles } from '../../../components/modal/MemberModal';
import Select from 'react-select';
import { configModalOptions } from '../../../constant/configuration';
import { useAppSelector } from '../../../hooks/redux';
import { ConfigData } from '../../../types/adminConfiguration';
import uniqId from 'uniqid';
import { traslateItemWithLabel } from '../../../utils/traslation.helpers';

export const KeywordsModal = (props: ModalProps) => {
    const { t } = useTranslation();
    const setConfigData = useAppSelector((state) => state.modals.data);
    const [name, setName] = useState({ value: '', categoryName: configModalOptions[0].value });

    const prefix = 'modal-';
    const inputPrefix = 'input-';

    const handleSave = useCallback(() => {
        if (name.categoryName === 1) {
            setConfigData((prevSate: ConfigData) => ({
                ...prevSate,
                balls: [...prevSate.balls, { id: uniqId(), value: name.value }],
            }));
        } else if (name.categoryName === 2) {
            setConfigData((prevSate: ConfigData) => ({
                ...prevSate,
                surfaces: [...prevSate.surfaces, { id: uniqId(), value: name.value }],
            }));
        } else if (name.categoryName === 3) {
            setConfigData((prevSate: ConfigData) => ({
                ...prevSate,
                sport: [...prevSate.sport, { id: uniqId(), value: name.value }],
            }));
        }  else if (name.categoryName === 4) {
            setConfigData((prevSate: ConfigData) => ({
                ...prevSate,
                tournamentType: [...prevSate.tournamentType, { id: uniqId(), value: name.value }],
            }));
        }
        props.onHide && props.onHide();
    }, [name, props, setConfigData]);

    const handleSelect = (option: { value: number; label: string }) => {
        setName((prevState) => ({ ...prevState, categoryName: option.value }));
    };

    return (
        <ModalBox {...props} handleSave={handleSave} isDisabled={!name.value}>
            <div className="d-flex flex-column">
                <p className="font-weight-normal mb-1">{t(prefix + 'category') + ':'}</p>
                <Select
                    defaultValue={traslateItemWithLabel(t,configModalOptions[0])}
                    styles={customStyles}
                    name="keywordOption"
                    options={configModalOptions.map(item => traslateItemWithLabel(t, item))}
                    className="basic-single holder-select"
                    classNamePrefix="select"
                    // @ts-ignore
                    onChange={handleSelect}
                />
                <p className="font-weight-normal my-1">{t(prefix + 'name') + ':'}</p>
                <Form.Control
                    maxLength={50}
                    placeholder={t(inputPrefix + 'placeholderName')}
                    onChange={(e) => {
                        setName((prevState) => ({ ...prevState, value: e.target.value.trim() }));
                    }}
                />
            </div>
        </ModalBox>
    );
};
