import { ClassesTag } from './components/ClassesTag';
import { CustomSpinner } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchTournamentClassListPure } from '../../../features/class/classActions';
import { selectClass } from '../../../features/class/classSelectors';
import { fetchTournamentInfo } from '../../../features/tournament/actionCreators';


export const TournamentClasses = () => {
    const { id } = useParams<{ id: string }>();
    const { isLoading } = useAppSelector(selectClass);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTournamentInfo(+id));
        dispatch(fetchTournamentClassListPure(+id));
    }, [dispatch, id]);

    return <div>
        {(isLoading && (<CustomSpinner />)) || <ClassesTag />}
    </div>
};