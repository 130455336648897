import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ItemWrapper } from '../../../../../components';
import { selectTournament } from '../../../../../features/tournament/tournamentSelectors';

import './TournamentCardHeadSection.scss';

interface TournamentCardHeadSectionProps {
    matchId: string; 
    court?: string;
}

export const TournamentCardHeadSection: FC<TournamentCardHeadSectionProps> = ({matchId, court = ''}) => {
    const prefix = 'printCardHead-';

    const { t } = useTranslation();
    const { data } = useSelector(selectTournament) || {} as any;
    
    return (<div className="tournament-card-head-section">
        <div className="tournament-card-head-section__logo-name">
            <div className="tournament-card-head-section__logo-holder">
                <img src={data?.icon || require('../../../../../assets/images/noImage.png')} alt="tournament logo" />
            </div>
            <div className='tournament-card-head-section__name'>
                {data?.name}
            </div>
        </div>
        <div className="tournament-card-head-section__info">
            <div className="tournament-card-head-section__court">
                <div className="tournament-card-head-section__court-title">{t(prefix + 'court')}</div>
                <ItemWrapper className="tournament-card-head-section__information-text-box">{court}</ItemWrapper>
            </div>
            <div className="tournament-card-head-section__match-id">
                <div className="tournament-card-head-section__match-id-title">{t(prefix + 'matchId')}</div>
                <ItemWrapper className="tournament-card-head-section__information-text-box">{matchId}</ItemWrapper>
            </div>
        </div>
    </div>)
};