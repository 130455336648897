import { FC } from 'react';
import classnames from 'classnames';

import { CheckCircleIcon } from '../../../../../assets/icons/CheckCircleIcon';
import { PlayIcon } from '../../../../../assets/icons/PlayIcon';
import { StopCircleIcon } from '../../../../../assets/icons/StopCircleIcon';
import { updateStatusPlay } from '../../../../../features/tournament/actionCreators';
import { useAppDispatch } from '../../../../../hooks/redux';
import { TournamentMatchStatusEnum } from '../../../../../types/enums';

import './MatchStatus.scss';

interface MatchStatusProps {
    matchId: string | number;
    results?: any[];
    status?: number;
    disabled?: boolean;
}

export const MatchStatus: FC<MatchStatusProps> = ({matchId, status = 0, disabled = false, results = []}) => {
    const dispatch = useAppDispatch();
    
    return (<div className={classnames(['match-status__wrapper', {'match-status__wrapper_disabled': disabled}])} role="button"
                onClick={() => {
                    if (disabled) return;

                    status !== undefined && status < 2 && dispatch(
                            updateStatusPlay(Number(matchId), {
                                status: Number(status) + 1,
                            })
                        )
                        
                }}
            >
                {status === TournamentMatchStatusEnum.NOT_STARTED && (<PlayIcon w="35" h="35" f="#34C765" />)}
                {status === TournamentMatchStatusEnum.STARTED && (<StopCircleIcon w="30" h="30"/>)}
                {status >= TournamentMatchStatusEnum.FINISHED && (<CheckCircleIcon w="35" h="35" f={
                    results.length ? '#34C765' : '#FF0F65'} />)}
            </div>)
}
