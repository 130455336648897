import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { AddIcon } from "../../assets/icons/AddIcon";
import { fetchOrganizationsList } from "../../features/organizations/organizationsActions";
import { getIsLoading, getOrganizationsList } from "../../features/organizations/organizationsSelectors";
import { clearStats } from "../../features/organizations/organizationsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";

import './OrganisationList.scss';

const truncateString = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + '...';
  };

export const OrganisationList = () => {
    const prefix = 'communityList-';
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    
    const [filter, setFilter] = useState("");
    const list = useAppSelector(getOrganizationsList);
    const loading = useAppSelector(getIsLoading);

    useEffect(() => {
        dispatch(clearStats());
        dispatch(fetchOrganizationsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to filter organizations based on name
    const filteredList = list?.filter(org => org.name.toLowerCase().includes(filter.toLowerCase()));

    return (
        <div className="raqt-organisation-list">
           
            <div className="raqt-organisation-list__communities">

                <div className="raqt-organisation-list__filterList">
                        <input
                            className="raqt-organisation-list__filterInput"
                            type="text"
                            placeholder="Filter by name"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                </div>

                <div className="raqt-organisation-list__cardNew">
 
                    <NavLink to="/communities/create">
                        <div className="raqt-organisation-list__cardContent">
                            <div className="raqt-organisation-list__card-title">
                                {t(prefix + 'createTitle')}
                            </div>

                            <div className="raqt-organisation-list__create-icon">
                                <AddIcon h="30" w="30" />
                            </div>
                            
                        </div>
                    </NavLink>
                </div>    

                
 
                {!loading && filteredList && filteredList.length > 0 ? (
                    filteredList.map((v) => (

                        <div className="raqt-organisation-list__cardNew">

                        <NavLink key={`organisation-list-${v.id}`} to={`/community/${v.id}`}>

                            <div className="raqt-organisation-list__cardContent">
                                    <div className="raqt-organisation-list__image">
                                        <img
                                            style={{
                                                objectFit: 'contain',
                                            }}
                                            className="card-img-top"
                                            src={v.img || require('../../assets/images/noImage.png')}
                                            alt={v.name}
                                        />
                                    </div>
                                    <div className="raqt-organisation-list__card-text">

                                        <div className="raqt-organisation-list__card-title">
                                            <span>{truncateString(v.name, 30)}</span>
                                        </div>

                                    </div>
                                </div>

                        </NavLink>
                        </div>
                        
                       
                    ))
                ) : (
                    <div>No organizations found.</div>
                )}


            </div>
           
        </div>
    );
};
