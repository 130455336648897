import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ClassFormatEnum } from '../../../../types/enums';
import { IStandingGroup, IStandingRound } from '../../TournamentScorecardPage.types';

import { TournamentScorecardPageGroupInfo } from '../TournamentScorecardPageGroupInfo';
import { TournamentScorecardPageMatches } from '../TournamentScorecardPageMatch';
import { getLooserBracket, getWinnerBracket } from './TournamentScorecardPageStandingsSwitcher.helpers';

import './TournamentScorecardPageStandingsSwitcher.scss';


type TournamentScorecardPageStandingsSwitcherProps =(IStandingRound | IStandingGroup) & {format: ClassFormatEnum};

export const TournamentScorecardPageStandingsSwitcher: FC<TournamentScorecardPageStandingsSwitcherProps> = ({ isGroup, format, ...data }) => {
    const prefix = 'tournamentStandings-';

    const { t } = useTranslation();

    return (<div className="tournament-scorecard-page-standings-switcher">
        {isGroup && (<TournamentScorecardPageGroupInfo {...data as IStandingGroup}/>)}
        {
            format === ClassFormatEnum.BRACKET &&
            (data as IStandingRound).matches &&
            ((data as IStandingRound).round !== 13 ? (
            <>
                <TournamentScorecardPageMatches
                    matches={getWinnerBracket((data as IStandingRound).matches)}
                    name={t(prefix + 'winnerBracket')}
                />
                <TournamentScorecardPageMatches
                    matches={getLooserBracket((data as IStandingRound).matches)}
                    name={t(prefix +'consolationBracket')}
                />
            </>
            ) : (
            <>
                <TournamentScorecardPageMatches
                    matches={
                        (data as IStandingRound).matches[0].isGold
                        ? [(data as IStandingRound).matches[0]]
                        : [(data as IStandingRound).matches[1]]
                    }
                    name={t(prefix + 'winnerBracket')}
                />
                <TournamentScorecardPageMatches
                    matches={
                        !(data as IStandingRound).matches[0].isGold
                        ? [(data as IStandingRound).matches[0]]
                        : [(data as IStandingRound).matches[1]]
                    }
                    name={t(prefix + 'consolationBracket')}
                />
            </>
            ))}
        { format === ClassFormatEnum.GROUP &&
            (data as IStandingRound).round !== -1 &&
            (data as IStandingRound).matches &&
            ((data as IStandingRound).round !== 13 ? (
            <TournamentScorecardPageMatches
                matches={(data as IStandingRound).matches}
                name={(data as IStandingRound).name}
            />
            ) : (
            <>
                <TournamentScorecardPageMatches
                    matches={
                        (data as IStandingRound).matches[0].isGold
                        ? [(data as IStandingRound).matches[0]]
                        : [(data as IStandingRound).matches[1]]
                    }
                    name={t(prefix + 'gold')}
                />
                <TournamentScorecardPageMatches
                    matches={
                        !(data as IStandingRound).matches[0].isGold
                        ? [(data as IStandingRound).matches[0]]
                        : [(data as IStandingRound).matches[1]]
                    }
                    name={t(prefix + 'bronze')}
                />
            </>
            ))}
    </div>)
};