import { ITournamentStats } from './../../apis/tournament';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Group, Match, TournamentState } from '../../types/tournament';

type State = {
    data: TournamentState | null;
    isLoading: boolean;
    error: string;
    message: string;
    stats: ITournamentStats | null;
};

const initialState: State = {
    data: null,
    isLoading: false,
    error: '',
    message: '',
    stats: null,
};

export const tournamentSlice = createSlice({
    name: 'tournament',
    initialState,
    reducers: {
        tournamentFetching: (state) => {
            state.isLoading = true;
        },
        tournamentClearNotification: (state) => {
            state.error = '';
            state.message = '';
        },
        tournamentFetchingSuccess: (state, action: PayloadAction<TournamentState>) => {
            state.isLoading = false;
            state.error = '';
            state.data = action.payload || null;
        },
        tournamentStatsSuccess: (state, action: PayloadAction<ITournamentStats>) => {
            return {
                ...state,
                stats: action.payload,
            }
        },
        tournamentFetchingError: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        tournamentUpdate: (state) => {
            state.isLoading = true;
        },
        tournamentUpdateSuccess: (state, action: PayloadAction<TournamentState>) => {
            state.isLoading = false;
            state.error = '';
            state.message = 'toast-tournamentUpdated';
            state.data = {...(state.data || {}), ...action.payload};
        },
        tournamentUpdatePlay: (
            state,
            action: PayloadAction<{
                id: number;
                tournamentGroups: Group[];
                tournamentMatches: Match[];
            }>
        ) => {
            if (state.data) {
                state.message = 'toast-tournamentPlayUpdated';
                state.data = {
                    ...state.data,
                    classes: state.data?.classes.map((el) =>
                        el.id === action.payload.id
                            ? {
                                  ...el,
                                  tournamentMatches: action.payload.tournamentMatches.map((el) => ({
                                      ...el,
                                      court:
                                          state.data?.courts.find(
                                              (court) => court.id === el.court?.id
                                          ) || null,
                                  })),
                                  tournamentGroups: action.payload.tournamentGroups,
                              }
                            : el
                    ),
                };
            }
        },
        tournamentUpdateError: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearTournament: () => {
            return initialState;
        },
    },
});

export const {
    tournamentFetching,
    tournamentFetchingSuccess,
    tournamentFetchingError,
    tournamentUpdate,
    tournamentUpdateSuccess,
    tournamentUpdateError,
    clearTournament,
    tournamentClearNotification,
    tournamentUpdatePlay,
    tournamentStatsSuccess,
} = tournamentSlice.actions;

export default tournamentSlice.reducer;
