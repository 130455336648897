import { useTranslation } from 'react-i18next';
import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';
import { useAppSelector } from '../../../../../hooks/redux';

export const UnscheduledReport = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const { unscheduledMatches, classIdToObject } = useAppSelector(selectSchedule);

    const unscheduledMatchesByClass = unscheduledMatches.reduce((accumulator, match) => {
        if (accumulator[match.classId] === undefined) {
            accumulator[match.classId] = 1;
        } else {
            accumulator[match.classId]++;
        }
        return accumulator;
    }, {} as Record<string, number>);

    return (
        <>
            <div className="alert alert-danger">
                <div className="row">
                    {t(prefix + 'unscheduledReportSummary')}
                    <br />
                </div>
                {Object.entries(unscheduledMatchesByClass).map(
                    ([classId, numberOfUnscheduledMatches]) => {
                        return (
                            <div className="row">
                                {classIdToObject[classId].name}: {numberOfUnscheduledMatches}
                            </div>
                        );
                    }
                )}
            </div>
            <div className="alert alert-info">{t(prefix + 'unscheduledReportInfo')}</div>
        </>
    );
};
