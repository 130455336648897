import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, Tour } from '../../types/tournament';

interface SettingState {
    officialsMembers: Array<User>;
    listTour: Array<Tour>;
    listKeywords: Array<any>;
    listSponsor: Array<any>;
    listPaddle: Array<any>;
}

const initialState: SettingState = {
    officialsMembers: [],
    listTour: [],
    listKeywords: [],
    listSponsor: [],
    listPaddle: [],
};

export const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        addMembers: (state, action: PayloadAction<User[]>) => {
            return {
                ...state,
                officialsMembers: action.payload,
            };
        },
        deleteMember: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                officialsMembers: state.officialsMembers.filter(
                    (member) => member.id !== action.payload
                ),
            };
        },
        setListTour: (state, action: PayloadAction<Tour[]>) => {
            return { ...state, listTour: action.payload };
        },
        setListPaddle: (state, action: PayloadAction<any[]>) => {
            return { ...state, listPaddle: action.payload };
        },
        setListSponsor: (state, action: PayloadAction<any[]>) => {
            return { ...state, listSponsor: action.payload };
        },
        setListKeywords: (state, action: PayloadAction<any[]>) => {
            return { ...state, listKeywords: action.payload };
        },
    },
});

export const { addMembers, deleteMember, setListTour, setListPaddle, setListSponsor, setListKeywords } = settingSlice.actions;

export default settingSlice.reducer;
