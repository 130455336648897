import React from 'react';
export interface SvgIconProps extends React.ComponentPropsWithoutRef<'svg'> {}

export const SvgIcon = (props: SvgIconProps) => {
    return (
        <svg
            width="50"
            height="50"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            fill="none"
            aria-hidden
            {...props}
        />
    );
};
