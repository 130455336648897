import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TournamentState } from '../../types/tournament';

export type TournamentsState = Omit<
    TournamentState,
    'officials' | 'sections' | 'courts' | 'classes'
>;

type State = {
    data: Array<TournamentsState>;
    isLoading: boolean;
    error: string;
    message: string;
};

const initialState: State = {
    data: [],
    isLoading: false,
    error: '',
    message: '',
};

export const tournamentsSlice = createSlice({
    name: 'tournaments',
    initialState,
    reducers: {
        tournamentsFetching: (state) => {
            state.isLoading = true;
        },
        clearTournamentsNotifications: (state) => {
            state.error = '';
            state.message = '';
        },
        tournamentsFetchingSuccess: (state, action: PayloadAction<TournamentsState[]>) => {
            state.isLoading = false;
            state.error = '';
            state.data = action.payload;
        },
        tournamentsFetchingError: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        tournamentDeleteSuccess: (
            state,
            action: PayloadAction<{ affected: 0 | 1; id: number }>
        ) => {
            if (action.payload.affected) {
                state.data = state.data.filter((tournament) => tournament.id !== action.payload.id);
            } else {
                return state;
            }
            state.isLoading = false;
            state.message = 'toast-tournamentDeleted';
        },
    },
});

export const {
    tournamentsFetching,
    tournamentsFetchingSuccess,
    tournamentsFetchingError,
    tournamentDeleteSuccess,
    clearTournamentsNotifications,
} = tournamentsSlice.actions;

export default tournamentsSlice.reducer;
