import { api } from './api.config';

import { Class } from './../types/tournament';

export const fetchClassByIdData = async (id: number): Promise<Class> => {
    const response = await api.get(`tournament/class/${id}`);
    return response.data;
};

export const fetchClassPlayData = async (id: number): Promise<Class> => {
    const response = await api.get(`tournament/class/${id}/play`);
    return response.data;
};

export const fetchClassTeamsData = async (id: number): Promise<Class> => {
    const response = await api.get(`tournament/class/${id}/teams`);
    return response.data;
};

export const fetchClassTournamentGroupsData= async (id: number): Promise<Class> => {
    const response = await api.get(`tournament/class/${id}/tournament-groups`);
    return response.data;
};

export const fetchClassMatchesData = async (id: number): Promise<Class> => {
    const response = await api.get(`tournament/class/${id}/tournament-matches`);
    return response.data;
};

export const createClassData = async (tornamentId: number, data: any): Promise<Class> => {
    const response = await api.post(`tournament/${tornamentId}/class`,
        data
    );
    return response.data;
};

export const updateClassData = async (tornamentId: number, classId: number, data: any): Promise<Class> => {
    // TODO: temporary fields: organization & creator, because out of registration
    const response = await api.patch(`tournament/${tornamentId}/class/${classId}`,
        data
    );
    return response.data;
};

export const assignTeamsForElimination = async (classId: number): Promise<Class> => {
    const response = await api.post(`tournament/class/${classId}/assign-teams`);
    return response.data;
};
