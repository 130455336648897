import { fetchOrganizationsList } from './organizationsActions';
import { useAppDispatch } from './../../hooks/redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import { RootState } from './../../app/store';

//Simple selectors
export const getIsLoadedOnce = (state: RootState) => Array.isArray(state.organizations.list);
export const getOrganizationsList = (state: RootState) => state.organizations.list;
export const getIsLoading = (state: RootState) => state.organizations.initialLoading;
export const getOrganizationStats = (state: RootState) => state.organizations.stats;

//Hooks
export const useGetCurrentOrganization = () => {
    const dispatch = useAppDispatch();
    const { communityId } = useParams<{ communityId: string }>();

    const organizationList = useAppSelector(getOrganizationsList);
    const organization = organizationList?.find(({ id }) => id === +communityId) || null;

    useEffect(() => {
        !organization && dispatch(fetchOrganizationsList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization])

    return organization;
};