import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/tournament';

type State = {
    userData: Array<User>;
    isLoading: boolean;
    error: string;
};

const initialState: State = {
    userData: [],
    isLoading: false,
    error: '',
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        userFetching: (state) => {
            state.isLoading = true;
        },
        userFetchingSuccess: (state, action: PayloadAction<User[]>) => {
            state.isLoading = false;
            state.error = '';
            state.userData = action.payload;
        },
        addUsersFromSearch: (state, action: PayloadAction<User[]>) => {
            const newUsers = (action.payload || []).filter(nu => !state.userData.find(ou => ou.id === nu.id));
            state.userData = [...state.userData, ...newUsers];
        },
        userFetchingError: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearUsers: (state) => {
            return initialState;
        },
    },
});

export const { userFetching, userFetchingError, userFetchingSuccess, clearUsers, addUsersFromSearch } =
    usersSlice.actions;

export default usersSlice.reducer;
