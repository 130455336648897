import { FC } from 'react';
import classnames from 'classnames';

import './BubbleTabItem.scss';

interface BubbleTabItemProps {
    className?: string;
    children: string;
    active?: boolean;
    onClick: () => void;
}
export const BubbleTabItem: FC<BubbleTabItemProps> = ({ children, active, className, onClick }) => 
    (<div onClick={onClick} className={classnames('bubble-tab-item',{'bubble-tab-item_active': active}, className)}>
        { children }
    </div>);