import { FC } from "react";
import { BubbleWithText } from "../BubbleWithText";

import './BubbleSequence.scss';

interface BubbleSequenceProps {
    amount: number | string
} 

export const BubbleSequence: FC<BubbleSequenceProps> = ({ amount }) => (<div className="bubble-sequence">
    {(new Array(Number(amount))).fill(null).map((v,index) => 
        <div key={`bubble-sequence-index-${index}`} className="bubble-sequence__item">
            <BubbleWithText content={index + 1}/>
        </div>)}
</div>);