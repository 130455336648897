import './OrganisationSettings.scss';

import { Form, Formik, Field, FieldProps, FormikProps, FormikErrors } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import AsyncSelect from 'react-select/async';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import { addAdminsToOrganisationAPI, fetchOrganizationAllAdminsAPI, IFetchOrganizationAllAdminsResponse, IOrganization, removeAdminsFromOrganisationAPI, updateOrganizationAPI, verifyAvailabilityOrganizationAPI } from "../../apis/organizations";

import { CheckIcon } from "../../assets/icons/CheckIcon";
import { PictureIcon } from "../../assets/icons/PictureIcon";
import { ButtonIcon, CustomButton, FormTextField, RoundedAvatar } from "../../components";
import { InfoImageUpload } from "../tourDetails/Information/components/InfoImageUpload";

import './OrganisationSettings.scss';
import { useGetCurrentOrganization } from '../../features/organizations/organizationsSelectors';
import { Accordion, Card } from 'react-bootstrap';
import { CustomAccordionHeader } from '../tourDetails/common/components/CustomAccordionHeader';
import { imgOptionUserLabel } from '../../utils/imgOptionUserLabel';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { addUsersFromSearch } from '../../features/users/userSlice';
import { fetchUsersData } from '../../apis/tournament';
import { User } from '../../types/tournament';
import { StylesConfig } from 'react-select';
import { CloseCircle } from '../../assets/icons/CloseCircle';
import { updateOrganizationsList } from '../../features/organizations/organizationsSlice';

type Option = { value: number; label: string };

interface IOrganisationName {
    name: string;
}

interface IOrganisationDescription {
    description?: string;
}

interface IOrganisationSettings {
    img?: string;
    type?: number; // set to 1
    country?: number; // set to 1
    verified?: boolean; // Don't know. Let it be 1.
}


export const customStyles: StylesConfig = {
    control: (base) => ({ ...base, borderRadius: '16px', minHeight: '50px' }),
};

export const OrganisationSettings = () => {
    const prefix = 'communitySettings-';

    const { userData } = useAppSelector((state) => state.users);
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const currentOrganization = useGetCurrentOrganization();
    const [nameAfterValidation, setNameAfterValidation] = useState<string | null>(currentOrganization?.name || null);
    const [multiSelections, setMultiSelections] = useState<IFetchOrganizationAllAdminsResponse[]>([]);
    const [amountOfOptions, setAmountOfOptions] = useState(0);
    const [admins, setAdmins] = useState<IFetchOrganizationAllAdminsResponse[]>([]);
    const [deletedAdmins, setDeletedAdmins] = useState<number[]>([]);

    const nameForm = {
        name: currentOrganization?.name || '',
    };

    const nameSchema = Yup.object().shape({
        name: Yup.string().required(),
    });
    const nameFormRef = useRef<FormikProps<IOrganisationName>>(null);
    const handleNameForm = async (value: IOrganisationName) => {
        if(!value.name) return;
        const { isAvailable } = await verifyAvailabilityOrganizationAPI(value);

        (isAvailable || value.name === currentOrganization?.name) && setNameAfterValidation(value.name);

        if(!isAvailable) nameFormRef.current?.setFieldError('name', t(prefix + 'nameIsNotAvaliable'));
    };
    
    const discriptionForm = {
        description: currentOrganization?.description || '',
    }
    const discriptionSchema = Yup.object().shape({
        description: Yup.string(),
    });
    const discriptionFormRef = useRef<FormikProps<IOrganisationDescription>>(null);
    
    const organisationForm = {
        img: currentOrganization?.img || '',
        country: currentOrganization?.country || 1,
        verified: currentOrganization?.verified ||true,
        type: currentOrganization?.type || 1,
    };
    const organisationSchema = Yup.object().shape({
        img: Yup.string(),
        description: Yup.string(),
    });
    const imageFormRef = useRef<FormikProps<IOrganisationSettings>>(null);

    const getAdmins = async () => {
       const admins = currentOrganization && await fetchOrganizationAllAdminsAPI(currentOrganization?.id);
       setAdmins(admins || [] as IFetchOrganizationAllAdminsResponse[]);
    }

    const handleSelect = useCallback(
        (e: Option[]) => {
            const userIds = e.map(({value}) => value);
            setDeletedAdmins(deletedAdmins.filter(i => !userIds.includes(i)));
            setMultiSelections(
                //@ts-ignore

               [...multiSelections, ...e
                    .filter(({value}) => !admins.find(({ id }) => id === value))
                    .map((option) => userData.find((user) => user.id === option.value))
                    .map(i => ({...i, role: 1}))
               ]
            );
            selectRef.current.clearValue();
        },
        [deletedAdmins, multiSelections, admins, userData]
    );
    
    const handleRemove = useCallback((userId: number) => {
        setDeletedAdmins([...deletedAdmins, userId])
        setMultiSelections(multiSelections.filter(({id}) => id !== userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[multiSelections])

    const selectRef = useRef<any>(null);

    const promiseOptions = debounce((inputValue, cb) => {
        fetchUsersData(inputValue)
            .then((response) => {
                setAmountOfOptions(response.length);
                dispatch(addUsersFromSearch(response));
                cb(response.map((user: User) => imgOptionUserLabel(user)));
            })
            .catch((e) => {
                console.log(e);
            });
    }, 500);

    useEffect(() => {
        getAdmins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganization]);

    const handleOrganisationForm = async () => {
        if(!currentOrganization) return;
        const errors = (await nameFormRef.current?.validateForm(nameFormRef.current.values)) as FormikErrors<IOrganisationName>;

        if(Object.keys(errors).length) {
            nameFormRef.current?.setTouched({name: true});
            nameFormRef.current?.setErrors(errors);
            return;
        }

        if(!nameAfterValidation || nameFormRef.current?.values.name !== currentOrganization?.name) {
            nameFormRef.current?.setFieldError('name', t('communityCreate-nameIsNotChecked'));
            return;
        }

        const organizationId = currentOrganization.id;
        const removedAdmins = admins.filter(({id}) => deletedAdmins.includes(id)).map(({ id }) => id);

        Promise.all([
            updateOrganizationAPI({
                id: currentOrganization.id,
                ...(nameFormRef.current?.values || {}),
                ...(discriptionFormRef.current?.values || {}),
                ...(imageFormRef.current?.values || {}),
            } as IOrganization),
            removedAdmins.length && removeAdminsFromOrganisationAPI({
                organizationId,
                userIds: removedAdmins
            }),
            multiSelections.length && addAdminsToOrganisationAPI({
                organizationId,
                userIds: multiSelections.map(({ id }) => id)
            })
        ]);

        dispatch(updateOrganizationsList({
            id: currentOrganization.id,
            ...(nameFormRef.current?.values || {}),
            ...(discriptionFormRef.current?.values || {}),
            ...(imageFormRef.current?.values || {}),
        } as IOrganization));

        history.push(`/community/${currentOrganization?.id}`);
    }

    const handleCancel = () => history.push(`/community/${currentOrganization?.id}`);

return <div className="organisation-settings">
            <div className="organisation-settings__title">{t(prefix + 'title')}</div> 
            <div className="organisation-settings__button-wrapper">
                <CustomButton btnText={t(prefix + 'cancel')} variant="outline-dark" onClick={handleCancel}/>
            
                <CustomButton className="organisation-settings__submit-form-button" btnText={t(prefix + 'save')} onClick={handleOrganisationForm}>
                    <CheckIcon w="50px" h="25px" f="#000"/>
                </CustomButton>
            </div>
            <Accordion>
                <Card>
                    <Card.Header className="p-0 bg-white border-0 relative">
                        <CustomAccordionHeader eventKey="Name">
                            {t(prefix + 'general')}
                        </CustomAccordionHeader>
                    </Card.Header>
                    <Accordion.Collapse eventKey="Name">
                        <Card.Body className="p-2">
                            <Formik validationSchema={nameSchema}  
                                    initialValues={nameForm}
                                    onSubmit={handleNameForm}
                                    innerRef={nameFormRef}
                                    enableReinitialize={true}
                            >
                                <Form className="organisation-settings__single-item" onChange={() => setNameAfterValidation(null)}>
                                    <div className={classnames('organisation-settings__single-item_text-field', {
                                        valid: !!nameAfterValidation || nameFormRef.current?.values.name === currentOrganization?.name
                                    })}>
                                        <FormTextField
                                            name="name"
                                            type="text"
                                            className=" "
                                            label={t(prefix + 'commName')}
                                        />
                                        {/* <ErrorMessage component="div" name="name" className="field-error text-danger fs-7" /> */}
                                    </div>
                                    <div className="organisation-settings__single-item_submit">
                                        <CustomButton btnText={t(prefix + 'verfiy')} type="submit" variant="outline-dark">
                                            <CheckIcon w="50px" h="25px" f="#000"/>
                                        </CustomButton>
                                    </div>
                                </Form>
                            </Formik>
                            <Formik 
                                validationSchema={discriptionSchema}  
                                initialValues={discriptionForm}
                                onSubmit={() => {}}
                                innerRef={discriptionFormRef}
                                enableReinitialize={true}
                            >
                                <Form>
                                    <div>
                                        <FormTextField
                                            name="description"
                                            type="text"
                                            label={t(prefix + 'commDesc')}
                                        />
                                    </div>
                                </Form>
                            </Formik>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion>
                <Card>
                    <Card.Header className="p-0 bg-white border-0 relative">
                        <CustomAccordionHeader eventKey="Image">
                            {t(prefix + 'images')}
                        </CustomAccordionHeader>
                    </Card.Header>
                    <Accordion.Collapse eventKey="Image">
                        <Card.Body className="p-2">
                        <Formik 
                            validationSchema={organisationSchema}
                            initialValues={organisationForm}
                            innerRef={imageFormRef}
                            onSubmit={handleOrganisationForm}
                            enableReinitialize={true}
                        >
                            <Form>
                                <div>
                                    <div>
                                        <Field name="img">
                                            {({ field, form }: FieldProps) => {
                                                return (
                                                    <div className="organisation-settings__community-image-block">
                                                        <InfoImageUpload
                                                            title={t(prefix + 'imageLabel')}
                                                            setFieldValue={form.setFieldValue}
                                                            imgUrl={field.value || require('../../assets/images/noImage.png')}
                                                            savePath="organization"
                                                            settingData={{ name: field.name }}
                                                        >
                                                            <div className="organisation-settings__image-button-section">
                                                                <div className="organisation-settings__image-text-wrapper" role="textbox" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}>
                                                                    {t(prefix + 'imageDesc')}
                                                                </div>
                                                                <div className="organisation-settings__image-button-wrapper">
                                                                    <CustomButton btnText={t(prefix + 'imageButton')} type="button" variant="outline-dark">
                                                                        <PictureIcon w="30"/>
                                                                    </CustomButton>
                                                                </div>
                                                        </div>
                                                        </InfoImageUpload>
                                                        <div className="organisation-settings__image-text-wrapper_outside" role="textbox" onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}>
                                                            {t(prefix + 'imageDesc')}
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
        <Accordion>
            <Card>
                <Card.Header className="p-0 bg-white border-0 relative">
                    <CustomAccordionHeader eventKey="Administration">
                        {t(prefix + 'admins')}
                    </CustomAccordionHeader>
                </Card.Header>
                <Accordion.Collapse eventKey="Administration">
                    <Card.Body className="p-2">
                        <div className="organisation-settings__users-block">
                            <div className="organisation-settings__row">
                                {t(prefix + 'creator')}
                            </div>
                            <div>
                                {admins.filter(({ role }) => role === 0).map(({userName, firstName, lastName}) =>
                                 <div className="organisation-settings__owner-card organisation-settings__row">{
                                    (firstName && lastName) ? `${firstName} ${lastName}` : `@${userName}`
                                }</div>)}
                            </div>
                        </div>
                        <div className="organisation-settings__users-block">
                            <div className="organisation-settings__row">
                                {t(prefix + 'otherAdmins')}
                            </div>
                            <div>
                                <div>
                                    {[...admins, ...multiSelections]
                                    // Show only admins
                                    .filter(({ role }) => role === 1)
                                    // Do not include deleted admins
                                    .filter(({ id }) => !deletedAdmins.includes(id))
                                    .map(({id, userName, firstName, lastName}) => 
                                        <div className="organisation-settings__owner-card organisation-settings__row">
                                            <div>
                                                {(firstName && lastName) ? `${firstName} ${lastName}` : `@${userName}`}
                                            </div>
                                            <ButtonIcon className="raqtButtonLeftIcon" onClick={() => handleRemove(id)}>
                                                <CloseCircle />
                                            </ButtonIcon>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <AsyncSelect
                                styles={customStyles}
                                isMulti
                                name="members"
                                ref={selectRef}
                                defaultOptions={true}
                                className={classnames('organisation-settings__row', 'basic-multi-select', 'holder-select', {
                                    'raqt-select__no-options':  !amountOfOptions
                                })}
                                classNamePrefix="select"
                                loadOptions={promiseOptions}
                                placeholder={t(prefix + 'select')}
                                //@ts-ignore
                                formatOptionLabel={(data: any) => (
                                    <div className="d-flex align-items-center">
                                        <RoundedAvatar avatar={data.avatar} alt="User avatar" name="user" />
                                        {data.label}
                                    </div>
                                )}
                            />
                            <div className="organisation-settings__add-admin-button organisation-settings__row"
                                 role="button"
                                 onClick={() => handleSelect(selectRef.current.getValue())}>
                                    {t(prefix + 'addUser')}
                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
        <div className="organisation-settings__button-wrapper">
            <CustomButton btnText={t(prefix + 'cancel')} variant="outline-dark" onClick={handleCancel}/>
        
            <CustomButton className="organisation-settings__submit-form-button" btnText={t(prefix + 'save')} onClick={handleOrganisationForm}>
                <CheckIcon w="50px" h="25px" f="#000"/>
            </CustomButton>
        </div>
</div>
};