

export const tShirtSize = [
    {label: 'S', value: 's'},
    {label: 'M', value: 'm'},
    {label: 'L', value: 'l'},
    {label: 'XL', value: 'xl'},
    {label: 'XXL', value: 'xxl'},
];

export interface ISellPageForm {
    tShirtSize: string;
    email: string;
    firstName: string;
    lastName: string;
    personalNumber: string;
    phone: string;
    'class': ITournamentClasses[];
};

export interface ITournamentClasses {
    name: string;
    price: number;
    date: string;
    starts: string;
    team: boolean;
};

export const tournamentClasses: ITournamentClasses[] = [
    {name: 'Individuales', price: 25, date: 'Viernes 17-22.30', starts: '4 jug. disponibles (16)', team: false},
    {name: 'Dobles Feminino', price: 25, date: 'Sábado 9.30-14.45', starts: '4 jug. disponibles (16)', team: true},
    {name: 'Dobles Masculino', price: 25, date: 'Sábado 16.00-21.30', starts: '4 jug. disponibles (16)', team: true},
    {name: 'Dobles Mixto', price: 25, date: 'Domingo 09.30-15.30', starts: '4 jug. disponibles (16)', team: true},
];

export const ComboPrices = [
    0,
    25,
    40,
    50,
];

export const ComboPricesForEach = [
    25,
    15,
    10,
];

export const maxTeamsSingle = 16;
export const maxTeamsTeam = 32;