import React from 'react';
import { SvgIcon } from '../../components';

interface BackIconProps {
    w?: string;
    h?: string;
    f?: string;
}

export const BackIcon = (props: BackIconProps) => {
    const { w, h, f } = props;
    return (
        <SvgIcon width={w || '50'} height={h || '50'} viewBox="0 0 50 50">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M31.2084 44.0833L12.125 25L31.2084 5.875L25.3334 0L0.333374 25L25.3334 50L31.2084 44.0833Z"
                fill={f || 'black'}
            />
        </SvgIcon>
    );
};
