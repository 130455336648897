import { BracketOptionsEnum } from './../../../../types/enums';


export const getWinnerBracket = (mtchs: any[]) => {
    return mtchs.filter(m => {
      return m.bracket === BracketOptionsEnum.Winner;
    });
  };

export const getLooserBracket = (mtchs: any[]) => {
    return mtchs.filter(m => {
      return m.bracket === BracketOptionsEnum.Consolation;
    });
  };