import { Form, Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';

import { ButtonFormikTab, Checkbox, ConfirmModal, CustomButton, FormTextField } from '../../components';
import { ClassSwitchForm } from './components/ClassSwitchForm';

import { ComboPrices, maxTeamsSingle, tournamentClasses, tShirtSize } from './SellPage.constants';
import { formStartsString, getTeamCountOfClasses, submitForm } from './SellPage.helpers';

import './SellPage.scss';
import { openInNewTab } from '../../utils/common.helpers';

export const SellPage = () => {
    const { t } = useTranslation();
    const prefixValidation = 'validation-';

    const SellSchema = Yup.object().shape({
        email: Yup.string()
            .required(t(prefixValidation + 'required')),
        firstName: Yup.string()
            .required(t(prefixValidation + 'required')),
        lastName: Yup.string()
            .required(t(prefixValidation + 'required')),
        personalNumber: Yup.string()
            .required(t(prefixValidation + 'required')),
        phone: Yup.string()
            .required(t(prefixValidation + 'required')),
    });

    const infoRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const [response, setResponse] = useState<any[] | null>(null);
    const [counter, setCounter] = useState<any[] | null>(null);

    const memoForTournamentClasses = useMemo(() => {
        return tournamentClasses.map((i) => {
            const countClass = counter?.find(({name}) => name === i.name);

            return ({
               ...i,
               starts: formStartsString(
                    maxTeamsSingle,
                    (countClass?.count || 0),
                    i.team 
                ),
                count: countClass?.count,
            })
        })
    },[counter]);

    const getCounter = async () => {
        const data = await getTeamCountOfClasses();
        setCounter(data);
    };

    useEffect(() => {
        getCounter();
    },[])

    const initialValues = {
        tShirtSize: tShirtSize[0].value,
        email: '',
        firstName: '',
        lastName: '',
        personalNumber: '',
        phone: '',
        policy: false,
        class: memoForTournamentClasses.map(({
            name,
            price,
            date,
            starts,
        }) => ({
            toggle: false,
            class: name,
            price,
            partnerFirstName: '',
            partnerLastName: '',
            description: `${date} ${starts}`
        })),
    };

    return <div>
        <Helmet>
            <meta property="og:title" content="Cadíz Pickleball Open" />
            <meta property="og:description" content="Segunda prueba puntuable del I Circuito Internacional de Pickleball de Andalucia Zcebra, 
            que tendrá lugar del 25 al 27 de noviembre, en las instalaciones de Pickleball Cádiz" /> 
            <meta property="og:url" content="http://raqt-admin-assets.s3-website.eu-central-1.amazonaws.com/cadiz-pickleball-open" />
            <meta property="og:image" content="IMAGE URL/cadiz-share.v01.jpg" />
            <meta property="og:image:secure_url" content="IMAGE URL/cadiz-share.v01.jpg" /> 
            <meta property="og:image:type" content="image/jpg" /> 
            <meta property="og:image:width" content="1200" /> 
            <meta property="og:image:height" content="630" />
        </Helmet>
    {((counter && (<div className="sell-page">
                <div className="sell-page__tournament-info">
                    <div className="sell-page__tournament-info-text">
                        {(!response && (<>
                            <h1>Bienvenido a CÁDIZ PICKLEBALL OPEN</h1>
                            <h5>25-27 Noviembre - Andaluz Circuito</h5>
                            <div className="sell-page__tournament-info-buttons">
                                <CustomButton variant="outline-dark" btnText='Inscribirse aquí' onClick={() => formRef.current?.scrollIntoView()}/>
                                <CustomButton variant="outline-dark" btnText='Informacíon' onClick={() => infoRef.current?.scrollIntoView()}/>
                            </div>
                        </>)) || 
                            (<>
                                <h1>Gracias!</h1>
                                <h5>{response?.[0].firstName} {response?.[0].lastName}</h5>
                                <div className="sell-page__receipt-row">
                                    <span>Referencias de pago:</span><h5 className="mb-0">36-{(response as any[])[0]?.submitId}</h5>
                                </div>
                                <div className="sell-page__receipt-row">
                                    <span>Por favor pague:</span>
                                    <h5 className="mb-0">{(response as any[]).reduce((acc, i) => {
                                        return acc + i.price;
                                    },0)}€</h5>
                                </div>
                                <div>
                                    <span>Transferencia bancaria a: ING </span>
                                </div>
                                <div>
                                    <span>ES75 1465 0100 95 1747303425</span>
                                </div>
                                <div>
                                    <span>Nota:</span><span className="sell-page__note-danger">No olvides añadir la referencia de pago cuando pagues la cuota de inscripción.</span>
                                </div>
                            </>)}
                    </div>
                    <div className="sell-page__tournament-info-image">
                        <img src={require('../../assets/images/CADIZ_fix.png')} alt="CÁDIZ PICKLEBALL OPEN"/>
                    </div>
                </div>
                <div className="sell-page__sell-info" ref={infoRef}>
                {(!response && (<><div className="sell-page__sell-info-title" >
                        <h3>Informacíon</h3>
                    </div>
                    <div className="sell-page__sell-info-paragraph">
                        <h3>Bienvenido</h3>
                        <div className="sell-page__sell-info-block">
                            <p>Segunda prueba puntuable del I Circuito Internacional de Pickleball de Andalucia Zcebra,</p>
                            <p>que tendrá lugar del 25 al 27 de noviembre, en las instalaciones de Pickleball Cádiz</p>
                        </div>
                    </div>
                    <div className="sell-page__sell-info-paragraph">
                        <h3>Información del contacto</h3>
                        <div className="sell-page__sell-info-block">
                            <p>Organizadoras:</p>
                            <p>Elena Arbolí y Vanesa Blanco</p>
                            <p>Teléfono y WhatsApp de contacto 603.018.600</p>
                            <p>Correo electrónico: <a target="__blank" href="mailto:pickleballcadiz@gmail.com">pickleballcadiz@gmail.com</a></p>
                        </div>
                    </div>

                    <div className="sell-page__sell-info-paragraph">
                        <h3>Ubicación</h3>
                        <div className="sell-page__sell-info-block">
                            <p><a target="__blank" href="https://maps.app.goo.gl/Bk9Y7eBMqjxQgVGBA">Calle Ciudad de San Roque, 4. Cádiz.</a></p>
                        </div>
                    </div>
                    <div className="sell-page__sell-info-paragraph">
                        <h3>Precios y pago</h3>
                        <div>
                            <div className="sell-page__sell-info-block">
                                <p>Precio por persona:</p>
                                <p>1 modalidad: 25€</p>
                                <p>2 modalidades: 40€</p>
                                <p>3 modalidades: 50€</p>
                            </div>
                           <div className="sell-page__sell-info-block">
                                <p>Después del registro, verificamos si el pago se ha completado y agregaré a los jugadores al torneo en nuestro sistema. </p>
                           </div>
                           <div className="sell-page__sell-info-block">
                                <p>Transferencia bancaria a: ING </p>
                                <p>ES75 1465 0100 95 1747303425</p>
                           </div>
                           <div className="sell-page__sell-info-block">
                                <p>Titular de la cuenta bancaria: Elena Arbolí Delgado</p>
                           </div>
                        </div>
                    </div> 
                    <div className="sell-page__sell-info-paragraph">
                        <h3>Formato de juego</h3>
                        <div>
                            <div className="sell-page__sell-info-block">
                                <p>Formato Open de Cádiz:</p>
                                <p>• 4 pistas</p>
                                <p>• Sólo una categoría. 5.0</p>
                                <p>• 4 Modalidades:</p>
                                <p>Individuales (chicos y chicas juntos)</p>
                                <p>Dobles Masculino</p>
                                <p>Dobles Femenino</p>
                                <p>Mixtos</p> 
                            </div>
                            <div className="sell-page__sell-info-block">
                                <h5>N° equipos:</h5>
                            </div>
                            <div className="sell-page__sell-info-block">
                                <p>Individuales: 16</p>
                                <p>4 grupos de 4 jugadores</p>
                                <p>Los dos primeros pasan a cuartos de final. Los otros 2 pasan a cuartos de fase de consolación.</p>
                            </div>
                            <div className="sell-page__sell-info-block">
                                <p>Dobles Masculino: 16</p>
                                <p>4 grupos de 4 jugadores</p>
                                <p>Los dos primeros pasan a cuartos de final. Los otros 2 pasan a cuartos de fase de consolación.</p>
                            </div>
                            <div className="sell-page__sell-info-block">
                                <p>Dobles Femenino: 16</p>
                                <p>4 grupos de 4 jugadores</p>
                                <p>Los dos primeros pasan a cuartos de final. Los otros 2 pasan a cuartos de fase de consolación.</p>
                            </div>
                            <div className="sell-page__sell-info-block">
                                <p>Mixto: 16</p>
                                <p>4 grupos de 4 jugadores</p>
                                <p>Los dos primeros pasan a cuartos de final. Los otros 2 pasan a cuartos de fase de consolación. </p>
                            </div>
                            <div className="sell-page__sell-info-block">
                                <p>Todos los partidos son a 11 puntos con diferencia de 2 puntos. 3° y 4° puesto y la Final al mejor de 3 sets a 11 puntos.</p>
                            </div>
                        </div>
                    </div></>)) || (
                        <div className="sell-page__sell-info-paragraph">
                            <h3>Información</h3>
                            <div className="sell-page__sell-info-block">
                                <p>Una vez que se hayan confirmado los pagos, lo agregaremos a cada categoría y </p>
                                <p>podrá verlo a usted y a otros jugadores en la aplicación Raqt.</p>
                            </div>
                            <div className="sell-page__sell-info-paragraph sell-page__raqt-apps">
                                    <div className="sell-page__raqt-apps-single" onClick={() => openInNewTab('https://play.google.com/store/apps/details?id=com.boilerplatee')} role="button">
                                        <div>
                                            <img src={
                                                require('../../assets/images/googleplay-1.png')
                                            } alt="Google play" />
                                        </div>
                                    </div>
                                    <div className="sell-page__raqt-apps-single" onClick={() => openInNewTab('https://apps.apple.com/us/app/raqt-chat/id1617653147')} role="button">
                                        <div>
                                            <img src={
                                                require('../../assets/images/appstore-1.png')
                                            } alt="App Store" />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    )}
                </div>
                {!response && (<><Formik initialValues={initialValues} 
                validationSchema={SellSchema}
                validateOnChange={false}
                validateOnBlur={true}
                validateOnMount={false}
                onSubmit={async (values) => {
                    const response = await submitForm(values);
                    setResponse(response);
                }}>
                    {(formikArg) => {
                        const selectedClasses = formikArg.values.class.filter(({ toggle }) => toggle ).length;
                        return (<Form autoComplete="off" ref={formRef}>  
                                    <div className="sell-page__sell-info-title" >
                                        <h3>Inscribirse aquí</h3>
                                        <p>Si la clase está llena, comuníquese con los organizadares del torneo para que lo incluyan en una lista de reserva.</p>
                                    </div> 
                                    <div className="sell-page__row">
                                        <div className="px-md-3 px-sm-3 sell-page__field">
                                            <FormTextField
                                                name="firstName"
                                                placeholder={'Nombre'}
                                                label={'Nombre'}
                                            />
                                        </div>
                                        <div className="px-md-3 px-sm-3 sell-page__field">
                                            <FormTextField
                                                name="lastName"
                                                placeholder={'Appelido'}
                                                label={'Appelido'}
                                            />
                                        </div>
                                        <div className="px-md-3 px-sm-3 sell-page__field">
                                            <FormTextField
                                                name="personalNumber"
                                                placeholder={'DNI'}
                                                label={'DNI'}
                                            />
                                        </div>
                                    </div>
                                    <div className="sell-page__row">
                                        <div className="px-lg-3 px-md-3 px-sm-3 sell-page__field">
                                            <FormTextField
                                                name="email"
                                                placeholder={'Email'}
                                                label={'Email'}
                                            />
                                        </div>
                                        <div className="px-lg-3 px-md-3 px-sm-3 sell-page__field">
                                            <FormTextField
                                                name="phone"
                                                placeholder={'Movil'}
                                                label={'Movil'}
                                            />
                                        </div>
                                        <div className="px-lg-3 px-md-3 px-sm-3 d-flex align-items-center sell-page__t-shirt">
                                            <div className='sell-page__t-shirt-sub'>
                                                <div>
                                                    <label htmlFor="tShirtSize" className="font-weight-normal">
                                                        {'Tu talla de camiseta'}
                                                    </label>
                                                </div>
                                                <ButtonFormikTab
                                                    currValue={formikArg.values.tShirtSize}
                                                    items={tShirtSize}
                                                    name="tShirtSize"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    { memoForTournamentClasses.map((i, index) => <ClassSwitchForm key={`class-${index}`} formikArg={formikArg} index={index} {...i}/>)}
                                    </div>
                                    <div className="sell-page__form-total">
                                        <h5>TOTAL:</h5> <h5>{ComboPrices[selectedClasses]}€</h5>
                                    </div>
                                    <div className="sell-page__form-submit">
                                        <div className="sell-page__policy-button">
                                            <Checkbox name='policy'/>
                                            <a target="__blank" href="https://raqt.com/policy/privacy">Privacy policy</a>
                                        </div>
                                        <ConfirmModal disabled={!selectedClasses || !formikArg.isValid || !formikArg.values.policy} onConfirm={() => {
                                            formikArg.submitForm();
                                        }} text={'Are you sure you have selected to correct classes?'}>
                                            <CustomButton variant="outline-dark" btnText='Enviar' disabled={!selectedClasses || !formikArg.isValid || !formikArg.values.policy}/>
                                        </ConfirmModal>
                                    </div>
                                    </Form>)
                                }
                            }
                </Formik></>)}
            </div>)) || 
            <div>
            </div>)}
        </div>;
};