import { Redirect, useParams } from 'react-router-dom';
import { selectTags } from '../../features/tag/tagSelectors';
import { useAppSelector } from '../../hooks/redux';

export const CreativeTourDetailRedirect = () => {
  const { id, communityId } = useParams<{ communityId: string, id: string }>();
  const tagsConfig = useAppSelector(selectTags);
  
  return <Redirect 
            to={{
                pathname: `/community/${communityId}/tournament/${id}/tags/${tagsConfig[0].id}`,
            }}
        />;
};