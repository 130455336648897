//import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//import { CopyToClipboard } from 'react-copy-to-clipboard';

//import { fetchPublicTournamentAPI, fetchStreamsAPI, IStream } from "../../apis/tournament";
//import { ChainIcon } from "../../assets/icons/ChainIcon";

//import { RaqtPopup } from "../../components/popup/RaqtPopup";
import { Logo } from "../../assets/icons/Logo";
//import { OpenIcon } from "../../assets/icons/OpenIcon";
import { CustomButton } from "../../components";
import { openInNewTab } from "../../utils/common.helpers";
//import { getReadableDate } from "../../utils/getStringDate";

import "./LandingPage.scss";

export const LandingPage = () => {
    const prefix = 'landingPage-';
    const history = useHistory();
    const { t } = useTranslation();
    //const [tournaments, setTournaments] = useState<any>([]);
    //const [streams, setStreams] = useState<IStream[]>([]);

    /*
    const fetchData = async () => {
        try {
            const pt = fetchPublicTournamentAPI();
            const ps = fetchStreamsAPI();

            const [ t, s ] = await Promise.all([pt, ps]);

            setTournaments(t);
            setStreams(s);
        } catch (e) {} 
    };
    

    useEffect(() => {
        fetchData();
    }, [])

    */
    
    return (<div className="landing-page">
        <div className="landing-page__container">
            <div className="landing-page__row landing-page__logo">
                <Logo h=" " w=" " f=" "/>
            </div>
            {/* <div className="landing-page__row landing-page__subtitle">
                {t(prefix + 'subtitle')}
            </div> */}
            <div className="landing-page__row landing-page__card-wrapper">
                <div className="landing-page__card">
                    <div className="landing-page__card-first">
                        <h5><strong>{t(prefix + 'getIn')}</strong></h5>
                        <p>{t(prefix + 'getInDesc')}</p>
                    </div>
                    <div className="landing-page__card-second">
                        <div onClick={() => openInNewTab('https://apps.apple.com/us/app/raqt-chat/id1617653147')} role="button">
                            <img src={
                                require('../../assets/images/appstore.png')
                            } alt="App Store" />
                        </div>
                        <div onClick={() => openInNewTab('https://play.google.com/store/apps/details?id=com.boilerplatee')} role="button">
                            <img src={
                                require('../../assets/images/googleplay.png')
                            } alt="Google play" />
                        </div>
                    </div>
                </div>
                <div className="landing-page__card">
                    <div className="landing-page__card-first">
                        <h5><strong>{t(prefix + 'communities')}</strong></h5>
                        <p>{t(prefix + 'commDesc')}</p>
                    </div>
                    <div className="landing-page__card-second_to-bottom">
                        <CustomButton onClick={() => history.push('/sign-in')} btnText={t(prefix + 'signIn')}/>
                    </div>
                </div>           
            </div>
            {/* <div className="landing-page__description landing-page__row">
                <p className="landing-page__entrepreneur">{t(prefix + 'entrepreneur')}</p>
            </div> */}
            {/* <div className="landing-page__description landing-page__row landing-page__w-100">
                <p className="landing-page__section-title">{t(prefix + 'raqtTV')}</p>
                <div className="landing-page__raqt-tv-holder">
                    <div className="landing-page__raqt-tv-wrapper">
                        {streams.map(i => <div key={`tournament-key-${i.id}`} className="landing-page__tournaments-card" role="button" onClick={() => openInNewTab(i.url)}>
                                                        <div>
                                                            <img className="landing-page__raqt-tv-img" src={i.image || require('../../assets/images/noImage.png')} alt="" />
                                                        </div>
                                                        <div><h5><strong>{i.name}</strong></h5></div>
                                                        <div className="landing-page__date">{getReadableDate(i.startDate)}</div>
                                                    </div>
                            )}
                    </div>
                </div>
            </div>
            <div className="landing-page__description landing-page__row landing-page__w-100">
                <p className="landing-page__section-title">{t(prefix + 'tournaments')}</p>
                <div className="landing-page__tournaments-holder">
                        {tournaments.map((i: any) => <div key={`tournament-key-${i.id}`} className="landing-page__tournaments-card" onClick={() => history.push(`/tournament/${i.id}`)}>
                                                <div>
                                                    <img className="landing-page__tournament-img" src={i.img || require('../../assets/images/noImage.png')} alt="" />
                                                </div>
                                                <div><h5><strong>{i.name}</strong></h5></div>
                                                <div className="landing-page__date">{getReadableDate(i.startDate)}</div>
                                                <div className="landing-page__tournaments-card-buttons-wrapper">
                                                    <div className="landing-page__tournaments-card-button-holder hide-svg">
                                                        <OpenIcon />
                                                    </div>
                                                    <div className="landing-page__tournaments-card-button-holder" onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }} >
                                                        <RaqtPopup popupStyle="light" content={t('popup-copied')} position="top center">
                                                                
                                                                <CopyToClipboard text={`${location.origin}/tournament/${i.id}`}>
                                                                    <a><ChainIcon /></a>
                                                                </CopyToClipboard>
                                                        </RaqtPopup>
                                                    </div>
                                                    <div className="landing-page__tournaments-card-button-holder" onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}>
                                                        <CustomButton btnText={t(prefix + 'more')} onClick={() => history.push(`/tournament/${i.id}`)} />
                                                    </div>
                                                </div>
                                            </div>
                            
                            
                        )}
                </div>
            </div> */}
        </div>
    </div>)
}