import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ScaleText from "react-scale-text";

import { selectTournamentStats } from '../../../../../features/tournament/tournamentSelectors';
import { useAppSelector } from '../../../../../hooks/redux';
import { StatsTabsLinksConfig } from '../../TournamentDashboard.constant';

import './TournamentStats.scss';

interface TournamentStatsProps {
    onClick: (subtag: string) => void
}

export const TournamentStats: FC<TournamentStatsProps> = ({ onClick }) => {
    const prefix = 'tournamentDashboard-';

    const { t } = useTranslation();
    const stats = useAppSelector(selectTournamentStats);

    return <>
        <div className="tournament-stats__section-title">{t(prefix + 'stats')}</div>
        {stats && <div className="tournament-stats__stats">
            <div className="tournament-stats__stats-item"  role="button" onClick={() => onClick(StatsTabsLinksConfig.CLASSES)}>
                <div className="tournament-stats__stats-quantity">
                    <ScaleText maxFontSize={128}>
                        {stats?.totalClasses}
                    </ScaleText>
                </div>
                <div className="tournament-stats__stats-value-name">
                    {t(prefix + 'statsClasses')}
                </div>
            </div>
            <div className="tournament-stats__stats-item" role="button" onClick={() => onClick(StatsTabsLinksConfig.PLAYERS)}>
                <div className="tournament-stats__stats-quantity">
                    <ScaleText maxFontSize={128}>
                        {stats?.totalPlayers}
                    </ScaleText>
                </div>
                <div className="tournament-stats__stats-value-name">
                    {t(prefix + 'statsPlayers')}
                </div>
            </div>
            <div className="tournament-stats__stats-item" role="button" onClick={() => onClick(StatsTabsLinksConfig.TIKETS)}>
                <div className="tournament-stats__stats-quantity">
                    <ScaleText maxFontSize={128}>
                        {stats?.totalTickets}
                    </ScaleText>
                </div>
                <div className="tournament-stats__stats-value-name">
                    {t(prefix + 'statsTickets') // Tickets & Payments
                    }
                </div>
            </div>
            <div className="tournament-stats__stats-item" role="button" onClick={() => onClick(StatsTabsLinksConfig.MATCHES)}>
                <div className="tournament-stats__stats-quantity">
                    <ScaleText maxFontSize={128}>
                        {stats?.totalMatches}
                    </ScaleText>
                </div>
                <div className="tournament-stats__stats-value-name">
                    {t(prefix + 'statsMatches')}
                </div>
            </div>
        </div>}
    </> 
};