import { useTranslation } from 'react-i18next';
import { FormTextField } from '../../../../../components';
import { OptInCheckBoxArray } from './OptInCheckBoxArray';
import { Court, Class } from '../../../../../types/tournament';
import { IScheduleDayConstraints } from '../../../../../types/schedule';
import { getDateString } from '../schedule.helpers';

interface ScheduleDayConstraintProps {
    day: IScheduleDayConstraints;
    courts: Court[];
    classes: Class[];
}

export const ScheduleDayConstraints = ({ day, courts, classes }: ScheduleDayConstraintProps) => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    return (
        <div>
            <div className="row p-3">
                <h2>
                    {t(prefix + 'scheduleDay')} {day.dayKey + 1} ({getDateString(day.startTime)})
                </h2>
            </div>
            <div className="row p-3">
                <div className="col-sm">
                    <FormTextField
                        type="time"
                        name={`startOfDay-${day.dayKey}`}
                        label={t(prefix + 'startOfDayLabel')}
                    />
                </div>
                <div className="col-sm">
                    <FormTextField
                        type="time"
                        name={`endOfDay-${day.dayKey}`}
                        label={t(prefix + 'endOfDayLabel')}
                    />
                </div>
                <div className="col-lg">
                    <FormTextField
                        type="number"
                        name={`defaultMatchLength-${day.dayKey}`}
                        label={t(prefix + 'matchLengthLabel')}
                    />
                </div>
                <div className="col-lg">
                    <FormTextField
                        type="number"
                        name={`defaultPauseLength-${day.dayKey}`}
                        label={t(prefix + 'pauseLengthLabel')}
                    />
                </div>
            </div>
            <div className="row p-3">
                <OptInCheckBoxArray
                    name="class"
                    dayKey={day.dayKey}
                    data={classes}
                    courts={courts}
                />
                <OptInCheckBoxArray name="court" dayKey={day.dayKey} data={courts} />
            </div>
        </div>
    );
};
