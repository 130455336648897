import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

interface ButtonTabProps {
    name: 'organization';
    value: Boolean;
    onClick: () => void;
}

export const ButtonTab: FC<ButtonTabProps> = ({ name, value, onClick }) => {
    const { t } = useTranslation();

    const prefix = 'organization-';
    const btnContent =
        name === 'organization' ? [t(prefix + 'profit'), t(prefix + 'commercial')] : [];

    return (
        <div className="border py-1 d-flex flex-row tagContainer">
            {btnContent.length
                ? btnContent.map((title, index) => (
                      <div
                          key={index}
                          className={`d-flex flex-grow-1 justify-content-center align-items-center py-1 mx-1 btnTabItem ${
                              Boolean(index - 1) === value ? 'activeBtn' : ''
                          }`}
                          onClick={onClick}
                      >
                          <label className="mb-0 w-100 text-center" role="button">
                              {title}
                          </label>
                      </div>
                  ))
                : null}
        </div>
    );
};
