import { FC } from 'react';
import { IMatch } from '../../../tourDetails/TournamentPlay/components/PlayTag/PlayTag';
import { MatchTournamentItem } from '../MatchTournamentItem';
import './MatchTournamentList.scss';

interface MatchTournamentListProps {
    matches: IMatch[];
    onClick: (e:any) => void;
}

export const MatchTournamentList: FC<MatchTournamentListProps> = ({ matches = [], onClick }) => (<div className="match-tournament-list">
   {matches.map((match, index) => <MatchTournamentItem onClick={() => onClick(match)} match={match} key={`match-list-${index}`}/>)}
</div>); 