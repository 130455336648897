import { Redirect, Route } from "react-router-dom";
import { selectUser } from "../../features/currentUser/currentUserSelectors";

import { useAppSelector } from "../../hooks/redux";

export const ProtectedPage = ({ component, ...rest }: any) => {
    const {accessToken, refreshToken} = useAppSelector(selectUser);
    switch(true) {

      case ((accessToken && refreshToken && !rest.unauth) || !rest.protected):
        return <Route component={component} {...rest} /> 
      
      case (accessToken && refreshToken && rest.unauth): 
        return <Route
                  {...rest}
                  render={({ location }) => (
                      <Redirect
                        to={{
                          pathname: "/communities",
                          state: { from: location }
                        }}
                      />
                    )
                  }
                />

      default: 
        return <Route
        {...rest}
        render={({ location }) => (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />;
  }
};
