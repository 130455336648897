import { useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';

import { TournamentStats } from "../TournamentStats";

import { SubTabLinksList, SubTabPageToEnumMap, StatsTabsConfig } from "../../TournamentDashboard.constant";

import './TournamentDashboardTag.scss';

export const TournamentDashboardTag = () => {
    const { id, communityId, subtag, tag } = useParams<{ id: string, communityId: string, subtag: string, tag: string }>();
    const history = useHistory();
    const current = SubTabLinksList.find(i => subtag === i) as unknown as string;
    const TabEnum = ((SubTabPageToEnumMap as unknown as any)[current] || StatsTabsConfig.NONE);

    useEffect(() => {
        if(TabEnum === StatsTabsConfig.NONE) history.push(`/community/${communityId}/tournament/${id}/tags/${tag}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communityId, id, tag, subtag]);

    const selectSubTag = (subtag: string) => {
        history.push(`/community/${communityId}/tournament/${id}/stats/${subtag}`)
    };

    return  <div className="tournament-dashboard-tag">
                <TournamentStats onClick={selectSubTag}/>
                {/* {TabEnum === StatsTabsConfig.CLASSES &&<TournamentClassesStats />}
                {TabEnum === StatsTabsConfig.PLAYERS &&<TournamentPlayersStats />}
                {TabEnum === StatsTabsConfig.TIKETS &&<TournamentTicketsStats />}
                {TabEnum === StatsTabsConfig.MATCHES &&<TournamentMatchesStats />} */}
            </div>;
};