import { useEffect } from 'react';
import { CustomSpinner } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { ScheduleTag } from './components/ScheduleTag';
import { selectSchedule } from '../../../features/schedule/scheduleSelectors';
import { useParams } from 'react-router-dom';
import { fetchDataForSchedule } from '../../../features/schedule/scheduleActions';

export const Schedule = () => {
    const { id } = useParams<{ id: string }>();

    const { isLoading, error } = useAppSelector(selectSchedule);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchDataForSchedule(+id));
    }, [dispatch, id]);

    return <div>{(isLoading && <CustomSpinner />) || error || <ScheduleTag />}</div>;
};
