import { TournamentState } from '../../types/tournament';
import { ClassOption, IScheduleConstraints, ScheduleMode } from '../../types/schedule';
import { ClassMatchType } from '../../types/enums';

export const generateDefaultSchedulingConstraints = (
    tournamentInfo: TournamentState
): IScheduleConstraints => {
    const startDate = new Date(tournamentInfo.startDate!);
    const endDate = new Date(tournamentInfo.endDate!);
    const numberOfDays =
        Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    const scheduleConstraints = {
        tournamentStartTime: tournamentInfo.startDate!,
        tournamentEndTime: tournamentInfo.endDate!,
        scheduleMode: ScheduleMode.SEQUENTIAL,
        days: new Array(numberOfDays).fill(null).map((_, index) => {
            const defaultStartTime = new Date(startDate);
            const defaultEndTime = new Date(endDate);
            defaultStartTime.setDate(startDate.getDate() + index);
            /* 
            Subtracts the number of days from the tournamnet end date, so that
            the endTime defaults to that time for each day.
            */
            defaultEndTime.setDate(endDate.getDate() - (numberOfDays - 1 - index));

            const defaultMatchLength = 30;
            const defaultPauseLength = 5;

            const matchLengths = Object.keys(ClassMatchType)
                .filter((k) => !Number.isNaN(+k))
                .reduce((accumulator, value) => {
                    const coerced: number = +value;
                    accumulator[coerced as keyof typeof accumulator] = defaultMatchLength;

                    return accumulator;
                }, {} as { [matchType in ClassMatchType]: number });

            const classOptions: ClassOption[] = tournamentInfo.classes.reduce((opts, clazz) => {
                const classOption: ClassOption = {
                    class: clazz,
                    courts: tournamentInfo.courts,
                    matchLengths: matchLengths,
                };
                return [...opts, classOption];
            }, [] as ClassOption[]);

            return {
                dayKey: index,
                startTime: defaultStartTime.toISOString(),
                endTime: defaultEndTime.toISOString(),
                defaultMatchLength: defaultMatchLength,
                defaultPauseLength: defaultPauseLength,
                classes: tournamentInfo.classes,
                classOptions: classOptions,
                courts: tournamentInfo.courts,
            };
        }),
        tournamentClasses: tournamentInfo.classes,
        tournamentCourts: tournamentInfo.courts,
    };

    return scheduleConstraints;
};
