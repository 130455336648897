import { InformationTag } from './components/InformationTag';
import { CustomSpinner } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchTournamentInfo } from '../../../features/tournament/actionCreators';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { selectTournament } from '../../../features/tournament/tournamentSelectors';

export const Information = () => {
    const { id } = useParams<{ id: string }>();
    const { isLoading } = useAppSelector(selectTournament);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTournamentInfo(+id));
    }, [dispatch, id]);

    return <div>
        {(isLoading && (<CustomSpinner />)) || <InformationTag />}
    </div>
}