import { combineReducers, configureStore } from '@reduxjs/toolkit';

import tagSlice from '../features/tag/tagSlice';
import modalSlice from '../features/modal/modalSlice';
import settingSlice from '../features/setting/settingSlice';
import scheduleSlice from '../features/schedule/scheduleSlice';
import classSlice from '../features/class/classSlice';
import tournamentSlice from '../features/tournament/tournamentSlice';
import tournamentsSlice from '../features/tournaments/tournamentsSlice';
import usersSlice from '../features/users/userSlice';
import organizationsSlice from './../features/organizations/organizationsSlice';
import currentUserSlice from './../features/currentUser/currentUserSlice';

const rootReducer = combineReducers({
    tournament: tournamentSlice,
    tournaments: tournamentsSlice,
    tags: tagSlice,
    modals: modalSlice,
    settings: settingSlice,
    schedule: scheduleSlice,
    class: classSlice,
    users: usersSlice,
    currentUser: currentUserSlice,
    organizations: organizationsSlice
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
