import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemWrapper } from '../../../../../components';
import { TournamentPrintCardInfoBox } from '../TournamentPrintCardInfoBox';

import './TournamentPrintCardSignatureSection.scss';


interface TournamentPrintCardSignatureSectionProps {
    team: number;
}

export const TournamentPrintCardSignatureSection: FC<TournamentPrintCardSignatureSectionProps> = ({ team }) => {
    const prefix = 'printCardSign-'

    const { t } = useTranslation();

    return (<div className="tournament-print-card-sign-section">
        <ItemWrapper className="tournament-print-card-sign-section__item" padding={false} overflowHidden={true}>
            <TournamentPrintCardInfoBox title="Side switch" className="tournament-print-card-sign-section__helper">
                <div className="tournament-print-card-sign-section__info-block tournament-print-card-sign-section__info-block-side">
                    <div className="tournament-print-card-sign-section__info-block-row tournament-print-card-sign-section__info-block-row_border-bottom" >
                        <div className="tournament-print-card-sign-section__info-block-half-item">{t(prefix + 'game')}</div>
                        <div className="tournament-print-card-sign-section__info-block-half-item">{t(prefix + 'atPoint')}</div>
                    </div>
                    <div className="tournament-print-card-sign-section__info-block-row">
                        <div className="tournament-print-card-sign-section__info-block-half-item">11</div>
                        <div className="tournament-print-card-sign-section__info-block-half-item">6</div>
                    </div>
                    <div className="tournament-print-card-sign-section__info-block-row">
                        <div className="tournament-print-card-sign-section__info-block-half-item">15</div>
                        <div className="tournament-print-card-sign-section__info-block-half-item">8</div>
                    </div>
                    <div className="tournament-print-card-sign-section__info-block-row">
                        <div className="tournament-print-card-sign-section__info-block-half-item">21</div>
                        <div className="tournament-print-card-sign-section__info-block-half-item">11</div>
                    </div>
                    <div className="tournament-print-card-sign-section__info-block-row_single tournament-print-card-sign-section__info-block-row_border-top">
                        {t(prefix + 'thirdGame')}
                    </div>
                </div>
            </TournamentPrintCardInfoBox>
        </ItemWrapper>
        <ItemWrapper className="tournament-print-card-sign-section__item" padding={false} overflowHidden={true}>
            <TournamentPrintCardInfoBox title="Timings" className="tournament-print-card-sign-section__helper">
                <div className="tournament-print-card-sign-section__info-block tournament-print-card-sign-section__info-block-timings">
                    <div className="tournament-print-card-sign-section__info-block-row" >
                        <div className="tournament-print-card-sign-section__info-block-text-item">{t(prefix + 'timeout')}</div>
                        <div className="tournament-print-card-sign-section__info-block-min-item">{t(prefix + 'min',{min: 1})}</div>
                    </div>
                    <div className="tournament-print-card-sign-section__info-block-row">
                        <div className="tournament-print-card-sign-section__info-block-text-item">{t(prefix + 'betweenGame')}</div>
                        <div className="tournament-print-card-sign-section__info-block-min-item">{t(prefix + 'min',{min: 2})}</div>
                    </div>
                    <div className="tournament-print-card-sign-section__info-block-row">
                        <div className="tournament-print-card-sign-section__info-block-text-item">{t(prefix + 'injury')}</div>
                        <div className="tournament-print-card-sign-section__info-block-min-item">{t(prefix + 'min',{min: 15})}</div>
                    </div>
                </div>
            </TournamentPrintCardInfoBox>
        </ItemWrapper>
        <div className="tournament-print-card-sign-section__item tournament-print-card-sign-section__sign-team">
            <div>
                {t(prefix + 'teamSign',{team})}
            </div>
            <ItemWrapper className="tournament-print-card-sign-section__sign-team-box"></ItemWrapper>
        </div>
        <div className="tournament-print-card-sign-section__item tournament-print-card-sign-section__sign">
            <div>
                <div>
                    {t(prefix + 'refSign')}
                </div>
                <ItemWrapper></ItemWrapper>
            </div>
            <div>
                <div>
                    {t(prefix + 'refName')}
                </div>
                <ItemWrapper></ItemWrapper>
            </div>
        </div>
    </div>)
}