export const matchData = {
    type: 1,
    homeTeam: null,
    awayTeam: null,
    homePlayer1: null,
    homePlayer2: null,
    awayPlayer1: null,
    awayPlayer2: null,
    bracket: 0,
};


// later
export const matchType = [
    { value: 1, label: 'enum-matchTypeRound' },
    { value: 2, label: 'enum-matchTypeRound' },
    { value: 3, label: 'enum-matchTypeRound' },
    { value: 4, label: 'enum-matchTypeRound' },
    { value: 5, label: 'enum-matchTypeRound' },
    { value: 6, label: 'enum-matchTypeRound' },
    { value: 7, label: 'enum-matchTypeRound' },
    { value: 8, label: 'enum-matchTypeRound' },
    { value: 9, label: 'enum-matchTypeRound' },
    { value: 10, label: 'enum-matchTypeRound' },
    { value: 11, label: 'enum-matchTypeQuater' },
    { value: 12, label: 'enum-matchTypeSemi' },
    { value: 13, label: 'enum-matchTypeFinals' },
];

export const filterClassFormatValues: number[] = [11, 12, 13]; 

