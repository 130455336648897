interface ITraslateItemWithLabelResult {
    label: string;
    value: any;
    props?: any;
}
type TraslationHook = (key: string, props?: any) => string;

export const traslateItemWithLabel = (traslationHook: TraslationHook, {label, value, props}: ITraslateItemWithLabelResult): ITraslateItemWithLabelResult => ({
    label: traslationHook(label, props),
    value
});