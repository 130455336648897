import { SvgIcon } from '../../components';

interface AppleIconProps {
    w?: string;
    h?: string;
    f?: string;
    s?: string;
}


export const AppleIcon = ({ w, h, f, s }: AppleIconProps) => {
    return (
        <SvgIcon width={w || '18'} height={h || '20'} viewBox="0 0 18 20">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M15.7101 17.5C14.8801 18.74 14.0001 19.95 12.6601 19.97C11.3201 20 10.8901 19.18 9.37009 19.18C7.84009 19.18 7.37009 19.95 6.10009 20C4.79009 20.05 3.80009 18.68 2.96009 17.47C1.25009 15 -0.0599081 10.45 1.70009 7.39C2.57009 5.87 4.13009 4.91 5.82009 4.88C7.10009 4.86 8.32009 5.75 9.11009 5.75C9.89009 5.75 11.3701 4.68 12.9201 4.84C13.5701 4.87 15.3901 5.1 16.5601 6.82C16.4701 6.88 14.3901 8.1 14.4101 10.63C14.4401 13.65 17.0601 14.66 17.0901 14.67C17.0601 14.74 16.6701 16.11 15.7101 17.5ZM10.0001 1.5C10.7301 0.67 11.9401 0.04 12.9401 0C13.0701 1.17 12.6001 2.35 11.9001 3.19C11.2101 4.04 10.0701 4.7 8.95009 4.61C8.80009 3.46 9.36009 2.26 10.0001 1.5Z"
                fill={f || '#2C2F30'}
                stroke={s || ''} 
            />
        </SvgIcon>
    );
};
