import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonImagePreview, ButtonTab, CustomButton } from '../../components';
import { CheckIcon } from '../../assets/icons/CheckIcon';
import { Form } from 'react-bootstrap';
import { AddIcon } from '../../assets/icons/AddIcon';
import { MemberTournamentCard } from '../../components';
import { OrganisationData } from '../../types/adminOrganisation';
import { ModalAction, ModalName } from '../../types/modal';
import { openModal } from '../../features/modal/modalSlice';
import { useAppDispatch } from '../../hooks/redux';
import { ImageListType } from 'react-images-uploading';
import { uploadImage } from '../../apis/tournament';

export const AdminOrganisation = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [organisationData, setOrganisationData] = useState<OrganisationData>({
        name: '',
        creatives: [],
        img: null,
        type: true,
        country: '',
    });

    const prefix = 'organization-';
    const buttonPrefix = 'button-';
    const inputPrefix = 'input-';

    const handleOpenModal = () => {
        dispatch(
            openModal({
                currentModal: ModalAction.ADD_MEMBER,
                modalOpen: true,
                data: {
                    modalName: ModalName.ADD_CREATIVES_MEMBER,
                    title: t(prefix + 'addCreatives') + ':',
                    setOrganisationData,
                },
            })
        );
    };

    const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOrganisationData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value.trim(),
        }));
    };

    const handleDeleteCreatives = useCallback(
        (id: number) => {
            setOrganisationData((prevState) => ({
                ...prevState,
                creatives: prevState.creatives.filter((user) => user.id !== id),
            }));
        },
        []
    );

    const onChange = useCallback(
        (imageList: ImageListType) => {
            const formData = new FormData();
            formData.append('file', imageList[0]?.file || '');
            imageList[0].file &&
                uploadImage(formData, 'organization')
                    .then((data) => {
                        setOrganisationData((prevState) => ({ ...prevState, img: data.url }));
                    })
                    .catch((e) => {
                        console.log(e);
                    });
        },
        []
    );

    const onToggleType = useCallback(() => {
        setOrganisationData((prevState) => ({ ...prevState, type: !prevState.type }));
    }, []);

    return (
        <div className="px-lg-5 px-sm-2 d-flex flex-grow-1 flex-column my-3 px-2 px-sm-0">
            <div className="d-flex justify-content-end">
                <CustomButton
                    btnText={t(buttonPrefix + 'saveAndClose')}
                    variant="primary"
                    className="d-flex align-items-center justify-content-center"
                    cb={() => {
                        console.log(organisationData);
                    }}
                >
                    <CheckIcon />
                </CustomButton>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 d-flex flex-column mb-4">
                    <p className="font-weight-normal mb-1">{t(prefix + 'name')}</p>
                    <Form.Control
                        name="name"
                        autoComplete="off"
                        placeholder={t(inputPrefix + 'placeholderOrgName') + '...'}
                        onChange={handleInput}
                    />
                </div>
                <div className="col pt-3 mt-1">
                    <div className="w-100 d-flex flex-wrap flex-column flex-md-row align-items-center">
                        <CustomButton
                            className="d-flex justify-content-evenly align-items-center mr-0 mr-md-2"
                            variant="outline-dark"
                            btnText={t(buttonPrefix + 'addCreatives')}
                            cb={handleOpenModal}
                        >
                            <AddIcon />
                        </CustomButton>
                        {organisationData.creatives.length ? (
                            organisationData.creatives.map((data) => (
                                <React.Fragment key={data.id}>
                                    <MemberTournamentCard
                                        member={data}
                                        onClick={handleDeleteCreatives}
                                    />
                                </React.Fragment>
                            ))
                        ) : (
                            <div className="d-flex justify-content-end fw-lighter">
                                <p className="mb-0">{t(prefix + 'noData') + '...'}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 text-center">
                    <ButtonImagePreview
                        title={t(prefix + 'logo')}
                        onChange={onChange}
                        src={organisationData.img}
                        alt="organization logo"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 d-flex flex-column mb-4">
                    <p className="font-weight-normal mb-1">{t(prefix + 'type')}</p>
                    <ButtonTab
                        name="organization"
                        value={organisationData.type}
                        onClick={onToggleType}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 d-flex flex-column mb-4">
                    <p className="font-weight-normal mb-1">{t(prefix + 'country')}</p>
                    <Form.Control
                        name="country"
                        autoComplete="off"
                        placeholder={t(inputPrefix + 'placeholderCountry')}
                        onChange={handleInput}
                    />
                </div>
            </div>
        </div>
    );
};
