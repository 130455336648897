import { SvgIcon } from '../../components';

interface GoogleStoreIconProps {
    w?: string;
    h?: string;
    f?: string;
    s?: string;
}


export const GoogleStoreIcon = ({ w, h, f, s }: GoogleStoreIconProps) => {
    return (
        <SvgIcon width={w || '18'} height={h || '20'} viewBox="0 0 18 20">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M0 18.5004V1.50039C0 0.910391 0.34 0.390391 0.84 0.150391L10.69 10.0004L0.84 19.8504C0.34 19.6004 0 19.0904 0 18.5004ZM13.81 13.1204L3.05 19.3404L11.54 10.8504L13.81 13.1204ZM17.16 8.81039C17.5 9.08039 17.75 9.50039 17.75 10.0004C17.75 10.5004 17.53 10.9004 17.18 11.1804L14.89 12.5004L12.39 10.0004L14.89 7.50039L17.16 8.81039ZM3.05 0.660391L13.81 6.88039L11.54 9.15039L3.05 0.660391Z"
                fill={f || '#2C2F30'}
                stroke={s || ''} 
            />
        </SvgIcon>
    );
};
