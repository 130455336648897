import { TournamentDashboard } from './../pages/tourDetails/TournamentDashboard/TournamentDashboard';
import { TournamentClasses } from '../pages/tourDetails/TournamentClasses/TournamentClasses';
import { Settings } from './../pages/tourDetails/Settings';
import { Schedule } from '../pages/tourDetails/Schedule';
import { Information } from '../pages/tourDetails/Information';
import { TournamentPlay } from '../pages/tourDetails/TournamentPlay';
import { Location } from '../pages/tourDetails/Location';

export enum TagIdsEnum {
    DASHBOARD = 'dashboard',
    INFORMATION = 'information',
    LOCATION = 'location',
    SETTINGS = 'settings',
    SCHEDULE = 'schedule',
    CLASSES = 'classes',
    PLAY = 'play',
}

export const adminTagConfig = [
    {
        id: TagIdsEnum.DASHBOARD,
        title: 'creativeDetail-dashboard',
        isActive: false,
        component: TournamentDashboard,
    },
    {
        id: TagIdsEnum.INFORMATION,
        title: 'creativeDetail-information',
        isActive: false,
        component: Information,
    },
    {
        id: TagIdsEnum.LOCATION,
        title: 'creativeDetail-location',
        isActive: false,
        component: Location,
    },
    {
        id: TagIdsEnum.SETTINGS,
        title: 'creativeDetail-settings',
        isActive: false,
        component: Settings,
    },
    {
        id: TagIdsEnum.CLASSES,
        title: 'creativeDetail-classes',
        isActive: false,
        component: TournamentClasses,
    },
    {
        id: TagIdsEnum.PLAY,
        title: 'creativeDetail-play',
        isActive: false,
        component: TournamentPlay,
    },
    {
        id: TagIdsEnum.SCHEDULE,
        title: 'creativeDetail-schedule',
        isActive: false,
        component: Schedule,
    },
];
