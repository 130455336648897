import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalAction } from '../../types/modal';

interface ModalState {
    modalOpen: boolean;
    currentModal: ModalAction | null;
    data?: any | null;
}

const initialState: ModalState = {
    modalOpen: false,
    currentModal: null,
    data: null,
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<ModalState>) => {
            return action.payload;
        },
        closeModal: () => {
            return initialState;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
