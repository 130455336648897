import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

import { TournamentMatchListTable } from './components/TournamentMatchListTable';

import { useDebounce, useInterval, useQuery } from '../../utils/hooks';
import { getMatchBoardForTournament, IMatchBoardItem } from '../../apis/tournament';
import { CustomSpinner, FormTextField } from '../../components';

import './TournamentMatchList.scss';


const limit = 20;

export const TournamentMatchList = ()=> {
    const prefix = 'tournamentMatchList-';

    const { t } = useTranslation();
    const { id } = useParams<{ id: string, s?: string }>();
    const s = useQuery().get('s');

    const [page, setPage] = useState(0);
    const [search, setSearch] = useState(s || '');
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsloading] = useState(false);
    let [forceReload, setForceReload] = useState(0);

    const searchDebounced = useDebounce(search);

    const [matchList, setMatchList] = useState<IMatchBoardItem[]>([]);

    const handlePageClick = (event: any) => setPage(event.selected);
    const getMatches = async () => {
        setIsloading(true);

        try {
            const {matchBoard, total} = await getMatchBoardForTournament({id: +id, search: searchDebounced, take: limit, skip: (page * limit)});
            setTotalPages(Math.ceil(total / limit));
            setMatchList(matchBoard);
        } catch (e) {
            setTotalPages(0);
            setMatchList([]);
        }

        setIsloading(false);
    }
    const reloadPage = () => setForceReload(++forceReload);

    useInterval(() => {
        reloadPage();
    },30 * 1000);

    useEffect(() => {
        if(!page) {
            reloadPage();
        } else {
            setPage(0);
        }
        setTotalPages(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchDebounced]);

    useEffect(() => {
        getMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, forceReload]);

    return (<div className="tournament-match-list">
                <div className="tournament-match-list__page-name">
                    {t(prefix + 'title')}
                </div>
                <div className="tournament-match-list__search-manager">
                    <div>
                        {t(prefix + 'filter')}
                    </div>
                    <div className="tournament-match-list__filter-input-wrapper">
                        <Formik initialValues={{search}} onSubmit={console.log} handleChange={console.log}>
                           {({values}) => {
                                if(search !== values.search) {
                                    setTimeout(() => setSearch(values.search), 0);
                                }

                                return (<FormTextField
                                    className="py-0 border-1 tournament-match-list__filter-input"
                                    index={0}
                                    disabled={isLoading}
                                    name="search"
                                    placeholder={t(prefix + 'placeholder')}
                                    type="text"
                                />)
                            }}
                        </Formik>
                    </div>
                </div>
                {/* <div className="tournament-match-list__search-manager">
                    <div className="tournament-match-list__title">
                        Applied filters
                    </div>
                    <div className="tournament-match-list__filter-list">
                        {(new Array(3)).fill(null).map((v, index) => (
                                                    <div key={`filter-${index}`} className="tournament-match-list__filter">
                                                        <CustomButton
                                                          className="d-flex align-items-center"
                                                          btnText={'+19 3.0 M Doubles'}
                                                          variant="outline-dark"
                                                          onClick={console.log}
                                                         >
                                                            <CloseCircle />
                                                      </CustomButton>
                                                    </div>))}
                    </div>
                </div> */}
                {(totalPages && <TournamentMatchListTable matchList={matchList}/>) || null}
                <div className="tournament-match-list__pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={t(prefix + 'next')}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageCount={totalPages}
                        previousLabel={t(prefix + 'previous')}
                        renderOnZeroPageCount={null as any}
                    />
                </div>
                {(isLoading && 
                    <div className="tournament-match-list__loader">
                        <CustomSpinner />
                    </div>
                ) || null}
            </div>);
}