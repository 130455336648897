import React, { FC, useCallback } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Field, FieldProps, ArrayHelpers } from 'formik';
import './index.scss';
import { ButtonIcon } from '../button/ButtonIcon';
import { EditIcon } from '../../assets/icons/EditIcon';
import { CloseCircle } from '../../assets/icons/CloseCircle';
import { CheckCircleIcon } from '../../assets/icons/CheckCircleIcon';
import { DataCourts } from '../../pages/tourDetails/Location/components/LocationTag/LocationTag';
import { useTranslation } from 'react-i18next';

interface EditingFormProps {
    data: Array<DataCourts>;
    name: string;
    helpers: ArrayHelpers;
    item: DataCourts;
}

export const EditingForm: FC<EditingFormProps> = (props) => {
    const { t } = useTranslation();
    const {
        name,
        helpers: { replace, remove },
        item,
        data,
    } = props;
    const index = data.findIndex((court) => court.id === item.id);

    const handleDelete = useCallback(() => {
        remove(index);
    }, [index, remove]);

    const handleEdit = useCallback(() => {
        replace(index, { ...item, isEdit: !item.isEdit });
    }, [index, item, replace]);

    return (
        <Field name={name}>
            {({ field }: FieldProps) => {
                return (
                    <InputGroup>
                        <ButtonIcon className="raqtButtonRightIcon" onClick={handleEdit}>
                            {item.isEdit ? <EditIcon /> : <CheckCircleIcon />}
                        </ButtonIcon>
                        <Form.Control
                            readOnly={item.isEdit}
                            {...field}
                            placeholder={t('location-courtNamePlaceholder')}
                        />
                        {item.isEdit && (
                            <ButtonIcon className="raqtButtonLeftIcon" onClick={handleDelete}>
                                <CloseCircle />
                            </ButtonIcon>
                        )}
                    </InputGroup>
                );
            }}
        </Field>
    );
};
