import React, { useCallback, useState } from 'react';
import { ModalProps } from 'react-bootstrap';

import { CustomButton, ModalBox } from '../../../../../components';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { ResultCard } from '../ResultCard';
import { MatchResult } from '../../../../../types/tournament';
import uniqId from 'uniqid';
import { updateStatusPlayData } from '../../../../../features/tournament/actionCreators';
import { useTranslation } from 'react-i18next';

export const ResultModal = (props: ModalProps) => {
    const dispatch = useAppDispatch();
    const { results, matchId } = useAppSelector((state) => state.modals.data);
    const [result, setResult] = useState<MatchResult[]>(results ? results : []);

    const { t } = useTranslation();
    const prefix = 'resultModal-';

    let isValidate = false;

    if (result.length) {
        for (const data of result) {
            if (isNaN(data.awayTeam)) {
                isValidate = true;
                break;
            } else if (isNaN(data.homeTeam)) {
                isValidate = true;
                break;
            }
        }
    }

    const handleSave = () => {
        if (typeof matchId === 'number') {
            const resultsData = result.map((result) =>
                typeof result.id === 'number'
                    ? { id: result.id, homeTeam: result.homeTeam, awayTeam: result.awayTeam }
                    : { homeTeam: result.homeTeam, awayTeam: result.awayTeam }
            );
            dispatch(updateStatusPlayData(matchId, { results: resultsData }));
        }

        props.onHide && props.onHide();
    };

    const handleAddResult = (id: string) => {
        setResult((prevState) => [...prevState, { id, homeTeam: 0, awayTeam: 0 }]);
    };
    const handleDeleteCard = useCallback(
        (id: number | string | undefined) => {
            id && setResult((prevState) => prevState.filter((match) => match.id !== id));
        },
        []
    );

    return (
        <ModalBox
            {...props}
            title={t(prefix + 'listOfMatch')}
            handleSave={handleSave}
            isDisabled={isValidate}
        >
            <>
                <div className="d-flex justify-content-end">
                    <CustomButton
                        disabled={result.length >= 5} // max 5 games in match
                        cb={() => {
                            handleAddResult(uniqId());
                        }}
                        className="d-flex align-items-center justify-content-around"
                        variant="outline-dark"
                        btnText={t(prefix + 'addResultButton')}
                    >
                        <AddIcon />
                    </CustomButton>
                </div>
                {result.map((data) => (
                    <React.Fragment key={data.id}>
                        <ResultCard
                            data={data}
                            results={result}
                            setResult={setResult}
                            handleDeleteCard={handleDeleteCard}
                        />
                    </React.Fragment>
                ))}
                {result.length ? (
                    <div className="row">
                        <div className="col">{t(prefix + 'previewScoreLabel')}</div>
                        <div className="col">
                            <div className="d-flex justify-content-around">
                                {result.map((data) => (
                                    <div className="d-flex mx-1" key={data.id}>
                                        <h4 className="mb-0 px-1">{data.homeTeam || 0}</h4>
                                        <h4 className="mb-0 border-start border-2 border-dark px-1">
                                            {data.awayTeam || 0}
                                        </h4>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        </ModalBox>
    );
};
