import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../components';
import { AddIcon } from '../../assets/icons/AddIcon';
import { ConfigurationItem } from './components/ConfigurationItem';
import { useAppDispatch } from '../../hooks/redux';
import { openModal } from '../../features/modal/modalSlice';
import { ModalAction } from '../../types/modal';
import { ConfigData, ConfigField } from '../../types/adminConfiguration';
import { CheckIcon } from '../../assets/icons/CheckIcon';
import { fieldTypesEnum, keywordsTypes } from './adminCounfiguration';
import { createBulkKeywords, getAllKeywords, removeKeyword } from '../../apis/keywords';
import { createBulkSponsors, getAllSponsors, removeSponsor } from '../../apis/sponsors';
import { createBulkPaddles, getAllPaddles, removePaddle } from '../../apis/paddles';
import { createBulkTours, getAllTours, removeTour } from '../../apis/tours';
import { filterUnsavedItems, prepareForView, removeIdForNewAndPrerareForBackend } from './adminCounfiguration.helpers';
import { setListKeywords, setListPaddle, setListSponsor, setListTour } from '../../features/setting/settingSlice';


// Titles are translated
export const AdminConfiguration = () => {
    const prefix = 'configuration-';
    const buttonPrefix = 'button-';

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [toRemove, setToRemove] = useState<{id: number, type: fieldTypesEnum}[]>([]);
    
    const [configData, setConfigData] = useState<ConfigData>({
        balls: [],
        surfaces: [],
        sport: [],
        tournamentType: [],
        sponsors: [],
        paddles: [],
        tours: [],
    });

    const getAllData = async () => {
        setLoading(true);
        const [keywords, sponsors, paddles, tours] = await Promise.all([
            getAllKeywords(),
            getAllSponsors(),
            getAllPaddles(),
            getAllTours()
        ]);

        dispatch(setListTour(tours));

        dispatch(setListKeywords(keywords));

        dispatch(setListPaddle(paddles));

        dispatch(setListSponsor(sponsors));

        setConfigData({
            balls: prepareForView(keywords.filter(({ type }) => type === keywordsTypes.BALL)),
            surfaces: prepareForView(keywords.filter(({ type }) => type === keywordsTypes.SURFACE)),
            sport: prepareForView(keywords.filter(({ type }) => type === keywordsTypes.SPORT)),
            tournamentType: prepareForView(keywords.filter(({ type }) => type === keywordsTypes.TOURNAMENT_TYPE)),
            sponsors: prepareForView(sponsors),
            paddles: prepareForView(paddles),
            tours: prepareForView(tours),
        } as ConfigData);

        setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {getAllData()}, []);

    const handleOpenModal = (modalKey: ModalAction, data?: any) => {
        dispatch(
            openModal({
                currentModal: modalKey,
                modalOpen: true,
                data,
            })
        );
    };


    const removeItem = ({id, type}: {id: number, type: fieldTypesEnum}) => {
        switch (type) {
            case fieldTypesEnum.KEYWORD:
                return removeKeyword(id);
            case fieldTypesEnum.PADDLE:
                return removePaddle(id)
            case fieldTypesEnum.SPONSOR:
                return removeSponsor(id);
            case fieldTypesEnum.TOUR: 
                return removeTour(id);
        }
    }

    const clearRemovedItems = async () => {
        await Promise.all(toRemove.map(removeItem));
        setToRemove([]);
    }

    const handleDeleteConfigItem = useCallback(
        (field: ConfigField, id: number | string, type: fieldTypesEnum ) => {
            setConfigData((prevState) => ({
                ...prevState,
                [field]: prevState[field].filter((el) => el.id !== id),
            }));
            if(typeof id === 'number') {
                setToRemove([...toRemove, {id, type}]);
            }
        },
        [toRemove]
    );
    
    return (!loading && (<div className="px-lg-5 px-sm-2 d-flex flex-grow-1 flex-column my-3 px-2 px-sm-0">
            <div className="d-flex justify-content-end mb-4">
                <CustomButton
                    btnText={t(buttonPrefix + 'saveAndClose')}
                    variant="primary"
                    className="d-flex align-items-center justify-content-center"
                    cb={async () => {
                        const keywords = [
                            ...configData.balls.map(item => ({
                                id: item.id,
                                type: keywordsTypes.BALL,
                                name: item.value
                            })),
                            ...configData.surfaces.map(item => ({
                                id: item.id,
                                type: keywordsTypes.SURFACE,
                                name: item.value
                            })),
                            ...configData.sport.map(item => ({
                                id: item.id,
                                type: keywordsTypes.SPORT,
                                name: item.value
                            })),
                            ...configData.tournamentType.map(item => ({
                                id: item.id,
                                type: keywordsTypes.TOURNAMENT_TYPE,
                                name: item.value
                            })),
                        ]
                        .filter(({id}) => typeof id === 'string')
                        .map(({id, ...rest}) => {
                            return typeof id === 'string' ? rest : Object.assign(rest, {id}); 
                        });
                        const paddles = filterUnsavedItems(configData.paddles);
                        const tours = filterUnsavedItems(configData.tours);
                        const sponsors = filterUnsavedItems(configData.sponsors);

                        await Promise.all([ 
                            keywords.length && createBulkKeywords(keywords),
                            paddles.length && createBulkPaddles(removeIdForNewAndPrerareForBackend(paddles)),
                            tours.length && createBulkTours(removeIdForNewAndPrerareForBackend(tours)),
                            sponsors.length && createBulkSponsors(removeIdForNewAndPrerareForBackend(sponsors)),
                        ]);
                        await clearRemovedItems();
                        
                        getAllData();
                    }}
                >
                    <CheckIcon />
                </CustomButton>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between justify-content-lg-start">
                        <h4 className="font-weight-bold mb-0 mr-3">{t(prefix + 'keywords')}</h4>
                        <CustomButton
                            btnText={t(buttonPrefix + 'addKeyword')}
                            className="d-flex justify-content-between align-items-center"
                            variant="outline-dark"
                            cb={handleOpenModal.bind(
                                null,
                                ModalAction.KEYWORD_WINDOW,
                                setConfigData
                            )}
                        >
                            <AddIcon />
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div className="row">
                <ConfigurationItem
                    title="Balls"
                    name="balls"
                    data={configData.balls}
                    handleDeleteConfigItem={(field, id) => handleDeleteConfigItem(field, id, fieldTypesEnum.KEYWORD)}
                />
                <ConfigurationItem
                    title="Surfaces"
                    name="surfaces"
                    data={configData.surfaces}
                    handleDeleteConfigItem={(field, id) => handleDeleteConfigItem(field, id, fieldTypesEnum.KEYWORD)}
                />
                <ConfigurationItem
                    title="Sport"
                    name="sport"
                    data={configData.sport}
                    handleDeleteConfigItem={(field, id) => handleDeleteConfigItem(field, id, fieldTypesEnum.KEYWORD)}
                />
                <ConfigurationItem
                    title="TournamentType"
                    name="tournamentType"
                    data={configData.tournamentType}
                    handleDeleteConfigItem={(field, id) => handleDeleteConfigItem(field, id, fieldTypesEnum.KEYWORD)}
                />
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between justify-content-lg-start">
                        <h4 className="font-weight-bold mb-0 mr-3">{t(prefix + 'sponsors')}</h4>
                        <CustomButton
                            btnText={t(buttonPrefix + 'addSponsor')}
                            className="d-flex justify-content-between align-items-center"
                            variant="outline-dark"
                            cb={handleOpenModal.bind(
                                null,
                                ModalAction.SPONSOR_WINDOW,
                                setConfigData
                            )}
                        >
                            <AddIcon />
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div className="row">
                <ConfigurationItem
                    title="Sponsors"
                    name="sponsors"
                    data={configData.sponsors}
                    handleDeleteConfigItem={(field, id) => handleDeleteConfigItem(field, id, fieldTypesEnum.SPONSOR)}
                />
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between justify-content-lg-start">
                        <h4 className="font-weight-bold mb-0 mr-3">{t(prefix + 'paddles')}</h4>
                        <CustomButton
                            btnText={t(buttonPrefix + 'addPaddles')}
                            className="d-flex justify-content-between align-items-center"
                            variant="outline-dark"
                            cb={handleOpenModal.bind(
                                null,
                                ModalAction.PADDLE_WINDOW,
                                setConfigData
                            )}
                        >
                            <AddIcon />
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div className="row">
                <ConfigurationItem
                    title="Paddles"
                    name="paddles"
                    data={configData.paddles}
                    handleDeleteConfigItem={(field, id) => handleDeleteConfigItem(field, id, fieldTypesEnum.PADDLE)}
                />
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between justify-content-lg-start">
                        <h4 className="font-weight-bold mb-0 mr-3">{t(prefix + 'tours')}</h4>
                        <CustomButton
                            btnText={t(buttonPrefix + 'addTour')}
                            className="d-flex justify-content-between align-items-center"
                            variant="outline-dark"
                            cb={handleOpenModal.bind(null, ModalAction.TOUR_WINDOW, setConfigData)}
                        >
                            <AddIcon />
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div className="row">
                <ConfigurationItem
                    title="Tours"
                    name="tours"
                    data={configData.tours}
                    handleDeleteConfigItem={(field, id) => handleDeleteConfigItem(field, id, fieldTypesEnum.TOUR)}
                />
            </div>
        </div>)) || <div>Loading...</div>;
};
