import React, { FC } from 'react';
import { Header } from '../../header/Header';
//import { Footer } from '../../footer/Footer';
import { Modal } from '../../index';

interface MainLayoutProps {
    children: React.ReactElement;
}

export const MainLayout: FC<MainLayoutProps> = (props) => {
    const { children } = props;

    return (
        <div className="d-flex flex-column justify-content-between bg-grey vh-min-screen">
            <Header />
            {children}
            <Modal />
            {/* <Footer /> */}
        </div>
    );
};
