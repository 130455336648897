import uniqId from 'uniqid';

export const initialData = {
    location: '',
    pinLocation: '',
    lat: 0,
    lng: 0,
    courtCount: 1,
    courts: [{ id: uniqId(), name: 'court 1', isEdit: true }],
};
