import { FC, useEffect, useRef, useState } from 'react';

import { ChevronIcon } from '../../assets/icons/ChevronIcon';

import './PlainDropdown.scss';

interface PlainDropdownProps {
    selected: any;
    options: any[];
    keyToShow?: string;
    callback: (e: any) => void;
}

export const PlainDropdown: FC<PlainDropdownProps> = ({selected, options, keyToShow, callback}) => {
    const myRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    
    const handleClickOutside = (e: any) => {
        if (!(myRef.current as any)?.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });


    return (<div className="plain-dropdown" onClick={() => setIsOpen(!isOpen)} role="button" ref={myRef as any}>
                <div className="plain-dropdown__button-holder">
                    <div className="plain-dropdown__title">{keyToShow ? selected?.[keyToShow] : selected}</div>
                    <div className="plain-dropdown__indicator">
                        <ChevronIcon height="20" width="20" isClick={!isOpen}/>
                    </div>
                </div>
                {isOpen && <div className="plain-dropdown__options">
                    {options.map((item, index) => 
                        (<div key={`option-key-${index}`} className="plain-dropdown__option" onClick={() => callback(item)}>
                            {keyToShow ? item[keyToShow] : item}
                        </div>))}
                </div>}
            </div>);
};
