import { getDateAsUtc } from './getStringDate';
import { PlayValues } from '../pages/tourDetails/TournamentPlay/components/PlayTag/PlayTag';
import { toBoolean } from './common.helpers';

export const playTagTypeConverter = (playValue: PlayValues) => {
    return {
        tournamentGroups: playValue.tournamentGroups.map((group) => {
            if (typeof group.id === 'string') {
                const { id, ...rest } = group;

                return rest;
            } else {
                return group;
            }
        }),
        tournamentMatches: Object.values(playValue.tournamentMatches).filter(( { id } ) => Boolean(id)).map((match) => {
            if (typeof match.id === 'string') {
                const { id, ...rest } = match;
                return {
                    ...rest,
                    startDate: getDateAsUtc(rest.startDate || ''),
                    isGold: toBoolean(rest.isGold),
                    court: { id: rest.court },
                    bracket: Number(match.bracket)
                };
            } else {
                return {
                    ...match,
                    startDate: getDateAsUtc(match.startDate || ''),
                    isGold: toBoolean(match.isGold),
                    court: { id: match.court },
                    bracket: Number(match.bracket)
                };
            }
        }),
    };
};
