import { FC } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RoundedAvatar } from '../../../../components';
import { TournamentMatchStatusEnum, TournamentMatchStatusEnumTranslation } from '../../../../types/enums';
import { TournamentState } from '../../../../types/tournament';
import { getTime, getDate } from '../../../../utils/getStringDate';
import { IMatch } from '../../../tourDetails/TournamentPlay/components/PlayTag/PlayTag';
import { TournamentScorecardPageMatchModalTeam } from '../TournamentScorecardPageMatchModalTeam';

import './TournamentScorecardPageMatchModal.scss';

interface TournamentScorecardPageMatchModalProps extends ModalProps {
    tournament?: TournamentState;
    match?: IMatch;
    selectedClassName?: string;
}

export const TournamentScorecardPageMatchModal: FC<TournamentScorecardPageMatchModalProps> = ({tournament, match, selectedClassName, ...rest}) => {
    const prefix = 'tournamentInfoModal-';
    const { t } = useTranslation();
    
    return (<Modal {...rest} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="tournament-scorecard-page-match-modal__header d-flex align-items-center" closeButton />
                <Modal.Body className="br-16">
                    <div className="min-h-1">
                        <div className="tournament-scorecard-page-match-modal__head">
                            <div className="tournament-scorecard-page-match-modal__tournament-icon">
                                <RoundedAvatar noMargin={true} avatar={tournament?.icon || null} height="35" width="35" alt="Tournament image" name="flag"/>
                            </div>
                            <div className="tournament-scorecard-page-match-modal__tournament-class">
                                <div className="tournament-scorecard-page-match-modal__title">{t(prefix + 'class')}</div>
                                <div className="tournament-scorecard-page-match-modal__name">{selectedClassName}</div>
                            </div>
                        </div>
                        <div className="tournament-scorecard-page-match-modal__match-info">
                            <div>
                                <div className="tournament-scorecard-page-match-modal__title tournament-scorecard-page-match-modal__court">{
                                    t(prefix + 'court')}
                                </div>
                                <div className="tournament-scorecard-page-match-modal__name">{(match?.court as any)?.name || '-'}</div>
                            </div>
                            <div>
                                <div className="tournament-scorecard-page-match-modal__title tournament-scorecard-page-match-modal__date">{
                                    t(prefix + 'date')}
                                </div>
                                <div className="tournament-scorecard-page-match-modal__name">{match?.startDate && getDate(match?.startDate)}</div>
                            </div>
                            <div>
                                <div className="tournament-scorecard-page-match-modal__title tournament-scorecard-page-match-modal__time">{t(prefix + 'time')}</div>
                                <div className="tournament-scorecard-page-match-modal__name">{match?.startDate && getTime(match?.startDate)}</div>
                            </div>
                            <div>
                                <div className="tournament-scorecard-page-match-modal__title tournament-scorecard-page-match-modal__status">{t(prefix + 'status')}</div>
                                <div className="tournament-scorecard-page-match-modal__name">{
                                    t(TournamentMatchStatusEnumTranslation[(match?.status) as TournamentMatchStatusEnum || 0] || '').toUpperCase()
                                    }</div>
                            </div>
                        </div>
                        <div>                        
                            <TournamentScorecardPageMatchModalTeam 
                                teamNumber={1} 
                                player1={match?.homePlayer1 || null} 
                                player2={match?.homePlayer2 || null} 
                                results={match?.results?.map(i => i['homeTeam'])}
                                showGameHeader={true}
                            />
                            <TournamentScorecardPageMatchModalTeam
                                teamNumber={2} 
                                player1={match?.awayPlayer1 || null} 
                                player2={match?.awayPlayer2 || null} 
                                results={match?.results?.map(i => i['awayTeam'])}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>);
};