import { api } from './api.config';

export const createBulkKeywords = async (data: any[]): Promise<any[]> => {
    const response = await api.post(`keywords/bulk`, {bulk: data});
    return response.data;
};

export const getAllKeywords = async (): Promise<any[]> => {
    const response = await api.get(`keywords`);
    return response.data;
};

export const removeKeyword = async (id: number): Promise<any[]> => {
    const response = await api.delete(`keywords/${id}`);
    return response.data;
};