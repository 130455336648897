import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../assets/icons/EditIcon';
import { CustomButton } from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { setFreeEditMode } from '../../../../../features/schedule/scheduleSlice';
import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';

export const FreeEditToggle = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const dispatch = useAppDispatch();
    const { isFreeEditMode } = useAppSelector(selectSchedule);

    const toggleFreeEditMode = () => {
        dispatch(setFreeEditMode(!isFreeEditMode));
    };

    return (
        <CustomButton btnText="" variant="outline-dark" cb={toggleFreeEditMode}>
            <EditIcon />
            {t(prefix + 'freeEdit' + (isFreeEditMode ? 'IsOn' : 'IsOff'))}
        </CustomButton>
    );
};
