import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemWrapper } from '../../../../../components';

import './TournamentCardPlayerHolder.scss';

interface TournamentCardPlayerHolderProps {
    team: number | string;
    player: number | string;
    teamPlayer: string;
}

export const TournamentCardPlayerHolder: FC<TournamentCardPlayerHolderProps> = ({
    team,
    player,
    teamPlayer
}) => {
    const prefix = 'printCardTeamPlayer-';

    const { t } = useTranslation();

    return (<div className="tournament-card-player-holder">
            <div className="tournament-card-player-holder__title">
                {t(prefix + 'title', {team, player})}
            </div>
            <div className="tournament-card-player-holder__player-info">
                <ItemWrapper className="tournament-card-player-holder__player-name">{teamPlayer}</ItemWrapper>
                <ItemWrapper className="tournament-card-player-holder__odd-or-even">{t(prefix + 'evenOrOdd')}</ItemWrapper>
            </div>
        </div>);
};