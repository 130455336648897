import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { routes } from './routes';
import { MainLayout } from './components';
import { ProtectedPage } from './pages/protectedPage';

function App() {
    return (
        <Router>
            <MainLayout>
                <Switch>
                    {routes.map((route) => (
                        <ProtectedPage {...route} key={route.path} />
                    ))}
                </Switch>
            </MainLayout>
        </Router>
    );
}

export default App;
