import { toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TypeError } from '../types/enums';

export const Notification = (
    type: TypeError,
    message: string,
    position: ToastPosition = 'top-right'
) => {
    const autoClose = 3000;
    switch (type) {
        case TypeError.info:
            toast.info(message, {
                position,
                autoClose,
            });
            break;
        case TypeError.success:
            toast.success(message, {
                position,
                autoClose,
            });
            break;
        case TypeError.warning:
            toast.warning(message, {
                position,
                autoClose,
            });
            break;
        case TypeError.error:
            toast.error(message, {
                position,
                autoClose,
            });
            break;
    }
};
