import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { ModalProps, Spinner } from 'react-bootstrap';
import { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { addMembers } from '../../features/setting/settingSlice';
import { ModalBox, RoundedAvatar } from '../../components';
import { ModalName } from '../../types/modal';
import { ClassTeam } from '../../pages/tourDetails/TournamentClasses/components/ClassesTag/ClassesTag';
import { fetchUsers } from '../../features/users/actionCreators';
import { clearUsers, addUsersFromSearch } from '../../features/users/userSlice';
import { fetchUsersData } from '../../apis/tournament';
import { Team, User } from '../../types/tournament';
import { imgOptionUserLabel } from '../../utils/imgOptionUserLabel';
import { OrganisationData } from '../../types/adminOrganisation';
import { ClassTypeEnum } from '../../types/enums';

type Option = { value: number; label: string };

export const customStyles: StylesConfig = {
    control: (base) => ({ ...base, borderRadius: '16px', minHeight: '50px' }),
};

interface MemberModalProps extends ModalProps{
    selected?: User[];
}

export const MemberModal = ({selected = [], ...props}: MemberModalProps) => {
    const { data } = useAppSelector((state) => state.modals);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { userData, isLoading } = useAppSelector((state) => state.users);
    const [multiSelections, setMultiSelections] = useState<User[]>([]);

    const prefix = 'modal-';
    const inputPrefix = 'input-';

    useMemo(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const handleSelect = useCallback(
        (e: Option[]) => {
            setMultiSelections(
                //@ts-ignore
                e.map((option) => userData.find((user) => user.id === option.value))
            );
        },
        [userData]
    );

    const promiseOptions = debounce((inputValue, cb) => {
        fetchUsersData(inputValue)
            .then((response) => {
                dispatch(addUsersFromSearch(response));
                cb(response.map((user: User) => imgOptionUserLabel(user)));
            })
            .catch((e) => {
                console.log(e);
            });
    }, 500);

    const handleSave = useCallback(() => {
        if (data?.modalName === ModalName.ADD_TOURNAMENT_MEMBER) {
            const newMulti = multiSelections.filter(nm => !(data.selected as User[] || []).find(om => om.id === nm.id));
            dispatch(addMembers([...data.selected, ...newMulti]));
        } else if (data?.modalName === ModalName.ADD_TEAM_PLAYERS_CLASS) {
            const { teams, setField, teamId, type } = data;
            const currentTeam = teams.find((team: Team) => team.id === teamId);

            let usersRaw; 

            if(type?.value === ClassTypeEnum.SINGLES && multiSelections.length >= 1) {
                usersRaw = multiSelections.slice(0, 1);
            } else if (type?.value === ClassTypeEnum.DOUBLES && multiSelections.length >= 2) {
                usersRaw = multiSelections.slice(0, 2);
            } else if (type?.value === ClassTypeEnum.TEAMS) {
                usersRaw = multiSelections;
            } else {
                usersRaw = multiSelections;
            }


            let users = [] as User[];
            if(type?.value === ClassTypeEnum.DOUBLES && usersRaw.length === 1 && currentTeam.users.length === 1) {
                users = [...currentTeam.users, ...usersRaw.filter(u => !currentTeam.users.find((ctu: User) => ctu.id === u.id))];
            } else if (type?.value === ClassTypeEnum.TEAMS) {
                users = [...currentTeam.users, ...usersRaw.filter(u => !currentTeam.users.find((ctu: User) => ctu.id === u.id))];
            } else {
                users = usersRaw;
            }

            let userTitleSingleOrDouble = '';

            if (type?.value === ClassTypeEnum.DOUBLES || type?.value === ClassTypeEnum.SINGLES) {
                const usersTitle = (idx: number) => {
                    if (users[idx]?.firstName && users[idx]?.lastName) {
                        return `${users[idx].firstName || ''} ${users[idx].lastName}`;
                    }
                    return users[idx]?.userName;
                };

                userTitleSingleOrDouble =
                    users.length === 1 ? usersTitle(0) : `${usersTitle(0)} / ${usersTitle(1)}`;
            }

            setField(
                'teams',
                teams.map((team: ClassTeam) =>
                    team.id === teamId
                        ? {
                              ...team,
                              name: type?.value === ClassTypeEnum.TEAMS ? team.name : type?.label === 'Cup' ? team.name : userTitleSingleOrDouble.length > 0 ? userTitleSingleOrDouble : '',
                              users,
                          }
                        : team
                )
            );
        } else if (data?.modalName === ModalName.ADD_CREATIVES_MEMBER) {
            data.setOrganisationData((prevState: OrganisationData) => {
                const newMulti = multiSelections.filter(nm => !(prevState.creatives as User[] || []).find(om => om.id === nm.id));
                
                return ({
                    ...prevState,
                    creatives: [...prevState.creatives, ...newMulti],
                });
            });
        } else if (data?.modalName === ModalName.ADD_TEAM_CAPTAIN_CLASS) {
            const { teams, setField, teamId, type } = data;

            const usersRaw = multiSelections.slice(0, 1); 

            let captain = [] as User[];
            captain = usersRaw;

            if ( type?.value === ClassTypeEnum.TEAMS ) {
                setField(
                    'teams',
                    teams.map((team: ClassTeam) =>
                        team.id === teamId
                            ? {
                                  ...team,
                                  captain: captain
                              }
                            : team
                    )
                );
            }
        }

        dispatch(clearUsers());
        props.onHide && props.onHide();
    }, [data, dispatch, multiSelections, props]);

    return (
        <ModalBox {...props} title={data?.title} handleSave={handleSave}>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <p className="mb-0 mx-3">{t(prefix + 'userFetch') + '...'}</p>
                    <Spinner size="sm" animation="border" variant="dark" />
                </div>
            ) : (
                <AsyncSelect
                    styles={customStyles}
                    isMulti
                    name="members"
                    className="basic-multi-select holder-select"
                    classNamePrefix="select"
                    defaultOptions={userData.map((user) => imgOptionUserLabel(user))}
                    loadOptions={promiseOptions}
                    placeholder={t(inputPrefix + 'placeholderSelect') + '...'}
                    //@ts-ignore
                    onChange={handleSelect}
                    formatOptionLabel={(data: any) => (
                        <div className="d-flex align-items-center">
                            <RoundedAvatar avatar={data.avatar} alt="User avatar" name="user" />
                            {data.label}
                        </div>
                    )}
                />
            )}
        </ModalBox>
    );
};
