import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';
import { useAppSelector } from '../../../../../hooks/redux';
import { ScheduleItem } from './ScheduleItem';

export const UnscheduledMatches = () => {
    const { unscheduledMatches, isFreeEditMode } = useAppSelector(selectSchedule);

    return (
        <div className="container-fluid border">
            <div
                className={'row flex-nowrap overflow-auto' + (isFreeEditMode ? '' : ' opacity-50 ')}
            >
                {unscheduledMatches.map((match, matchIndex) => {
                    return (
                        <div key={'match-' + matchIndex} className="col-sm-auto col-6">
                            <ScheduleItem item={match} key={matchIndex} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
