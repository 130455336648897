import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './SettingBox.scss';

interface SettingBoxProps {
    children: React.ReactElement;
    title: string;
}

export const SettingBox: FC<SettingBoxProps> = ({ children, title }) => {
    const { t } = useTranslation();

    const prefix = 'creativeDetail-';

    return (
        <div className="d-block d-lg-flex align-items-center my-3">
            <div className="col-lg-4 col pb-0 pb-lg-3 pb-lg-0 ps-0">
                <span>{t(prefix + title.toLowerCase())}</span>
            </div>
            <div className="col px-0">
                <div className="settingInputContainer">{children}</div>
            </div>
        </div>
    );
};
