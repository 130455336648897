import { api } from './api.config';

export const createBulkSponsors = async (data: any[]): Promise<any[]> => {
    const response = await api.post(`sponsor/bulk`, {bulk: data});
    return response.data;
};

export const getAllSponsors = async (): Promise<any[]> => {
    const response = await api.get(`sponsor`);
    return response.data;
};

export const removeSponsor = async (id: number): Promise<any[]> => {
    const response = await api.delete(`sponsor/${id}`);
    return response.data;
};