import { FC } from 'react';
import classnames from 'classnames';
import './MatchInformationItemWrapperGray.scss';

interface MatchInformationItemWrapperGrayProps {
    children?: JSX.Element | string;
    className?: string;
    padding?: boolean;
    overflowHidden?: boolean;
    white?: boolean;
}

export const MatchInformationItemWrapperGray: FC<MatchInformationItemWrapperGrayProps> = ({ 
    children = '',
    className = '',
    padding = true,
    overflowHidden,
    white = false
}) => 
(<div className={classnames('match-information-item-wrapper-gray',
                            {'match-information-item-wrapper-gray_padding': padding,
                             'match-information-item-wrapper-gray_overflow-hidden': overflowHidden,
                             'creative-tour-detail__gray-item': !white },
                             className)}>
    {children}
</div>);