import { createRegistrationForm, getAllRegistrationForm } from "../../apis/registration-form";
import { ComboPricesForEach } from "./SellPage.constants";

export const submitForm = ({policy, ...data}: any) => {
   const body = {
    ...data,
    class: data.class.filter(({ toggle }: any) => toggle).map((data: any, index: number) => ({
        class: data.class,
        price: ComboPricesForEach[index],
        partnerFirstName: data.partnerFirstName,
        partnerLastName: data.partnerLastName,
        description: data.description
    }))
   }; 
   return createRegistrationForm(body);
};

export const getTeamCountOfClasses = async () => {
    const data = await getAllRegistrationForm();

    return data.reduce((acc: any, item: any) => {
        const foundedItem = acc.find(({ name }: {name: string}) => {
            return name === item.class;
        });
        if(foundedItem) {
            foundedItem.count++;
        } else {
            acc.push({name: item.class, count: 1});
        }

        return acc;
    }, [])
}

export const formStartsString = (max: number, current = 0, team: boolean) => {
    return team ? `${max - current} parejas disponibles (${max})`: `${max - current} jug. disponibles (${max})`;
};

