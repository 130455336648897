export const isString = (data: any) => typeof data  === 'string';

export const leadZero = (num: number) => (num < 10 ? '0' : '') + num;

export const toBoolean = (data: any) => {
    if (typeof data === 'string') {
        data = data === 'true';
    }

    if (typeof data === 'number') {
        data = Boolean(data);
    }

    return data;
};

export const openInNewTab = (url: string) => {
    url && window.open(url, '_blank');
}
