import React from 'react';
import { SvgIcon } from '../../components';

interface WarningIconProps {
    w?: string;
    h?: string;
    f?: string;
    className?: string;
}

export const WarningIcon = (props: WarningIconProps) => {
    const { w, h, f, className } = props;
    return (
        <SvgIcon width={w || '14'} height={h || '14'} viewBox="0 0 24 24" className={className}>
            <path d="M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z" fill={f || 'black'} />
        </SvgIcon>
    );
};
