import { useTranslation } from 'react-i18next';
import {
    addUnscheduledItemToSchedule,
    swapScheduleItems,
} from '../../../../../features/schedule/scheduleActions';
import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';
import { setSelectedItem } from '../../../../../features/schedule/scheduleSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { Notification } from '../../../../../hooks/useNotification';
import { TypeError } from '../../../../../types/enums';
import {
    IScheduleItem,
    isFreeSlot,
    isScheduled,
    itemsOnSameTimeSlot,
} from '../../../../../types/schedule';
import { FreeSlotItem } from './FreeSlotItem';
import { MatchSlotItem } from './MatchSlotItem';
import { getItemCursorType } from './item.helpers';

export const ScheduleItem = ({ item }: { item: IScheduleItem }) => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const dispatch = useAppDispatch();
    const { selectedItem, currentFilters, isFreeEditMode } = useAppSelector(selectSchedule);

    const itemIsScheduled = isScheduled(item);
    const selectedItemIsScheduled = !!selectedItem && isScheduled(selectedItem);

    const handleClick = () => {
        if (currentFilters.length === 0) {
            if (selectedItem === null && (itemIsScheduled || isFreeEditMode)) {
                dispatch(setSelectedItem(item));
            } else {
                const moveUnscheduledMatchToFreeSlot =
                    selectedItem !== null &&
                    !selectedItemIsScheduled &&
                    isFreeSlot(item) &&
                    isFreeEditMode;

                if (itemIsScheduled && selectedItemIsScheduled) {
                    const shouldPerformLateralSwap = itemsOnSameTimeSlot(item, selectedItem);

                    if (shouldPerformLateralSwap || isFreeEditMode) {
                        dispatch(swapScheduleItems(item, selectedItem, t));
                    }
                } else if (moveUnscheduledMatchToFreeSlot) {
                    dispatch(addUnscheduledItemToSchedule(item, selectedItem, t));
                }
            }
        } else {
            Notification(TypeError.error, t(prefix + 'errorEditWithActiveFilter'));
        }
    };

    return (
        <div
            onClick={() => handleClick()}
            style={{
                cursor: getItemCursorType(item, selectedItem, isFreeEditMode),
                height: '100%',
            }}
        >
            {isFreeSlot(item) ? <FreeSlotItem item={item} /> : <MatchSlotItem item={item} />}
        </div>
    );
};
