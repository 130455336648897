import { ClassSettingsBallEnum } from './../types/enums';

export const sportOptions = [{ value: 1, label: 'Pickleball' }];

export const tourOptions = [
    { value: '0', label: 'None' },
    { value: '4', label: 'STANDALONE' },
];

export const tournamentTypeOptions = [
    { value: 1, label: 'Regular' },
    { value: 2, label: 'Team league' },
];

export const tripleOption = ['enum-settingsClassStatusNone', 'enum-settingsClassStatusPublished', 'enum-settingsClassStatusStarted', 'enum-settingsClassStatusEnded'];
export const boolOption = ['true', 'false'];
export const ratingOption = ['100', '80', '60'];
export const rankingOption = ['300', '200', '100'];
export const stateClassOption = ['enum-saleStateClassHide', 'enum-saleStateClassShow'];
export const ballOption = [
    { value: ClassSettingsBallEnum.FRANKLIN_X40, label: 'Franklin X40' },
    { value: ClassSettingsBallEnum.FRANKLIN_X26, label: 'Franklin X26' },
    { value: ClassSettingsBallEnum.DURA_FAST_40, label: 'Dura fast 40' },
    { value: ClassSettingsBallEnum.ONYX, label: 'Onyx' },
    { value: ClassSettingsBallEnum.EXEL, label: 'Exel' },
    { value: ClassSettingsBallEnum.ZCEBRA, label: 'Zcebra' },
];
export const surfaceOption = [
    { value: 1, label: 'Hard court' },
    { value: 2, label: 'Plastic sport floor' },
    { value: 3, label: 'Wood' },
    { value: 4, label: 'Tarmac' },
    { value: 5, label: 'Concrete' },
];
export const isDiscountOption = [
    { label: 'toggle-yes', value: true },
    { label: 'toggle-no', value: false },
];
export const ticketSalesOption = ['enum-settingsTicketSalesAutomated', 'enum-settingsTicketSalesManual'];

export const initialData = (translationHook: (key:string) => string) => ({
    keywordSport: sportOptions[0].value,
    tour: tourOptions[1].value,
    keywordTournamentType: tournamentTypeOptions[0].value,
    status: translationHook(tripleOption[0]),
    showOnPublicPage: boolOption[0],
    numberOfGroups: 1,
    liveStreaming: boolOption[0],
    liveStreamingLink: '',
    isRating: boolOption[0],
    ratingType: ratingOption[0],
    isRanking: boolOption[0],
    rankingType: rankingOption[0],
    isCanRecordScores: boolOption[0],
    // matchPoints: 1,
    stateOfClass: translationHook(stateClassOption[0]),
    keywordBall: ballOption[0].value,
    keywordSurface: surfaceOption[0].value,
    isTicketSales: translationHook(ticketSalesOption[0]),
    isShowSalesButton: boolOption[0],
    minPlayers: 0,
    maxPlayers: 0,
    price: 1,
    vat: 1,
    isDiscount: false,
    discount1: 0,
    discount2: 0,
    discount3: 0,
});
