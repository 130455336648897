
import React, { FC, Ref } from 'react';
import { selectTournament } from '../../../../../features/tournament/tournamentSelectors';

import { useAppSelector } from '../../../../../hooks/redux';
import { User } from '../../../../../types/tournament';
import { IMatch } from '../PlayTag/PlayTag';
import { TournamentPrintCardTeam } from '../TournamentPrintCardTeam';
import { getPlayerName } from './TournamentPrintCard.helpers';

import './TournamentPrintCard.scss';

interface TournamentPrintCardProps {
    ref: Ref<any>;
    match: IMatch;
    matchId: string;
}

export const TournamentPrintCard: FC<TournamentPrintCardProps> = React.forwardRef(({match, matchId }, ref)=> {
    const { courts } = useAppSelector(selectTournament).data || {};
    const { name: courtName } = courts?.find(({id}) => id === match.court) || {name: ''};

    return (<div ref={ref} className="tournament-print-card">
                <div className="tournament-print-card__home-team">
                    <TournamentPrintCardTeam 
                        matchId={matchId}
                        court={courtName} 
                        team={1}
                        player1={getPlayerName(match.homePlayer1 || {} as User)}
                        player2={getPlayerName(match.homePlayer2 || {} as User)}
                    />
                </div>
                <div className="tournament-print-card__away-team">
                    <TournamentPrintCardTeam 
                        matchId={matchId}
                        court={courtName}
                        team={2}
                        player1={getPlayerName(match.awayPlayer1 || {} as User)}
                        player2={getPlayerName(match.awayPlayer2 || {} as User)}
                    />
                </div>
            </div>)
});