import classNames from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormTextField } from "../../../../../components";
import { isString } from "../../../../../utils/common.helpers";
import { IMatch } from "../../../TournamentPlay/components/PlayTag/PlayTag";

import './ShowDontKnowTeamOrError.scss';

interface ShowDontKnowTeamOrErrorProps {
    match: IMatch;
    placeholderKey: 'homeTeamPlaceholder' | 'awayTeamPlaceholder'

}

export const ShowDontKnowTeamOrError: FC<ShowDontKnowTeamOrErrorProps> = ({ match, placeholderKey}) => {
    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';

    const { t } = useTranslation();
    const showTeamPlaceholder = isString(match[placeholderKey]);

    return <div className={classNames(['tournament-play__dont-know', {'tournament-play__dont-know_placeholder': showTeamPlaceholder}])}>
              {(showTeamPlaceholder && 
                (<FormTextField
                    className="match-section__match-input py-0 border-1 border-white creative-tour-detail__gray-input"
                    name={`tournamentMatches.${match.id}.${placeholderKey}`}
                    index={match.id}
                    placeholder={t(inputPrefix + placeholderKey)}
                />)) || (<p className="fw-lighter">{t(prefix + 'warning4')}</p>)}
            </div> 
};