import {
    CreativeTourDetail,
    CreativeDashboard,
    AdminDashboard,
    AdminConfiguration,
    AdminOrganisation,
    TournamentScorecardPage,
    NoMatch,
    SellPage,
    LandingPage,
    TournamentMatchList,
    CreativeTourDetailRedirect,
    Login,
    ResetPassword,
    SignUp,
    OrganisationList,
    OrganisationCreate,
    TournamentMatchesStats,
    TournamentPlayersStats,
    OrganisationSettings,
    TournamentClassesStats,
    TournamentTicketsStats,
    PublicTournamentInfo,
} from './pages';

export const routes = [
    {
        path: '/sign-in',
        component: Login,
        exact: true,
        show: true,
        unauth: true,
    },
    {
        path: '/communities',
        component: OrganisationList,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/communities/create',
        component: OrganisationCreate,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/community/:communityId',
        component: CreativeDashboard,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/community/:communityId/settings',
        component: OrganisationSettings,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/community/:communityId/tournament/:id',
        component: CreativeTourDetailRedirect,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/community/:communityId/tournament/:id/tags/:tag',
        component: CreativeTourDetail,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/community/:communityId/tournament/:id/stats/matches',
        component: TournamentMatchesStats,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/community/:communityId/tournament/:id/stats/players',
        component: TournamentPlayersStats,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/community/:communityId/tournament/:id/stats/classes',
        component: TournamentClassesStats,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/community/:communityId/tournament/:id/stats/tikets',
        component: TournamentTicketsStats,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/admin-dashboard',
        component: AdminDashboard,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/admin-configuration',
        component: AdminConfiguration,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/admin-organization',
        component: AdminOrganisation,
        exact: true,
        show: false,
        protected: true,
    },
    {
        path: '/tournament/:id',
        component: PublicTournamentInfo,
        exact: true,
        show: true,
    },
    {
        path: '/tournament/:id/information',
        component: TournamentScorecardPage,
        exact: true,
        show: true,
    },
    {
        path: '/cadiz-pickleball-open',
        component: SellPage,
        exact: true,
        show: true,
    },
    {
        path: '/',
        component: LandingPage,
        exact: true,
        show: true,
    },
    {
        path: '/tournament/:id/matches',
        component: TournamentMatchList,
        exact: true,
        show: true,
    },
    {
        path: '/sign-up',
        component: SignUp,
        exact: true,
        show: true,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        exact: true,
        show: true,
    },
    {
        path: '*',
        component: NoMatch,
        show: true,
    },
];
