import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';
import {
    setDisplayScheduleState,
    setSelectedItem,
    setCurrentFilters,
    clearScheduleStates,
} from '../../../../../features/schedule/scheduleSlice';
import {
    IMatchScheduleItem,
    IScheduleItem,
    isFreeSlot,
    ITournamentSchedule,
} from '../../../../../types/schedule';
import { pureFilterScheduleBy } from '../schedule.helpers';
import { TagIdsEnum } from '../../../../../constant/navigation';
import { Form, Formik } from 'formik';
import {
    CustomButton,
    CustomSpinner,
    ReactSelectForm,
    RoundedAvatar,
} from '../../../../../components';
import { UnscheduledMatches } from './UnscheduledMatches';
import { UnscheduledReport } from './UnscheduledReport';
import { ScheduleFilterType } from '../../../../../types/enums';
import { ScheduleItem } from './ScheduleItem';
import { BackIcon } from '../../../../../assets/icons/BackIcon';
import { FreeEditToggle } from './FreeEditToggle';
import { createScheduleAction } from '../../../../../features/schedule/scheduleActions';
import { RemoveScheduleButton } from './RemoveScheduleButton';
import { ScheduleItemPositioner } from './ScheduleItemPositioner';
import { ScheduleItemScaleSlider } from './ScheduleItemScaleSlider';
import { Class, Team } from '../../../../../types/tournament';

export const ScheduleLayout = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const dispatch = useAppDispatch();
    const {
        schedule,
        scheduleConstraints,
        displaySchedule,
        selectedItem,
        currentFilters,
        unscheduledMatches,
        isCreatingSchedule,
        loadedSavedSchedule,
    } = useAppSelector(selectSchedule);

    const updateFilterItems = (formikValues: any) => {
        const classes: number[] = formikValues['filter-classes'];
        const teams: number[] = formikValues['filter-teams'];

        const filteredClasses = classes?.map((id) => ({
            type: ScheduleFilterType.CLASS,
            value: id,
        }));

        const filteredTeams = teams?.map((id) => ({
            type: ScheduleFilterType.TEAM,
            value: id,
        }));

        dispatch(setCurrentFilters([...(filteredClasses || []), ...(filteredTeams || [])]));
    };

    const initialFormikValues = {
        'filter-classes': currentFilters
            .filter((filter) => filter.type === ScheduleFilterType.CLASS)
            .map((filter) => filter.value),
        'filter-teams': currentFilters
            .filter((filter) => filter.type === ScheduleFilterType.TEAM)
            .map((filter) => filter.value),
    };

    useEffect(() => {
        if (scheduleConstraints && schedule === null) {
            dispatch(createScheduleAction(t));
        }
    }, [dispatch, schedule, scheduleConstraints, t]);

    useEffect(() => {
        if (schedule) {
            let filteredSchedule: ITournamentSchedule = pureFilterScheduleBy(
                schedule,
                currentFilters
            );
            dispatch(setDisplayScheduleState(filteredSchedule));
        }
    }, [currentFilters, dispatch, schedule]);

    const deselectItemOnClickOutsideScheduleItems = () => {
        !!selectedItem && dispatch(setSelectedItem(null));
    };

    const allClasses = scheduleConstraints?.days.map((day) =>
        day.classOptions.map((option) => option.class)
    );
    const allTeams = schedule?.days.flatMap((day) =>
        day.courts.flatMap((court) =>
            court.matches.flatMap((match: IScheduleItem | IMatchScheduleItem) => {
                if (!isFreeSlot(match)) {
                    return [match.homeTeam, match.awayTeam].filter(
                        (team) => team !== null
                    ) as Team[];
                }
                return [];
            })
        )
    );

    const uniqueClasses = Array.from(new Set(allClasses?.flat().map((c) => c.id)))
        .map((id) => allClasses?.flat().find((c) => c.id === id))
        .filter((c): c is Class => c !== undefined);
    const uniqueTeams = Array.from(new Set(allTeams?.map((t) => t.id)))
        .map((id) => allTeams?.find((t) => t.id === id))
        .filter((t): t is Team => t !== undefined);

    return (
        (isCreatingSchedule && <CustomSpinner key="loading-spinner" />) || (
            <div onClick={deselectItemOnClickOutsideScheduleItems} key="schedule-layout">
                {schedule && scheduleConstraints && (
                    <>
                        <div className="d-flex justify-content-between">
                            <CustomButton
                                btnText=""
                                variant="outline-dark"
                                cb={() => dispatch(clearScheduleStates())}
                            >
                                <BackIcon w={'25'} h={'25'} />
                                {t(prefix + 'backToConstraints')}
                            </CustomButton>
                            {loadedSavedSchedule && <RemoveScheduleButton />}
                        </div>
                        <div>
                            <Formik
                                initialValues={initialFormikValues}
                                onSubmit={updateFilterItems}
                            >
                                {({ errors, handleSubmit }) => (
                                    <Form autoComplete="off" id={TagIdsEnum.SCHEDULE}>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <ReactSelectForm
                                                    isMulti
                                                    name="filter-classes"
                                                    placeholder="Filter by class..."
                                                    options={uniqueClasses?.map((c) => ({
                                                        label: c.name,
                                                        value: c.id,
                                                    }))}
                                                    onChangeCb={handleSubmit}
                                                    formatOptionLabel={(data: any) => (
                                                        <div className="d-flex align-items-center">
                                                            {data.label}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <ReactSelectForm
                                                    isMulti
                                                    name="filter-teams"
                                                    placeholder="Filter by team..."
                                                    options={uniqueTeams?.map((team) => ({
                                                        label: team.name,
                                                        value: team.id,
                                                        logo: team.logo,
                                                    }))}
                                                    onChangeCb={handleSubmit}
                                                    formatOptionLabel={(data: any) => {
                                                        console.log({ data });
                                                        return (
                                                            <div className="d-flex align-items-center">
                                                                <RoundedAvatar
                                                                    avatar={data.logo}
                                                                    alt="Team logo"
                                                                    name="user"
                                                                />
                                                                {data.label}
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="align-self-center">
                                                <ScheduleItemScaleSlider />
                                            </div>
                                            <div className="align-self-center">
                                                <FreeEditToggle />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        {unscheduledMatches.length > 0 && (
                            <div>
                                <h1>{t(prefix + 'unscheduledMatchesTitle')}</h1>
                                <UnscheduledReport />
                                <UnscheduledMatches />
                            </div>
                        )}
                        {displaySchedule &&
                            displaySchedule.days.map((day) => {
                                return (
                                    <div key={'day-' + day.dayKey}>
                                        <h1>Day {day.dayKey + 1}</h1>
                                        <div className="container-fluid border">
                                            <div
                                                key={day.dayKey}
                                                className="row flex-nowrap overflow-auto"
                                            >
                                                {day.courts.map(({ court, matches }) => {
                                                    return (
                                                        <div
                                                            key={'column-' + court.id}
                                                            className="col-sm-auto col-6"
                                                        >
                                                            <h2 key={'court-' + court}>
                                                                {court.name}
                                                            </h2>

                                                            {matches.map((match, matchIndex) => {
                                                                return (
                                                                    <ScheduleItemPositioner
                                                                        key={'match-' + matchIndex}
                                                                        day={
                                                                            scheduleConstraints.days.find(
                                                                                ({ dayKey }) =>
                                                                                    dayKey ===
                                                                                    day.dayKey
                                                                            )!
                                                                        }
                                                                    >
                                                                        <ScheduleItem
                                                                            item={match}
                                                                            key={matchIndex}
                                                                        />
                                                                    </ScheduleItemPositioner>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </>
                )}
            </div>
        )
    );
};
