import React from 'react';
import { SvgIcon } from '../../components';

interface PictureIconProps {
    w?: string;
    h?: string;
    f?: string;
    s?: string;
}

export const PictureIcon = (props: PictureIconProps) => {
    const { w, h, f, s } = props;
    return (
        <SvgIcon width={w || '20'} height={h || '20'} viewBox="0 0 20 20">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19ZM3 19L14 8L19 13M8 6.5C8 7.32843 7.32843 8 6.5 8C5.67157 8 5 7.32843 5 6.5C5 5.67157 5.67157 5 6.5 5C7.32843 5 8 5.67157 8 6.5Z"
                fill={f || 'none'}
                stroke={s || '#2C2F30'} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
