import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetPasswordApi } from '../../apis/auth';
import { setUser } from '../../features/currentUser/currentUserSlice';

import { ChecksAndPasswordFormGroup } from './components/ChecksAndPasswordFormGroup';

import './ResetPassword.scss';

export const ResetPassword = () => {
    const prefix = 'reset-'

    const { t } = useTranslation();    
    const dispatch = useDispatch();
    const history = useHistory();


    const submitSignUp = async (values: {phone: string, password: string, mfaCode: number}) => {

        try {
            const user = await resetPasswordApi(values);
            dispatch(setUser({...user, save: true}));
            history.push('/communities');
        } catch (e) {
            console.log(e);
        }
    }

    return <div className="raqt-reset">
                <div className="raqt-reset__title">{t(prefix + 'title')}</div> 
                <ChecksAndPasswordFormGroup onSubmit={submitSignUp}/>
            </div>
};