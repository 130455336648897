import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'react-bootstrap';
import { useAppSelector } from '../../../../../hooks/redux';
import { ModalBox, RoundedAvatar } from '../../../../../components';
import { ClassTeam } from '../ClassesTag/ClassesTag';
import Select, { MultiValue } from 'react-select';
import { customStyles } from '../../../../../components/modal/MemberModal';
import { Group, Team } from '../../../../../types/tournament';
import { imgOptionTeamLabel } from '../../../../../utils/imgOptionTeamLabel';

export const GroupsModal = (props: ModalProps) => {
    const { t } = useTranslation();

    const prefix = 'creativeDetail-';

    const { currentGroup, setGroups, groups } = useAppSelector(
        (state) => state.modals.data
    );

    const { currentClassId, classCollection } = useAppSelector(
        (state) => state.class
    );
 
    const currentClass = classCollection.find(({ classId }) => classId === currentClassId);

    const [multiSelections, setMultiSelections] = useState<Team[]>([]);

    const teamOptions = currentClass?.teams.map((team: ClassTeam) => imgOptionTeamLabel(team));

    const tournamentGroups = groups.map((group: Group) =>
        group.id === currentGroup.id ? { ...group, teams: multiSelections } : group
    );

    // TO DO: Remove one - ClassTeam or Team because they are the same
    // TO DO: Rewise store structure. We have duplication of data
    const handleSelect = useCallback(
        (e: MultiValue<{ value: string | number; label: string }>) => {
            setMultiSelections(
                e.map((option) => currentClass?.teams.find((team: ClassTeam) => team.id === option.value) as Team)
            );
        },
        [currentClass?.teams]
    );

    const handleSave = () => {
        setGroups('tournamentGroups', tournamentGroups);
        props.onHide && props.onHide();
    };

    return (
        <ModalBox
            {...props}
            title={`${t(prefix + 'addTeam')} ${currentGroup?.name || ''}`}
            handleSave={handleSave}
        >
            <Select
                styles={customStyles}
                isMulti
                name="groupTeams"
                options={teamOptions}
                className="basic-multi-select holder-select"
                classNamePrefix="select"
                // @ts-ignore
                onChange={handleSelect}
                formatOptionLabel={(data: any) => (
                    <div className="d-flex align-items-center">
                        <RoundedAvatar avatar={data.logo} alt="Flag image" name="flag" />
                        {data.label}
                    </div>
                )}
            />
        </ModalBox>
    );
};
