import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { AddIcon } from '../../../../../assets/icons/AddIcon';

interface MatchesLabelItemProps {
    label: string;
    btnTitle: string;
    withBtn?: boolean;
    btnCb: () => void;
}

export const MatchesLabelItem: FC<MatchesLabelItemProps> = (props) => {
    const { btnCb, label, btnTitle, withBtn = true } = props;

    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
                <div className="pr-3">{label}</div>
                {withBtn && (<Button
                    className="py-0 px-1 d-flex justify-content-between align-items-center"
                    style={{
                        borderRadius: '2.25rem',
                    }}
                    variant="outline-dark"
                    onClick={btnCb}
                >
                    <span className="px-2">{btnTitle}</span>
                    <AddIcon />
                </Button>)}
            </div>
        </div>
    );
};
