import { FC, useMemo } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import './index.scss';

interface IValuesWithLabels {
    label: string;
    value: string | number | boolean;
}
interface ButtonTabProps {
    currValue: string | number | boolean;
    items: Array<string> | Array<number> | Array<IValuesWithLabels>;
    name: string;
    disabled?: boolean;
}
// TO DO: REWORK THIS COMPONENT!!!
export const ButtonFormikTab: FC<ButtonTabProps> = (props) => {
    const { items, name, currValue = 'false', disabled } = props;
    const { t } = useTranslation();

    const memoOptions = useMemo(() => {
        const splitName = name.split('.').length > 1 ? name.split('.')[2] : name;

        return items.map((item, i) => {
            if(typeof item === 'string' || typeof item === 'number') {
                const active = items[i] === currValue.toString() || i === currValue;

                return (
                    <div
                        className={`d-flex flex-grow-1 justify-content-center align-items-center py-1 mx-1 btnTabItem ${
                            active ? 'activeBtn' : ''
                        }`}
                        key={i}
                    >
                        <label className="mb-0 w-100 text-center" role="button">
                            <Field className="d-none" type="radio" name={name} value={item} />
                            {splitName === 'rankingType'
                                ? item + 'p'
                                : splitName === 'ratingType'
                                ? item + '%'
                                : splitName === 'isGold'
                                ? item === 'true'
                                    ? t('toggle-gold')
                                    : t('toggle-bronze')
                                : item === 'true'
                                ? t('toggle-yes')
                                : item === 'false'
                                ? t('toggle-no')
                                : item}
                        </label>
                    </div>
                );
            } else {
                const active = item.value.toString() === currValue.toString();

                return (
                    <div
                        className={`d-flex flex-grow-1 justify-content-center align-items-center py-1 mx-1 btnTabItem ${
                            active ? 'activeBtn' : ''
                        }`}
                        key={i}
                    >
                        <label className="mb-0 w-100 text-center" role="button">
                            <Field className="d-none" type="radio" name={name} value={item.value} />
                            {item.label}
                        </label>
                    </div>)
            }

        });
    }, [currValue, items, name, t]);

    return <div className="border py-1 d-flex flex-row tagContainer">
                    {disabled && <div className="raqt__radio-buttons-disabled"></div>}
                    {memoOptions}
            </div>;
};
