import React from 'react';
import { SvgIcon } from '../../components';

interface GroupIconProps {
    w?: string;
    h?: string;
    f?: string;
}

export const GroupIcon = (props: GroupIconProps) => {
    const { w, h, f } = props;
    return (
        <SvgIcon width={w || '50'} height={h || '50'} viewBox="0 0 50 50">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M25 11.4583C26.9339 11.4583 28.7885 12.2266 30.156 13.594C31.5234 14.9615 32.2917 16.8161 32.2917 18.75C32.2917 20.6839 31.5234 22.5385 30.156 23.906C28.7885 25.2734 26.9339 26.0417 25 26.0417C23.0661 26.0417 21.2115 25.2734 19.844 23.906C18.4766 22.5385 17.7083 20.6839 17.7083 18.75C17.7083 16.8161 18.4766 14.9615 19.844 13.594C21.2115 12.2266 23.0661 11.4583 25 11.4583ZM10.4167 16.6667C11.5833 16.6667 12.6667 16.9792 13.6042 17.5417C13.2917 20.5208 14.1667 23.4792 15.9583 25.7917C14.9167 27.7917 12.8333 29.1667 10.4167 29.1667C8.75906 29.1667 7.16935 28.5082 5.99725 27.3361C4.82515 26.164 4.16667 24.5743 4.16667 22.9167C4.16667 21.2591 4.82515 19.6693 5.99725 18.4972C7.16935 17.3251 8.75906 16.6667 10.4167 16.6667ZM39.5833 16.6667C41.2409 16.6667 42.8306 17.3251 44.0028 18.4972C45.1749 19.6693 45.8333 21.2591 45.8333 22.9167C45.8333 24.5743 45.1749 26.164 44.0028 27.3361C42.8306 28.5082 41.2409 29.1667 39.5833 29.1667C37.1667 29.1667 35.0833 27.7917 34.0417 25.7917C35.8333 23.4792 36.7083 20.5208 36.3958 17.5417C37.3333 16.9792 38.4167 16.6667 39.5833 16.6667ZM11.4583 38.0208C11.4583 33.7083 17.5208 30.2083 25 30.2083C32.4792 30.2083 38.5417 33.7083 38.5417 38.0208V41.6667H11.4583V38.0208ZM0 41.6667V38.5417C0 35.6458 3.9375 33.2083 9.27083 32.5C8.04167 33.9167 7.29167 35.875 7.29167 38.0208V41.6667H0ZM50 41.6667H42.7083V38.0208C42.7083 35.875 41.9583 33.9167 40.7292 32.5C46.0625 33.2083 50 35.6458 50 38.5417V41.6667Z"
                fill={f || '#2C2F30'}
            />
        </SvgIcon>
    );
};
