import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import './app/localization/localization';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './apis/api.config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </Provider>
);
