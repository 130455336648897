import React from 'react';
import { Spinner } from 'react-bootstrap';

export const CustomSpinner = () => {
    return (
        <div className="d-flex justify-content-center p-5">
            <Spinner animation="border" />
        </div>
    );
};
