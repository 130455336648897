import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { selectSchedule } from '../../../../features/schedule/scheduleSelectors';
import { updateScheduleConstraintDays } from '../../../../features/schedule/scheduleSlice';
import { TagIdsEnum } from '../../../../constant/navigation';
import {
    generateInitialScheduleValues,
    generateScheduleSchema,
    getClassOptionsFromFormikInput,
    getTimeDateFromFormikInput,
} from './schedule.helpers';
import { ScheduleDayConstraints } from './FormComponents/ScheduleDayConstraints';
import { CustomButton } from '../../../../components';
import { ScheduleLayout } from './ScheduleComponents/ScheduleLayout';
import { OptInError } from './FormComponents/OptInCheckBoxArray';

export const ScheduleTag = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const dispatch = useAppDispatch();
    const { scheduleConstraints, scheduleConstraintsGiven, tournamentInfo } =
        useAppSelector(selectSchedule);

    const initialFormikValues =
        scheduleConstraints && generateInitialScheduleValues(scheduleConstraints!);
    const scheduleSchema =
        scheduleConstraints?.days &&
        Yup.object().shape(generateScheduleSchema(scheduleConstraints?.days!, t));

    const handleSubmit = (values: any) => {
        console.log(values);
        if (scheduleConstraints) {
            dispatch(
                updateScheduleConstraintDays(
                    scheduleConstraints.days.map((day) => {
                        return {
                            dayKey: day.dayKey,
                            startTime: getTimeDateFromFormikInput(
                                values['startOfDay-' + day.dayKey],
                                day.startTime
                            ),
                            endTime: getTimeDateFromFormikInput(
                                values['endOfDay-' + day.dayKey],
                                day.endTime
                            ),
                            defaultMatchLength: values['defaultMatchLength-' + day.dayKey],
                            defaultPauseLength: values['defaultPauseLength-' + day.dayKey],
                            classes: tournamentInfo!.classes.filter((clazz) => {
                                if (values['all-class-' + day.dayKey]) {
                                    return true;
                                } else {
                                    return (values['class-day-' + day.dayKey] as string[]).includes(
                                        clazz.id.toString()
                                    );
                                }
                            }),
                            classOptions: getClassOptionsFromFormikInput(
                                values,
                                day,
                                scheduleConstraints.tournamentCourts
                            ),
                            courts: tournamentInfo!.courts.filter((court) => {
                                if (values['all-court-' + day.dayKey]) {
                                    return true;
                                } else {
                                    return (values['court-day-' + day.dayKey] as string[]).includes(
                                        court.id.toString()
                                    );
                                }
                            }),
                        };
                    })
                )
            );
        }
    };

    return (
        <div className="container-auto">
            {scheduleConstraintsGiven ? (
                <ScheduleLayout />
            ) : (
                <Formik
                    initialValues={initialFormikValues}
                    validationSchema={scheduleSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors }) => (
                        <Form autoComplete="off" id={TagIdsEnum.SCHEDULE}>
                            {scheduleConstraints?.days?.length &&
                                scheduleConstraints.days.map((day) => (
                                    <div key={`constraints-day-${day.dayKey}`}>
                                        <ScheduleDayConstraints
                                            day={day}
                                            courts={tournamentInfo!.courts}
                                            classes={tournamentInfo!.classes}
                                        />
                                    </div>
                                ))}
                            <OptInError errors={errors} />
                            <div className="row">
                                <div className="col-sm-2 p-4">
                                    <CustomButton
                                        btnText={t(prefix + 'scheduleBtn')}
                                        type="submit"
                                        variant="outline-dark"
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};
