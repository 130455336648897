import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { CustomButton } from "../../../../../components";

import { IMatch } from "../PlayTag/PlayTag";
import { TournamentPrintCard } from "../TournamentPrintCard";

import './ScoreCardDownload.scss';

interface ScoreCardDownloadProps {
    match: IMatch;
    matchId: string;
}

export const ScoreCardDownload:FC<ScoreCardDownloadProps> = (attr) => {
    const buttonPrefix = 'button-';

    const { t } = useTranslation();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef?.current || null,
      bodyClass: 'score-card-download__print-page'
    });

    return (<div className="score-card-download">
                <CustomButton
                        className="d-flex justify-content-evenly align-items-center"
                        variant="outline-dark"
                        btnText={t(
                            buttonPrefix +
                                'scoreCard'
                        )}
                        cb={handlePrint}
                    />
                <div className="score-card-download__hidden-content">
                    <TournamentPrintCard ref={componentRef} {...attr}/>
                </div>
            </div>)
}