import {
    IFreeSlotItem,
    IMatchScheduleItem,
    isFreeSlot,
    isSameSlot,
    isScheduled,
} from '../../../../../types/schedule';

export const styleWhenSomeItemIsSelected = (
    item: IMatchScheduleItem | IFreeSlotItem,
    selectedItem: IMatchScheduleItem | IFreeSlotItem | null,
    isFreeEditMode: boolean
) => {
    if (selectedItem === null) {
        return '';
    }

    const isSelectedItem =
        selectedItem !== null &&
        (isFreeSlot(selectedItem)
            ? isFreeSlot(item)
                ? isSameSlot(item, selectedItem)
                : false
            : !isFreeSlot(item)
            ? item.id === selectedItem.id
            : false);

    const itemIsScheduled = isScheduled(item);
    const selectedItemIsScheduled = isScheduled(selectedItem);

    const isDifferentTimeSlotThanSelectedItem =
        itemIsScheduled &&
        selectedItem !== null &&
        selectedItemIsScheduled &&
        item.scheduleDate?.toString() !== selectedItem.scheduleDate?.toString();

    if (isSelectedItem) {
        return 'border-primary shadow-lg';
    } else if (isFreeEditMode) {
        if (
            (!selectedItemIsScheduled && !isFreeSlot(item)) ||
            (selectedItemIsScheduled && !itemIsScheduled)
        ) {
            return 'opacity-25';
        }
        return '';
    } else {
        if (isDifferentTimeSlotThanSelectedItem) {
            return 'opacity-25';
        }
        return '';
    }
};

export const getItemCursorType = (
    item: IMatchScheduleItem | IFreeSlotItem,
    selectedItem: IMatchScheduleItem | IFreeSlotItem | null,
    isFreeEditMode: boolean
) => {
    const noItemSelected = selectedItem === null;

    const itemIsScheduled = isScheduled(item);
    const selectedItemIsScheduled = !noItemSelected && isScheduled(selectedItem);

    const itemSelectable =
        itemIsScheduled &&
        !noItemSelected &&
        selectedItemIsScheduled &&
        item.scheduleDate?.toString() === selectedItem.scheduleDate?.toString();

    if (!isFreeEditMode) {
        if ((noItemSelected && itemIsScheduled) || itemSelectable) {
            return 'pointer';
        } else {
            return 'not-allowed';
        }
    } else {
        if (noItemSelected) {
            return 'pointer';
        } else if (
            (selectedItemIsScheduled && !itemIsScheduled) ||
            (!selectedItemIsScheduled && !isFreeSlot(item))
        ) {
            return 'not-allowed';
        }
        return 'pointer';
    }
};
