import { AppDispatch } from '../../app/store';
import { userFetching, userFetchingError, userFetchingSuccess } from './userSlice';
import { fetchUsersData } from '../../apis/tournament';

export const fetchUsers =
    (name: string = '') =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(userFetching());
            const data = await fetchUsersData(name);
            dispatch(userFetchingSuccess(data));
        } catch (e) {
            const error = e as Error;
            dispatch(userFetchingError(error.message));
        }
    };
