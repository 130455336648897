import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton, EditingForm, FormTextField } from '../../../../../components';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { Button } from 'react-bootstrap';
import { ArrayHelpers, FieldArray } from 'formik';
import { DataCourts } from '../LocationTag/LocationTag';
import uniqId from 'uniqid';

import './CourtSection.scss';

interface CourtSectionProps {
    data: {
        courtAmount: number;
        dataCourts: Array<DataCourts>;
    };
}

export const CourtSection: FC<CourtSectionProps> = ({ data }) => {
    const { t } = useTranslation();

    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';
    const buttonPrefix = 'button-';

    return (
        <FieldArray name="courts">
            {(arg: ArrayHelpers) => (
                <div className="container-fluid px-0">
                    <div className="my-3">
                        <h4 className="font-weight-bold">{t(prefix + 'courts')}</h4>
                    </div>
                    <div className="w-100 d-flex justify-content-between flex-wrap align-items-center">
                        <div className="courtInputContainer">
                            <FormTextField
                                type="text"
                                placeholder={t(inputPrefix + 'placeholderAmountCourts')}
                                id="courtCount"
                                label={t(prefix + 'numberCourts')}
                                name="courtCount"
                            />
                        </div>
                        <div className="courtBtnContainer flex-grow-1 ml-1 ml-sm-4">
                            <CustomButton
                                variant="outline-dark"
                                btnText={t(buttonPrefix + 'generate')}
                                disabled={data.dataCourts.length >= 100}
                                cb={() => {
                                    const index = data.dataCourts.length;
                                    const maxAmount = 100 - index;

                                    for (let i = 0; i < (Math.min(data.courtAmount, maxAmount)); i++) {
                                        arg.push({
                                            id: uniqId(),
                                            name: 'court ' + (!index ? i + 1 : index + (i + 1)),
                                            isEdit: true,
                                        });
                                    }
                                }}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <AddIcon />
                            </CustomButton>
                        </div>
                    </div>
                    <div className="smallCourtBtn my-3">
                        <Button
                            className="py-2 px-3 badge btn-dark d-flex justify-content-between align-items-center"
                            style={{ borderRadius: '2.25rem' }}
                            variant="outline-dark"
                            onClick={() => {
                                arg.push({
                                    id: uniqId(),
                                    name: 'court ' + (data.dataCourts.length + 1),
                                    isEdit: true,
                                });
                            }}
                        >
                            <span className="px-2">{t(buttonPrefix + 'addCourt')}</span>
                            <AddIcon f="#ffffff" />
                        </Button>
                    </div>

                    <div className="d-flex flex-wrap">
                        {data.dataCourts.length ? (
                            data.dataCourts.map((court, i) => (
                                <div key={court.id} className="courtInputContainer">
                                    <EditingForm
                                        data={data.dataCourts}
                                        item={court}
                                        helpers={arg}
                                        name={`courts.${i}.name`}
                                    />
                                </div>
                            ))
                        ) : (
                            <p className="fw-lighter">{t(prefix + 'noCourts')}</p>
                        )}
                    </div>
                </div>
            )}
        </FieldArray>
    );
};
