import { FC } from 'react';
import classnames from 'classnames';

import { IStandingMatch } from '../../TournamentScorecardPage.types';

import './TournamentScorecardPageMatchScore.scss';

export const TournamentScorecardPageMatchScore:FC<IStandingMatch> = ({id, matchNumber, homeTeam, awayTeam}) => 
    (<div className="tournament-scorecard-page-match-score">
        <div className="tournament-scorecard-page-match-score__sort-key">{ matchNumber }</div>
        <div className="tournament-scorecard-page-match-score__match-info">
            <div className="tournament-scorecard-page-match-score__team">
                <div className="tournament-scorecard-page-match-score__team-info">
                    <div className="tournament-scorecard-page-match-score__team-seed">{homeTeam?.id || ' '}</div>
                    <div className="tournament-scorecard-page-match-score__team-name">{homeTeam?.name || ' '}</div>
                </div>
                <div className={classnames(
                        'tournament-scorecard-page-match-score__team-score',
                        {'tournament-scorecard-page-match-score__team-score_winner': homeTeam?.result > awayTeam?.result}
                    )}>{homeTeam?.result || 0}</div>
            </div>
            <div className="tournament-scorecard-page-match-score__team">
                <div className="tournament-scorecard-page-match-score__team-info">
                    <div className="tournament-scorecard-page-match-score__team-seed">{awayTeam?.id || ' '}</div>
                    <div className="tournament-scorecard-page-match-score__team-name">{awayTeam?.name || ' '}</div>
                </div>
                <div className={classnames(
                        'tournament-scorecard-page-match-score__team-score',
                        {'tournament-scorecard-page-match-score__team-score_winner': homeTeam?.result < awayTeam?.result}
                    )}>{awayTeam?.result || 0}</div>
            </div>
        </div>
    </div>);