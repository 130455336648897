import { TFunction } from 'react-i18next';
import {
    createTournamentSchedule,
    fetchTournamentWithClassAndCourtListByTournamentId,
    getScheduleConstraints,
    getTournamentSchedule,
    publishTournamentSchedule,
    removeTournamentSchedule,
    updateTournamentSchedule,
} from '../../apis/tournament';
import { AppDispatch, RootState } from '../../app/store';
import { Notification } from '../../hooks/useNotification';
import { TypeError } from '../../types/enums';
import {
    IDaySchedule,
    IFreeSlotItem,
    IMatchScheduleItem,
    ITournamentSchedule,
    isFreeSlot,
    isSameSlot,
    isScheduled,
} from '../../types/schedule';
import { selectSchedule } from './scheduleSelectors';
import {
    initialDataFetchError,
    initialDataFetchSuccess,
    initialDataFetching,
    updateSchedule,
    updateScheduleSuccess,
    updateScheduleError,
    setSelectedItem,
    setScheduleState,
    setUnscheduledMatchesState,
    creatingSchedule,
    creatingScheduleError,
    creatingScheduleSuccess,
    setScheduleConstraintsGiven,
    setTournamentInfo,
    setScheduleConstraints,
    restoreInitialState,
    setLoadedSavedSchedule,
    publishSchedule,
    publishScheduleSuccess,
    publishScheduleError,
    setScheduleStateFromSource,
} from './scheduleSlice';
import { generateDefaultSchedulingConstraints } from './schedule.helpers';

export const fetchDataForSchedule =
    (tournamentId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const { tournamentInfo } = selectSchedule(getState());

        try {
            if (tournamentInfo === null || tournamentInfo.id !== tournamentId) {
                dispatch(restoreInitialState());
                dispatch(initialDataFetching());
                const tournamentInfo = await fetchTournamentInfo(tournamentId);
                dispatch(setTournamentInfo(tournamentInfo));

                if (tournamentInfo.isScheduled) {
                    const constraints = await getScheduleConstraints(tournamentInfo.id);
                    dispatch(setScheduleConstraints(constraints));
                    dispatch(setScheduleConstraintsGiven(true));

                    const { schedule, unscheduled } = await getTournamentSchedule(
                        tournamentInfo.id
                    );
                    dispatch(setScheduleStateFromSource(schedule));
                    dispatch(setUnscheduledMatchesState(unscheduled));

                    dispatch(setLoadedSavedSchedule());
                } else if (tournamentInfo.startDate && tournamentInfo.endDate) {
                    const constraints = generateDefaultSchedulingConstraints(tournamentInfo);
                    dispatch(setScheduleConstraints(constraints));
                }

                dispatch(initialDataFetchSuccess());
            }
        } catch (e) {
            const err = e as Error;
            dispatch(initialDataFetchError(err.message));
        }
    };

const fetchTournamentInfo = async (id: number) => {
    return await fetchTournamentWithClassAndCourtListByTournamentId(id);
};

export const createScheduleAction =
    (t: TFunction<'translation', undefined>) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const prefix = 'creativeDetail-';

        dispatch(creatingSchedule());
        try {
            const { scheduleConstraints, tournamentInfo } = selectSchedule(getState());
            if (!!tournamentInfo && !!scheduleConstraints) {
                const { schedule, unscheduled } = await createTournamentSchedule(
                    tournamentInfo.id,
                    scheduleConstraints
                );

                dispatch(setScheduleStateFromSource(schedule));
                dispatch(setUnscheduledMatchesState(unscheduled));

                if (unscheduled.length > 0) {
                    Notification(TypeError.error, t(prefix + 'scheduleAllMatchesError'));
                } else {
                    Notification(TypeError.success, t(prefix + 'scheduleAllMatchesSuccess'));
                }
                dispatch(creatingScheduleSuccess());
            } else {
                throw new Error('Must fill in tournament info and schedule constraints');
            }
        } catch (e) {
            const err = e as Error;
            dispatch(creatingScheduleError(err.message));
        }
    };

export const removeSchedule =
    (t: TFunction<'translation', undefined>) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const prefix = 'creativeDetail-';

        const { tournamentInfo } = selectSchedule(getState());
        const id = tournamentInfo!.id;

        await removeTournamentSchedule(id)
            .then(() => dispatch(restoreInitialState()))
            .then(() => dispatch(fetchDataForSchedule(id)))
            .then(() => Notification(TypeError.info, t(prefix + 'scheduleRemovedInfo')))
            .catch((err) => Notification(TypeError.error, err.message));
    };

export const updateTournamentScheduleAction =
    (schedule: ITournamentSchedule, t: TFunction<'translation', undefined>) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(updateSchedule());
        const { tournamentInfo, scheduleConstraints, unscheduledMatches } = selectSchedule(
            getState()
        );

        const matches = schedule.days.flatMap((day) => {
            return day.courts
                .flatMap((courtSchedule) => {
                    return courtSchedule.matches;
                })
                .filter((match) => !isFreeSlot(match) && isScheduled(match))
                .map((match) => {
                    return {
                        id: (match as IMatchScheduleItem).id,
                        scheduleDate: new Date(match.scheduleDate!),
                        court: match.court,
                    };
                });
        });
        try {
            if (unscheduledMatches.length > 0) {
                throw new Error(t('error-saveOnUnscheduled'));
            }
            await updateTournamentSchedule(tournamentInfo!.id, scheduleConstraints!, matches);
            dispatch(setLoadedSavedSchedule());
            dispatch(updateScheduleSuccess());
        } catch (e) {
            const err = e as Error;
            dispatch(updateScheduleError(err.message));
        }
    };

export const publishTournamentScheduleAction =
    (schedule: ITournamentSchedule, t: TFunction<'translation', undefined>) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(publishSchedule());
        const { tournamentInfo, scheduleConstraints, unscheduledMatches } = selectSchedule(
            getState()
        );

        const matches = schedule.days.flatMap((day) => {
            return day.courts
                .flatMap((courtSchedule) => {
                    return courtSchedule.matches;
                })
                .filter((match) => !isFreeSlot(match) && isScheduled(match))
                .map((match) => {
                    return {
                        id: (match as IMatchScheduleItem).id,
                        scheduleDate: new Date(match.scheduleDate!),
                        court: match.court,
                    };
                });
        });

        try {
            if (unscheduledMatches.length > 0) {
                throw new Error(t('error-publishSchedule'));
            }
            const { schedule, unscheduled } = await publishTournamentSchedule(
                tournamentInfo!.id,
                scheduleConstraints!,
                matches
            );
            dispatch(setScheduleStateFromSource(schedule));
            dispatch(publishScheduleSuccess());
            dispatch(setUnscheduledMatchesState(unscheduled));

            dispatch(setLoadedSavedSchedule());
        } catch (e) {
            const err = e as Error;
            dispatch(publishScheduleError(err.message));
        }
    };

export const swapScheduleItems =
    (
        item: IMatchScheduleItem | IFreeSlotItem,
        storedItem: IMatchScheduleItem | IFreeSlotItem,
        t: TFunction<'translation', undefined>
    ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const { schedule } = selectSchedule(getState());
        const redundantSwap =
            (isFreeSlot(item) && isFreeSlot(storedItem)) ||
            (!isFreeSlot(item) && !isFreeSlot(storedItem) && item.id === storedItem.id);

        if (!redundantSwap) {
            // Only called if schedule items are clicked, if they are clicked, the schedule must exist.
            const swappedSchedule = { ...schedule! };
            swappedSchedule.days = swappedSchedule.days.map((day) => {
                let newDay: IDaySchedule = {
                    dayKey: day.dayKey,
                    courts: day.courts.map(({ ...courtSchedule }) => {
                        courtSchedule.matches = courtSchedule.matches.map((match) => {
                            if (!isFreeSlot(match)) {
                                if (!isFreeSlot(item) && match.id === item.id) {
                                    return {
                                        ...storedItem,
                                        scheduleDate: item.scheduleDate!,
                                        court: item.court,
                                    };
                                } else if (!isFreeSlot(storedItem) && match.id === storedItem.id) {
                                    return {
                                        ...item!,
                                        scheduleDate: storedItem.scheduleDate!,
                                        court: storedItem.court,
                                    };
                                }
                            } else {
                                if (isFreeSlot(storedItem) && isSameSlot(match, storedItem)) {
                                    return {
                                        ...item!,
                                        scheduleDate: storedItem.scheduleDate,
                                        court: storedItem.court,
                                    };
                                } else if (isFreeSlot(item) && isSameSlot(match, item)) {
                                    return {
                                        ...storedItem,
                                        scheduleDate: item.scheduleDate,
                                        court: item.court,
                                    };
                                }
                            }

                            return match;
                        });
                        return courtSchedule;
                    }),
                };
                return newDay;
            });

            Notification(TypeError.info, swapMessage(item, storedItem, t));

            dispatch(setScheduleState(swappedSchedule));

            dispatch(setSelectedItem(null));
        }
    };

export const addUnscheduledItemToSchedule =
    (
        freeSlot: IFreeSlotItem,
        unscheduledItem: IMatchScheduleItem,
        t: TFunction<'translation', undefined>
    ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const { schedule, unscheduledMatches } = selectSchedule(getState());

        // Only called if schedule items are clicked, if they are clicked, the schedule must exist.
        const swappedSchedule = { ...schedule! };
        swappedSchedule.days = swappedSchedule.days.map((day) => {
            let newDay: IDaySchedule = {
                dayKey: day.dayKey,
                courts: day.courts.map(({ ...courtSchedule }) => {
                    courtSchedule.matches = courtSchedule.matches.map((match) => {
                        if (isFreeSlot(match)) {
                            if (isSameSlot(match, freeSlot)) {
                                return {
                                    ...unscheduledItem,
                                    scheduleDate: freeSlot.scheduleDate,
                                    court: freeSlot.court,
                                };
                            }
                        }
                        return match;
                    });
                    return courtSchedule;
                }),
            };
            return newDay;
        });

        Notification(TypeError.info, swapMessage(unscheduledItem, freeSlot, t));

        dispatch(
            setUnscheduledMatchesState(
                unscheduledMatches.filter(({ id }) => id !== unscheduledItem.id)
            )
        );

        dispatch(setScheduleState(swappedSchedule));

        dispatch(setSelectedItem(null));
    };

const swapMessage = (
    item: IMatchScheduleItem | IFreeSlotItem,
    storedItem: IMatchScheduleItem | IFreeSlotItem,
    t: TFunction<'translation', undefined>
) => {
    if (!isFreeSlot(item) && !isFreeSlot(storedItem)) {
        return (
            storedItem.matchId +
            ' ' +
            t('creativeDetail-itemMovedTo') +
            ' ' +
            item.court.name +
            ', ' +
            item.matchId +
            ' ' +
            t('creativeDetail-itemMovedTo') +
            ' ' +
            storedItem.court.name
        );
    } else {
        return !isFreeSlot(item)
            ? item.matchId + ' ' + t('creativeDetail-itemMovedTo') + ' ' + storedItem.court.name
            : (storedItem as IMatchScheduleItem).matchId +
                  ' ' +
                  t('creativeDetail-itemMovedTo') +
                  ' ' +
                  item.court.name;
    }
};
