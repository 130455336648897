import { FC } from 'react';
import classnames from 'classnames';

import { RoundedAvatar } from '../../../../components';
import { User } from '../../../../types/tournament';
import { formatName } from './TournamentScorecardPageMatchModalTeam.helpers';

import './TournamentScorecardPageMatchModalTeam.scss';

interface TournamentScorecardPageMatchModalTeamProps {
    player1: User | null;
    player2: User | null;
    results?: number[];
    showGameHeader?: boolean;
    teamNumber: number;
}

export const TournamentScorecardPageMatchModalTeam: FC<TournamentScorecardPageMatchModalTeamProps> = ({player1, player2, results = [], showGameHeader, teamNumber}) => (  
<div className="tournament-scorecard-page-match-modal-team">
    <div className={classnames(
        'tournament-scorecard-page-match-modal-team__team-holder',
        {'tournament-scorecard-page-match-modal-team__team-holder-home': showGameHeader}
        )}>
        <div className="tournament-scorecard-page-match-modal-team__avatars">
            <div>
                <RoundedAvatar
                    noMargin={true}
                    height="50"
                    width="50" 
                    avatar={player1?.avatar || null} 
                    alt="Home player avatar" 
                    name="user"
                /> 
            </div>
            <div className="tournament-scorecard-page-match-modal-team__avatars_2">
                {player2 && (<RoundedAvatar 
                    noMargin={true}
                    height="50"
                    width="50" 
                    avatar={player2.avatar || null} 
                    alt="Home player avatar" 
                    name="user"
                />)}
            </div>
        </div>
        <div className="tournament-scorecard-page-match-modal-team__team-info-box">
            <div className="tournament-scorecard-page-match-modal-team__team-number">Team {teamNumber}</div>
            <div className="tournament-scorecard-page-match-modal-team__team-player-name">{formatName(player1?.firstName, player1?.lastName)}</div>
            <div className="tournament-scorecard-page-match-modal-team__team-player-name">{formatName(player2?.firstName, player2?.lastName)}</div>
        </div>
    </div>
    <div className="tournament-scorecard-page-match-modal-team__score-holder">
        {((results.length && results) || ['']).map((item, index) => (<div className="tournament-scorecard-page-match-modal-team__score">
            {showGameHeader && <div className="tournament-scorecard-page-match-modal-team__score-game">G{index + 1}</div>}
            <div className="tournament-scorecard-page-match-modal-team__score-number">
                {item}
            </div>
        </div>))}
    </div>
</div>)