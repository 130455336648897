import { FC } from 'react';

interface DontKnowPlayerPlaceholderProps {
    placeholder: string | null;
}


export const DontKnowPlayerPlaceholder: FC<DontKnowPlayerPlaceholderProps> = ({placeholder}) => (
    <div className="col-md-12 col-lg-8 order-3 order-lg-0">
        {placeholder && (<div className="d-flex h-100 align-items-center">
            <div
                style={{ minHeight: '43px', minWidth: '91px' }}
                className="d-flex justify-content-lg-center justify-content-between align-items-center mr-2 border bg-white my-2 br-16"
            >
                <div className="d-flex flex-grow-1">
                    <span className="px-2">{placeholder}</span>
                </div>
            </div>
        </div>  )}
    </div>
);