import { FC } from 'react';
import classnames from 'classnames';

import { ItemWrapper } from '../blockWrappers/ItemWrapper/ItemWrapper';

import './RaqtTable.scss'

export interface IRaqtTableColumnConfig {
    label: string;
    key: string;
    columnSize?: 'small' | 'standard' | 'big';
    position?: 'left' | 'center' | 'right';   
}

export interface IRaqtTableConfig {
    columns: IRaqtTableColumnConfig[];
    blankHead?: boolean;
}

interface RaqtTableProps {
    config: IRaqtTableConfig;
    data: Array<{
        [key: string]: any;
    }>
}

const prepareHtmlClass = (v: IRaqtTableColumnConfig) => ({
    'raqt-table__column_big': v.columnSize === 'big',
    'raqt-table__column_standard':!v.columnSize || v.columnSize === 'standard',
    'raqt-table__column_small': v.columnSize  === 'small',
    'raqt-table__column_center': !v.position || v.position === 'center',
    'raqt-table__column_left': v.position === 'left',
    'raqt-table__column_right': v.position === 'right',
})

export const RaqtTable: FC<RaqtTableProps> = ({config, data}) => (<div className="raqt-table">
<div className="raqt-table__wrapper">
    <div className={classnames('raqt-table__head', {'raqt-table__blank-head': config.blankHead})}>
        <div className="raqt-table__row-wrapper">
            {config.columns.map((v, i) => <div key={`table-head-column-${i}`} className={
                    classnames(`raqt-table__column-${ i }`, prepareHtmlClass(v))
                }>
                    <ItemWrapper className="raqt-table__head-item">
                        {v.label}
                    </ItemWrapper>
                </div>)
            }
        </div>
    </div>
    <div className="raqt-table__body">
        {data.map((item, index) => (<div key={`table-body-row-${index}`} className={classnames('raqt-table__row-wrapper', `raqt-table__row-${ index }`)}>
            {config.columns.map((v, i) => <div key={`table-body-column-${i}`} className={
                    classnames(`raqt-table__column-${ i }`, {
                        ...prepareHtmlClass(v),
                        'raqt-table__column-falsy-value': !item[v.key] && item[v.key] !== 0,
                        'raqt-table__column-truly-value': !!item[v.key] || item[v.key] === 0
                    })
                }>
                    <ItemWrapper  white={true}>
                        {item[v.key]}
                    </ItemWrapper>
                </div>)}
        </div>))}
    </div>
</div>
</div>);