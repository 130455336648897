import { useLocation } from "react-router-dom";

export const NoMatch: React.FC<null> = () => {
    let location = useLocation();
  
    return (
      <div>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    );
};