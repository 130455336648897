import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemWrapper } from '../../../../../components';
import { selectCurrentClass } from '../../../../../features/class/classSelectors';
import { useAppSelector } from '../../../../../hooks/redux';
import { TournamentCardPlayerHolder } from '../TournamentCardPlayerHolder';

import './TournamentCardTeamSection.scss';

interface TournamentCardTeamSectionProps {
    team: number;
    player1?: string;
    player2?: string;
}

export const TournamentCardTeamSection: FC<TournamentCardTeamSectionProps> = ({team, player1 = '', player2 = ''}) => {
    const prefix = 'printCardTeam-'

    const { t } = useTranslation();
    const { name: className } = useAppSelector(selectCurrentClass) || {name: ''};

    return (<div className="tournament-card-team-section">
        <div className="tournament-card-team-section__side tournament-card-team-section__side_left">
            <div className="tournament-card-team-section__event">
                <div>{t(prefix + 'event')}</div>
                <ItemWrapper>
                    {className}
                </ItemWrapper>
            </div>
            <TournamentCardPlayerHolder team={team} player="1" teamPlayer={player1}/>
        </div>
        <div className="tournament-card-team-section__side tournament-card-team-section__side_right">
            <div>
                <div className="tournament-card-team-section__title">
                    {t(prefix + 'format')}
                </div>
                <div className="tournament-card-team-section__format-holder">
                    <ItemWrapper>
                        {t(prefix + 'formatType1')}
                    </ItemWrapper>
                    <ItemWrapper>
                        {t(prefix + 'formatType2')}
                    </ItemWrapper>
                    <ItemWrapper>
                        {t(prefix + 'formatType3')}
                    </ItemWrapper>
                    <ItemWrapper>
                        {t(prefix + 'formatType4')}
                    </ItemWrapper>
                </div>
            </div>
            <TournamentCardPlayerHolder team={team} player="2" teamPlayer={player2}/>
        </div>
    </div>);
};