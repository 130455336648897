import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IOrganization, IOrganizationStats } from './../../apis/organizations';

interface IOrganizationsSlice {
    initialLoading: boolean;
    stats: IOrganizationStats | null;
    list: IOrganization[] | null;   
}

const initialState: IOrganizationsSlice = {
    initialLoading: false,
    list: null,
    stats: null
};

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        setOrganizationsList: (state, action: PayloadAction<IOrganization[]>) => {
            return {
                ...state,
                list: action.payload
            };
        },

        setStats: (state, action: PayloadAction<IOrganizationStats>) => {
            return {
                ...state,
                stats: action.payload
            };
        }, 

        clearStats: (state) => {
            return {
                ...state,
                stats: null
            };
        },

        startLoading: (state) => {
            return {
                ...state,
                initialLoading: true,
            }
        },

        stopLoading: (state) => {
            return {
                ...state,
                initialLoading: false,
            }
        },
        updateOrganizationsList: (state, action: PayloadAction<IOrganization>) => {
            const newList = state.list?.map(item => {
                if(item.id === action.payload.id) return action.payload;

                return item;
            });
            return {
                ...state,
                list: newList || [],
            };
        },
    },
});

export const { setOrganizationsList, startLoading, stopLoading, setStats, clearStats, updateOrganizationsList } = organizationsSlice.actions;

export default organizationsSlice.reducer;
