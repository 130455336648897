import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { fetchTicketsInfoAPI, IRaqtInvoice, ITicket, ITicketSummery } from '../../apis/tournament';
import { BackIcon } from '../../assets/icons/BackIcon';
import { ItemWrapper, RaqtTable, Switcher } from '../../components';
import { IRaqtTableConfig } from '../../components/table/RaqtTable';
import { selectTournamentData } from '../../features/tournament/tournamentSelectors';
import { useAppSelector } from '../../hooks/redux';
import { getDateWithTime } from '../../utils/getStringDate';

import './TournamentTicketsStats.scss';

enum SubTiketPagesEnum {
    TICKETS,
    SUMMERY
}

export const TournamentTicketsStats = () => {
    const prefix = 'tournamentStatsTickets-';

    const { t } = useTranslation();
    const [TicketList, setTicketList] = useState<ITicket[]>([]);
    const currentTournamet = useAppSelector(selectTournamentData);
    const [page, setPage] = useState<SubTiketPagesEnum>(SubTiketPagesEnum.TICKETS);
    const [raqtInfo, setRaqtInfo] = useState({} as IRaqtInvoice);
    const [userInvoice, setUserInvoice] = useState([] as ITicketSummery[])
    // const [isLoading, setIsloading] = useState(false);
    const { id , communityId } = useParams<{ id: string, communityId: string }>();

    const classList = [
        {label: t(prefix + 'allTickets'), value: SubTiketPagesEnum.TICKETS},
        {label: t(prefix + 'summary'), value: SubTiketPagesEnum.SUMMERY},
    ];

    const getTickets = async () => {
        // setIsloading(true);

        try {
            const {
                ticketsInfo,
                ticketsSummery,
                raqtDetails,
            } = await fetchTicketsInfoAPI(+id);

            const Tickets = [
                ticketsInfo.reduce((acc, item) => {
                    acc.vat += +item.vat;
                    acc.price += +item.price;
                    if(!acc.currency) {
                        acc.currency = item.currency;
                    }

                    return acc;
                }, {
                    currency: '',
                    date: null,
                    paymentId: null,
                    class: t(prefix + 'total'),
                    price: 0,
                    purchasedTicket: null,
                    vat: 0,
                })as unknown as ITicket,
                ...ticketsInfo
            ].map((item, index) => ({
                date: (index && getDateWithTime(item.date)) || '',
                paymentId: item.paymentId,
                class: item.class,
                price: `${ item.currency } ${item.price}`.toLocaleUpperCase(),
                purchasedTicket: item.purchasedTicket,
                vat: `${ item.currency } ${ item.vat }`.toLocaleUpperCase(),
            })) as unknown as ITicket[];

            setTicketList(Tickets);
            setRaqtInfo(raqtDetails);
            setUserInvoice(ticketsSummery);
        } catch (e) {
            setTicketList([]);
        }
    }


    const tableConfig: IRaqtTableConfig = {
        blankHead: true,
        columns: [
            {
                label: t(prefix + 'class'),
                key: 'class',
                position: 'left',
            },
            {
                label: t(prefix + 'purchasedTicket'),
                key: 'purchasedTicket',
                position: 'left',
                columnSize: 'big'
            },
            {
                label: t(prefix + 'price'),
                key: 'price',
                position: 'right',
                columnSize: 'small',
            },
            {
                label: t(prefix + 'vat'),
                key: 'vat',
                position: 'right',
                columnSize: 'small',
            },
            {
                label: t(prefix + 'date'),
                key: 'date',
                position: 'left',
            },
            {
                label: t(prefix + 'paymentId'),
                key: 'paymentId',
                position: 'left',
                columnSize: 'big'
            },
        ]
    }

    useEffect(() => {
        getTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]) 
    
    return <div className="tournament-tickets-stats">
                <div className="tournament-tickets-stats__title">{t(prefix + 'title')}</div>
                <div className="tournament-tickets-stats__back-link">
                    <NavLink to={`/community/${communityId}/tournament/${id}/tags/dashboard`} >
                        <BackIcon w="10" h="16" f="#0474EF" /> <span className="tournament-tickets-stats__back-text">{t('tournamentDashboard-backButton')}</span>
                    </NavLink>
                </div>
                <Switcher switches={classList} onSelect={setPage} selectedValue={page} />
                {page === SubTiketPagesEnum.TICKETS && <div className="tournament-tickets-stats__table-holder">
                    <RaqtTable config={tableConfig} data={TicketList} />
                </div>}
                {page === SubTiketPagesEnum.SUMMERY && <div className="tournament-tickets-stats__table-holder">
                    <div className="tournament-tickets-stats__summary-title">
                        {t(prefix + 'summaryRaqtDetailsTitle').toLocaleUpperCase()}
                    </div>
                    <div className="tournament-tickets-stats__summary-raqt-wrapper">
                        <div className="tournament-tickets-stats__summary-raqt">
                            <ItemWrapper className="tournament-tickets-stats__summary-raqt-left">
                                {t(prefix + 'summaryAddress')}
                            </ItemWrapper>
                            <ItemWrapper className="tournament-tickets-stats__summary-raqt-right">
                                {raqtInfo.invoiceAddress || ''}
                            </ItemWrapper>
                        </div>                       
                         <div className="tournament-tickets-stats__summary-raqt">
                            <ItemWrapper className="tournament-tickets-stats__summary-raqt-left">
                                {t(prefix + 'summaryVat')}
                            </ItemWrapper>
                            <ItemWrapper className="tournament-tickets-stats__summary-raqt-right">
                                {raqtInfo.raqtVAT || ''}
                            </ItemWrapper>
                        </div>
                        <div className="tournament-tickets-stats__summary-raqt">
                            <ItemWrapper className="tournament-tickets-stats__summary-raqt-left">
                                {t(prefix + 'summaryNumber')}
                            </ItemWrapper>
                            <ItemWrapper className="tournament-tickets-stats__summary-raqt-right">
                                {raqtInfo.organizationNumber || ''}
                            </ItemWrapper>
                        </div>
                        <div className="tournament-tickets-stats__summary-raqt">
                            <ItemWrapper className="tournament-tickets-stats__summary-raqt-left">
                                {t(prefix + 'summaryEmail')}
                            </ItemWrapper>
                            <ItemWrapper className="tournament-tickets-stats__summary-raqt-right">
                                {raqtInfo.invoiceEmail || ''}
                            </ItemWrapper>
                        </div>
                    </div>
                    <div className="tournament-tickets-stats__summary-title">
                        {t(prefix + 'summaryToInvoiceTitle').toLocaleUpperCase()}
                    </div>
                    {userInvoice.map((item, index) => <div key={`summery-item-${index}`} className="tournament-tickets-stats__summary-user-wrapper">
                        <div className="tournament-tickets-stats__summary-user">
                            <ItemWrapper className="tournament-tickets-stats__summary-user-left">
                                {t(prefix + 'summaryTickets',{tournamentName: currentTournamet?.name || ''})}
                            </ItemWrapper>
                            <ItemWrapper className="tournament-tickets-stats__summary-user-right">
                                {`${item.currency} ${item.ticketsPrice}`.toLocaleUpperCase() || ''}
                            </ItemWrapper>
                        </div>                       
                        <div className="tournament-tickets-stats__summary-user">
                            <ItemWrapper className="tournament-tickets-stats__summary-user-left">
                                {t(prefix + 'summaryVatIncluded')}
                            </ItemWrapper>
                            <ItemWrapper className="tournament-tickets-stats__summary-user-right">
                                {`${item.currency} ${item.vatIncludedPrice}`.toLocaleUpperCase() || ''}
                            </ItemWrapper>
                        </div>  
                        <div className="tournament-tickets-stats__summary-user">
                            <ItemWrapper className="tournament-tickets-stats__summary-user-left">
                                {t(prefix + 'summaryFee')}
                            </ItemWrapper>
                            <ItemWrapper className="tournament-tickets-stats__summary-user-right">
                                {`${item.currency} ${item.serviceFeePrice}`.toLocaleUpperCase() || ''}
                            </ItemWrapper>
                        </div>  
                        <div className="tournament-tickets-stats__summary-user">
                            <ItemWrapper className="tournament-tickets-stats__summary-user-left">
                                {t(prefix + 'summaryToInvoice')}
                            </ItemWrapper>
                            <ItemWrapper className="tournament-tickets-stats__summary-user-right">
                                {`${item.currency} ${item.invoice}`.toLocaleUpperCase() || ''}
                            </ItemWrapper>
                        </div>  
                    </div>)}
                </div>}
            </div>;
}