export enum StatsTabsConfig {
    NONE,
    CLASSES,
    PLAYERS,
    TIKETS,
    MATCHES,
}

export enum StatsTabsLinksConfig {
    NONE = '',
    CLASSES = 'classes',
    PLAYERS = 'players',
    TIKETS = 'tikets',
    MATCHES = 'matches',
}

export const SubTabLinksList = [
    'classes',
    'players',
    'tikets',
    'matches',
]

export const SubTabPageToEnumMap = {
    'classes': StatsTabsConfig.CLASSES,
    'players': StatsTabsConfig.PLAYERS,
    'tikets': StatsTabsConfig.TIKETS,
    'matches': StatsTabsConfig.MATCHES,
}