import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { CustomButton } from './Button';

interface ButtonImagePreviewProps {
    title?: string;
    onChange: (arg: ImageListType) => void;
    src: null | string;
    alt: string;
    btnTxt?: string;
    className?: string;
    imgClassName?: string;
}

export const ButtonImagePreview: FC<ButtonImagePreviewProps> = ({ title, onChange, src, alt, btnTxt, className, imgClassName }) => {
    const { t } = useTranslation();
    const buttonPrefix = 'button-';

    return (
        <>
            {title && <p className="font-weight-normal my-1 mb-3">{title}</p>}
            <div className={className || 'd-flex flex-column align-items-center'}>
                <img
                    style={{ objectFit: 'cover' }}
                    className={imgClassName || 'rounded-circle'}
                    src={src || require('../../assets/images/noImage.png')}
                    width="100"
                    height="100"
                    alt={alt}
                />
                <ImageUploading value={[]} onChange={onChange}>
                    {({ onImageUpload }) => (
                        <CustomButton btnText={btnTxt || t(buttonPrefix + 'addIcon')} cb={onImageUpload} />
                    )}
                </ImageUploading>
            </div>
        </>
    );
};
