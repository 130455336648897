import { getIsLoadedOnce } from './organizationsSelectors';
import { AppDispatch, RootState } from './../../app/store';
import { fetchOrganizationsListAPI } from './../../apis/organizations';
import { setOrganizationsList, startLoading, stopLoading } from './organizationsSlice';

export const fetchOrganizationsList = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const isInitial = !getIsLoadedOnce(getState());

    isInitial && dispatch(startLoading());

    try {
        const { organizations } = await fetchOrganizationsListAPI();
        dispatch(setOrganizationsList(organizations));
    } catch (e) {

    }

    isInitial && dispatch(stopLoading());
};
