import { SvgIcon } from '../../components';

interface ChainIconProps {
    w?: string;
    h?: string;
    f?: string;
    s?: string;
}


export const ChainIcon = ({ w, h, f, s }: ChainIconProps) => {
    return (
        <SvgIcon width={w || '22'} height={h || '22'} viewBox="0 0 22 22">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M9.0002 12C9.42965 12.5741 9.97756 13.0491 10.6067 13.3929C11.2359 13.7367 11.9317 13.9411 12.6468 13.9923C13.362 14.0435 14.0798 13.9403 14.7515 13.6897C15.4233 13.4392 16.0333 13.047 16.5402 12.54L19.5402 9.53997C20.451 8.59695 20.955 7.33394 20.9436 6.02296C20.9322 4.71198 20.4063 3.45791 19.4793 2.53087C18.5523 1.60383 17.2982 1.07799 15.9872 1.0666C14.6762 1.0552 13.4132 1.55918 12.4702 2.46997L10.7502 4.17996M13.0002 9.99997C12.5707 9.42584 12.0228 8.95078 11.3936 8.60703C10.7645 8.26327 10.0687 8.05885 9.35355 8.00763C8.63841 7.95641 7.92061 8.0596 7.24885 8.31018C6.5771 8.56077 5.96709 8.9529 5.4602 9.45997L2.4602 12.46C1.54941 13.403 1.04544 14.666 1.05683 15.977C1.06822 17.288 1.59407 18.542 2.52111 19.4691C3.44815 20.3961 4.70221 20.9219 6.01319 20.9333C7.32418 20.9447 8.58719 20.4408 9.5302 19.53L11.2402 17.82"
                fill={f || 'none'}
                stroke={s || '#FAFAFA'} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
