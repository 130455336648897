import React from 'react';
import { SvgIcon } from '../../components';

interface PlayIconProps {
    w?: string;
    h?: string;
    f?: string;
}

export const PlayIcon = (props: PlayIconProps) => {
    const { w, h, f } = props;
    return (
        <SvgIcon width={w || '50'} height={h || '50'} viewBox="0 0 50 50">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M20.8333 34.375V15.625L33.3333 25L20.8333 34.375ZM25 4.16666C22.2641 4.16666 19.555 4.70553 17.0274 5.7525C14.4998 6.79947 12.2031 8.33405 10.2686 10.2686C6.36156 14.1756 4.16663 19.4746 4.16663 25C4.16663 30.5253 6.36156 35.8244 10.2686 39.7314C12.2031 41.6659 14.4998 43.2005 17.0274 44.2475C19.555 45.2945 22.2641 45.8333 25 45.8333C30.5253 45.8333 35.8243 43.6384 39.7314 39.7314C43.6384 35.8244 45.8333 30.5253 45.8333 25C45.8333 22.2641 45.2944 19.555 44.2475 17.0274C43.2005 14.4998 41.6659 12.2032 39.7314 10.2686C37.7968 8.33405 35.5002 6.79947 32.9725 5.7525C30.4449 4.70553 27.7358 4.16666 25 4.16666Z"
                fill={f || '#5D6465'}
            />
        </SvgIcon>
    );
};
