import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import {
    IScheduleConstraints,
    IScheduleDayConstraints,
    ITournamentSchedule,
    IFreeSlotItem,
    isFreeSlot,
} from '../../../../types/schedule';
import { ClassMatchType, ScheduleFilterType } from '../../../../types/enums';
import { Court } from '../../../../types/tournament';

/*
Need to create the initial values dynamically due to the variable amount of days in a tournament, cannot provide static keys
*/
export function generateInitialScheduleValues(constraints: IScheduleConstraints) {
    const initialValues: any = {};
    constraints.days.forEach((day) => {
        const allClassesChecked =
            constraints.tournamentClasses.length !== 1 &&
            constraints.tournamentClasses.every(({ id }) => {
                return day.classes.map(({ id: classId }) => classId).includes(id);
            });
        const allCourtsChecked =
            constraints.tournamentCourts.length !== 1 &&
            constraints.tournamentCourts.every(({ id }) => {
                return day.courts.map(({ id: courtId }) => courtId).includes(id);
            });

        const partiallySelectedClasses = !allClassesChecked
            ? day.classes.map(({ id: classId }) => classId.toString())
            : [];

        const partiallySelectedCourts = !allCourtsChecked
            ? day.courts.map(({ id: courtId }) => courtId.toString())
            : [];

        const selectedCourtsForClasses = constraints.tournamentClasses
            .flatMap((clazz) => {
                const combination = constraints.tournamentCourts.flatMap((court) => [
                    `${clazz.id}-${court.id}`,
                ]);
                return [...combination];
            })
            .filter((classOnCourt) => {
                const [classId, courtId] = classOnCourt.split('-');
                const option = day.classOptions.find(({ class: clazz }) => clazz.id === +classId);
                if (option !== undefined) {
                    return option.courts.find(({ id }) => id === +courtId);
                }
                return true;
            });

        day.classOptions.forEach((option) => {
            initialValues[`defaultMatchLength-class-${option.class.id}-day-${day.dayKey}`] =
                option.matchLengths[ClassMatchType.ROUND1];
            initialValues[`QF-matchLength-class-${option.class.id}-day-${day.dayKey}`] =
                option.matchLengths[ClassMatchType.QUARTERFINAL];
            initialValues[`SF-matchLength-class-${option.class.id}-day-${day.dayKey}`] =
                option.matchLengths[ClassMatchType.SEMIFINAL];
            initialValues[`F-matchLength-class-${option.class.id}-day-${day.dayKey}`] =
                option.matchLengths[ClassMatchType.FINAL];
        });

        initialValues[`startOfDay-${day.dayKey}`] = `${new Date(
            day.startTime
        ).toLocaleTimeString()}`;
        initialValues[`endOfDay-${day.dayKey}`] = `${new Date(day.endTime).toLocaleTimeString()}`;
        initialValues[`defaultMatchLength-${day.dayKey}`] = day.defaultMatchLength;
        initialValues[`defaultPauseLength-${day.dayKey}`] = day.defaultPauseLength;
        initialValues[`all-class-${day.dayKey}`] = allClassesChecked;
        initialValues[`all-court-${day.dayKey}`] = allCourtsChecked;

        initialValues[`class-day-${day.dayKey}`] = partiallySelectedClasses;
        initialValues[`court-day-${day.dayKey}`] = partiallySelectedCourts;

        initialValues[`class-on-court-day-${day.dayKey}`] = selectedCourtsForClasses;
    });
    return initialValues;
}

export function generateScheduleSchema(
    days: IScheduleDayConstraints[],
    t: TFunction<'translation', undefined>
) {
    const prefixValidation = 'validation-';

    const yupSchema: any = {};
    days.forEach((day) => {
        yupSchema[`startOfDay-${day.dayKey}`] = Yup.string().required(
            t(prefixValidation + 'required')
        );
        yupSchema[`endOfDay-${day.dayKey}`] = Yup.string()
            .required(t(prefixValidation + 'required'))
            .test({
                name: 'endTime',
                exclusive: false,
                message: t(prefixValidation + 'endTime'),
                test: function (endTime) {
                    const startTime = this.parent[`startOfDay-${day.dayKey}`];
                    if (startTime && endTime) {
                        const start = parseTime(startTime);
                        const end = parseTime(endTime);
                        return end > start;
                    }
                    return true;
                },
            });
        yupSchema[`defaultMatchLength-${day.dayKey}`] = Yup.number()
            .typeError(t(prefixValidation + 'mustBeANumber'))
            .required(t(prefixValidation + 'required'))
            .positive(t(prefixValidation + 'positive'))
            .integer(t(prefixValidation + 'integer'));
        yupSchema[`defaultPauseLength-${day.dayKey}`] = Yup.number()
            .typeError(t(prefixValidation + 'mustBeANumber'))
            .required(t(prefixValidation + 'required'))
            .moreThan(-1, t(prefixValidation + 'positive'))
            .integer(t(prefixValidation + 'integer'));
        yupSchema[`all-class-${day.dayKey}`] = Yup.boolean().test({
            name: 'class-opt-in',
            exclusive: false,
            message: t(prefixValidation + 'classOptIn'),
            /*
            Ensures that if classes are opt-in, one or more classes have been selected.
            */
            test: function (isNotOptIn) {
                if (!isNotOptIn) {
                    const classesSelected: Array<any> = this.parent[`class-day-${day.dayKey}`];
                    if (!classesSelected || !classesSelected.length) {
                        return false;
                    }
                }
                return true;
            },
        });
        yupSchema[`all-court-${day.dayKey}`] = Yup.boolean().test({
            name: 'court-opt-in',
            exclusive: false,
            message: t(prefixValidation + 'courtOptIn'),
            test: function (isNotOptIn) {
                if (!isNotOptIn) {
                    const courtsSelected: Array<any> = this.parent[`court-day-${day.dayKey}`];
                    if (!courtsSelected || !courtsSelected.length) {
                        return false;
                    }
                }
                return true;
            },
        });

        day.classes.forEach((clazz) => {
            yupSchema[`defaultMatchLength-class-${clazz.id}-day-${day.dayKey}`] = Yup.number()
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .required(t(prefixValidation + 'required'))
                .positive(t(prefixValidation + 'positive'))
                .integer(t(prefixValidation + 'integer'));

            yupSchema[`QF-matchLength-class-${clazz.id}-day-${day.dayKey}`] = Yup.number()
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .required(t(prefixValidation + 'required'))
                .positive(t(prefixValidation + 'positive'))
                .integer(t(prefixValidation + 'integer'));

            yupSchema[`SF-matchLength-class-${clazz.id}-day-${day.dayKey}`] = Yup.number()
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .required(t(prefixValidation + 'required'))
                .positive(t(prefixValidation + 'positive'))
                .integer(t(prefixValidation + 'integer'));

            yupSchema[`F-matchLength-class-${clazz.id}-day-${day.dayKey}`] = Yup.number()
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .required(t(prefixValidation + 'required'))
                .positive(t(prefixValidation + 'positive'))
                .integer(t(prefixValidation + 'integer'));
        });
    });
    return yupSchema;
}

function parseTime(timeString: string) {
    const [hours, minutes] = timeString.split(':');
    return new Date(0, 0, 0, +hours, +minutes);
}

export function getTimeDateFromFormikInput(time: string, d: string) {
    const timeInHHmm: string[] = time.split(':');
    const t = new Date(d);
    t.setHours(+timeInHHmm[0]);
    t.setMinutes(+timeInHHmm[1]);
    return t.toISOString();
}

export function getClassOptionsFromFormikInput(
    values: any,
    day: IScheduleDayConstraints,
    allCourts: Court[]
) {
    return day.classOptions.map((option) => {
        const out = { ...option };
        let courtsForClass = values[`class-on-court-day-${day.dayKey}`];
        if (courtsForClass !== undefined) {
            courtsForClass = courtsForClass
                .filter((v: string) => {
                    const a = v.split('-');
                    return +a[0] === option.class.id;
                })
                .map((c: string) => +c.split('-')[1]);
            if (courtsForClass.length > 0) {
                out.courts = allCourts.filter(({ id }) => courtsForClass.includes(id));
            }
        }

        const defaultMatchLengthsForClass =
            values[`defaultMatchLength-class-${option.class.id}-day-${day.dayKey}`];
        const qfMatchLengthsForClass =
            values[`QF-matchLength-class-${option.class.id}-day-${day.dayKey}`];
        const sfMatchLengthsForClass =
            values[`SF-matchLength-class-${option.class.id}-day-${day.dayKey}`];
        const finalMatchLengthsForClass =
            values[`F-matchLength-class-${option.class.id}-day-${day.dayKey}`];

        out.matchLengths = Object.keys(ClassMatchType)
            .filter((k) => !Number.isNaN(+k))
            .reduce((accumulator, value) => {
                const coerced: number = +value;
                if (coerced === ClassMatchType.FINAL) {
                    accumulator[coerced as keyof typeof accumulator] = finalMatchLengthsForClass;
                } else if (coerced === ClassMatchType.SEMIFINAL) {
                    accumulator[coerced as keyof typeof accumulator] = sfMatchLengthsForClass;
                } else if (coerced === ClassMatchType.QUARTERFINAL) {
                    accumulator[coerced as keyof typeof accumulator] = qfMatchLengthsForClass;
                } else {
                    accumulator[coerced as keyof typeof accumulator] = defaultMatchLengthsForClass;
                }

                return accumulator;
            }, {} as { [matchType in ClassMatchType]: number });

        return out;
    });
}

export function getDateString(d: string) {
    return new Date(d).toISOString().split('T')[0];
}

export function getHhmmTimeString(datestring: string) {
    const d = new Date(datestring);
    const Hh = d.getHours().toString();
    const Mm = d.getMinutes().toString();
    return (Hh.length === 1 ? '0' + Hh : Hh) + ':' + (Mm.length === 1 ? '0' + Mm : Mm);
}

export type FilterItem = {
    type: ScheduleFilterType;
    value: number | string;
};

export function pureFilterScheduleBy(
    schedule: ITournamentSchedule,
    filters: FilterItem[]
): ITournamentSchedule {
    if (filters.length === 0) {
        return schedule;
    }
    const filteredSchedule: ITournamentSchedule = { ...JSON.parse(JSON.stringify(schedule)) };
    filteredSchedule.days = filteredSchedule.days.map((day) => {
        day.courts = day.courts.map((courtSchedule) => {
            courtSchedule.matches = courtSchedule.matches
                .map((match) => {
                    if (isFreeSlot(match)) {
                        return { ...match, scheduleDate: match.scheduleDate };
                    } else {
                        return {
                            ...match,
                            scheduleDate: match.scheduleDate ? match.scheduleDate : null,
                        }; // on deep copy the complete Date object will not be created.
                    }
                })
                .map((match) => {
                    if (isFreeSlot(match)) return match;
                    let includeMatch: boolean = false;
                    filters.forEach((filterItem) => {
                        switch (filterItem.type) {
                            case ScheduleFilterType.PLAYER:
                                if (
                                    +match.homePlayer1 === filterItem.value ||
                                    (match.homePlayer2 &&
                                        +match.homePlayer2 === filterItem.value) ||
                                    +match.awayPlayer1 === filterItem.value ||
                                    (match.awayPlayer2 && +match.awayPlayer2 === filterItem.value)
                                ) {
                                    includeMatch = true;
                                }
                                break;
                            case ScheduleFilterType.CLASS:
                                if (+match.classId === filterItem.value) {
                                    includeMatch = true;
                                }
                                break;
                            case ScheduleFilterType.TEAM:
                                if (
                                    +match.homeTeam.id === filterItem.value ||
                                    +match.awayTeam.id === filterItem.value
                                ) {
                                    includeMatch = true;
                                }
                                break;
                            case ScheduleFilterType.TIME:
                                if (
                                    match.scheduleDate?.toString() === filterItem.value.toString()
                                ) {
                                    includeMatch = true;
                                }
                                break;
                        }
                    });
                    if (includeMatch) {
                        return match;
                    } else {
                        return {
                            isFreeSlot: true,
                            court: match.court,
                            scheduleDate: match.scheduleDate,
                        } as IFreeSlotItem;
                    }
                });
            return courtSchedule;
        });
        return day;
    });
    return filteredSchedule;
}
