import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { getMatchBoardForTournament, IMatchBoardItem } from '../../apis/tournament';
import { BackIcon } from '../../assets/icons/BackIcon';
import { RaqtTable, Switcher } from '../../components';
import { IRaqtTableConfig } from '../../components/table/RaqtTable';
import { fetchTournamentClassListPure } from '../../features/class/classActions';
import { selectClassList } from '../../features/class/classSelectors';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { TournamentMatchStatusEnumTranslation } from '../../types/enums';
import { getDateWithTime } from '../../utils/getStringDate';
import './TournamentMatchesStats.scss';


export const TournamentMatchesStats = () => {
    const prefix = 'tournamentStatsMatches-';

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [matchList, setMatchList] = useState<(Omit<IMatchBoardItem, 'results' | 'status'> & { results: string, status: string })[]>([]);
    // const [isLoading, setIsloading] = useState(false);
    const [classId, setClassId] = useState<number | null>(null);
    const { id , communityId } = useParams<{ id: string, communityId: string }>();
    const classes = useAppSelector(selectClassList);

    const classList = [
        {label: 'All', value: null},
        ...classes.map(({classId, name}) => ({
            value: classId,
            label: name,
        }))
    ];

    const getMatches = async () => {
        // setIsloading(true);

        try {
            const {matchBoard} = await getMatchBoardForTournament({id: +id, classId});
            setMatchList(matchBoard.map(i => ({
                ...i,
                startDate: getDateWithTime(i.startDate),
                status: t(TournamentMatchStatusEnumTranslation[i.status]),
                results: i.results.filter(Boolean).join(', ')
            })));
        } catch (e) {
            setMatchList([]);
        }

        // setIsloading(false);
    }

    const tableConfig: IRaqtTableConfig = {
        blankHead: true,
        columns: [
            {
                label: t(prefix + 'class').toLocaleUpperCase(),
                key: 'tournamentClassName',
                position: 'left',
            },
            {
                label: t(prefix + 'startDate').toLocaleUpperCase(),
                key: 'startDate',
                columnSize: 'small',
                position: 'left',
            },
            {
                label: t(prefix + 'firstTeam').toLocaleUpperCase(),
                key: 'homeTeamName',
                position: 'left',
            },
            {
                label: t(prefix + 'secondTeam').toLocaleUpperCase(),
                key: 'awayTeamName',
                position: 'left',
            },
            {
                label: t(prefix + 'results').toLocaleUpperCase(),
                key: 'results',
                columnSize: 'small',
                position: 'left',
            },
            {
                label: t(prefix + 'status').toLocaleUpperCase(),
                key: 'status',
                columnSize: 'small',
                position: 'left',
            }
        ]
    }

    useEffect(() => {
        getMatches();
        !classes.length && dispatch(fetchTournamentClassListPure(+id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, classId, classes]) 
    
    return <div className="tournament-matches-stats">
        <div className="tournament-matches-stats__title">{t(prefix + 'title')}</div>
        <div className="tournament-matches-stats__back-link">
            <NavLink to={`/community/${communityId}/tournament/${id}/tags/dashboard`} >
                <BackIcon w="10" h="16" f="#0474EF" /> <span className="tournament-matches-stats__back-text">{t('tournamentDashboard-backButton')}</span>
            </NavLink>
        </div>
        <Switcher switches={classList} onSelect={setClassId} selectedValue={classId} />
        <div className="tournament-matches-stats__table-holder">
            <RaqtTable config={tableConfig} data={matchList} />
        </div>
    </div>;
}