import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { CloseCircle } from '../../../assets/icons/CloseCircle';
import './index.scss';

interface ConfigItemCardProps {
    title: string;
    handleDeleteConfigItem: () => void;
}

export const ConfigItemCard: FC<ConfigItemCardProps> = ({ title, handleDeleteConfigItem }) => {
    return (
        <div
            style={{ minHeight: '43px' }}
            className="d-flex justify-content-lg-center justify-content-between align-items-center mr-2 border bg-white my-2 br-16"
        >
            <div className="d-flex flex-grow-1">
                <span className="px-2">{title}</span>
            </div>
            {
                <Button
                    className="border-0 btnDeleteConfigItem"
                    variant="outline-dark"
                    onClick={handleDeleteConfigItem}
                >
                    <CloseCircle />
                </Button>
            }
        </div>
    );
};
