import { refreshApi } from './auth';
import axios from 'axios';
import { store } from '../app/store';
import { logout, refreshToken } from '../features/currentUser/currentUserSlice';
import { QueryClient } from '@tanstack/react-query';

//const fallOffApi = 'http://localhost:3000';

// const fallOffApi = 'https://api-dev.raqt.com';

const fallOffApi = 'https://api.raqt.com';

//

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || fallOffApi,
});

export const queryClient = new QueryClient();

api.interceptors.request.use((config) => {
    const state = store.getState();

    if (state.currentUser.accessToken) {
        return {
            ...config,
            headers: {
                ...config.headers,
                authorization: `Bearer ${state.currentUser.accessToken}`,
            },
        };
    }
    return config;
});

api.interceptors.response.use(undefined, async (error) => {
    const originalRequest = error.config;
    const state = store.getState();
    console.log(error);
    if (error.response.request.responseURL.includes('refresh')) return;

    if (
        (error.response.status === 403 || error.response.status === 401) &&
        !originalRequest._retry &&
        state.currentUser.refreshToken
    ) {
        originalRequest._retry = true;
        store.dispatch(refreshToken({ accessToken: state.currentUser.refreshToken }));

        try {
            const token = await refreshApi();
            store.dispatch(refreshToken(token));
            return api({
                ...originalRequest,
                headers: {
                    ...originalRequest.headers,
                    authorization: `Bearer ${token.accessToken}`,
                },
            });
        } catch (e) {
            store.dispatch(logout());
        }
    } else if (
        (error.response.status === 403 || error.response.status === 401) &&
        (originalRequest._retry || !state.currentUser.refreshToken)
    ) {
        store.dispatch(logout());
    }

    return Promise.reject(error);
});
