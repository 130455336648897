import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
// import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import classnames from 'classnames';

import { CustomAccordionHeader } from '../../../common/components/CustomAccordionHeader';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { ButtonFormikTab, CustomButton, FormTextField, ReactSelectForm } from '../../../../../components';
import { MatchesLabelItem } from '../MatchesLabelItem';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';
import { PlayIcon } from '../../../../../assets/icons/PlayIcon';
import { ClassFormatEnum, SettingsStatusEnum, TournamentMatchStatusEnum } from '../../../../../types/enums';
import { EditIcon } from '../../../../../assets/icons/EditIcon';
import { updateStatusPlay } from '../../../../../features/tournament/actionCreators';
import { TeamPlayCard } from '../TeamPlayCard';
import { MemberTournamentCard } from '../../../../../components';
import { boolOption } from '../../../../../constant/setting';
import { matchType, filterClassFormatValues } from '../../../../../constant/play';
import { Court } from '../../../../../types/tournament';
import { PlayValues } from '../PlayTag/PlayTag';
import { IMatch } from '../PlayTag/PlayTag';
import { ClassValues } from '../../../TournamentClasses/components/ClassesTag/ClassesTag';
import { MatchResult } from '../../../../../types/tournament';
import { BracketOptions } from '../../../../../types/enums';
import { traslateItemWithLabel } from '../../../../../utils/traslation.helpers';
import { MatchStatus } from '../MatchStatus';
import { StopCircleIcon } from '../../../../../assets/icons/StopCircleIcon';
import { ShowDontKnowTeamOrError } from '../ShowDontKnowTeamOrError';
import { DontKnowPlayerPlaceholder } from '../DontKnowPlayerPlaceholder';
import { convertToBooleanString, getMatchId, getMatchName } from './Match.helpers';
import { MatchInformationItemWrapperGray } from '../MatchInformationItemWrapperGray';
import { ScoreCardDownload } from '../ScoreCardDownload';

import './Match.scss';

interface MatchProps {
    index: number;
    formikArg: FormikProps<PlayValues>;
    courts: Array<Court>;
    matches: { [key in number]: IMatch };
    match: IMatch;
    isGroup?: boolean;
    currentClass: ClassValues | undefined;
    handleAddTeam: (matchId: string | number, fieldName: string) => void;
    handleAddPlayer: (teamId: string | number, fieldName: string, matchId: string | number) => void;
    handleOpenResultModal: (results?: Array<MatchResult>, matchId?: number | string) => void;
}

export const Match: FC<MatchProps> = ({
    matches,
    match,
    currentClass,
    courts,
    isGroup = false,
    formikArg,
    handleAddTeam,
    handleAddPlayer,
    handleOpenResultModal,
    index,
}) => {
    const { t } = useTranslation();
    // const { id: TourId } = useParams<{ id: string }>();
    // const { currentClassId } = useAppSelector((state) => state.class);

    const dispatch = useAppDispatch();
    const { status } = useAppSelector(state => state.tournament.data?.settings) || {};

    const prefix = 'creativeDetail-';
    const buttonPrefix = 'button-';
    
    useEffect(()=> {
        formikArg.setFieldValue(`tournamentMatches.${match.id}.name`, getMatchName(t, match));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.id, match.homeTeam, match.awayTeam, match.homeTeamPlaceholder, match.awayTeamPlaceholder]);

    useEffect(() => {
        const matchId = getMatchId(formikArg.values.tournamentMatches[match.id as number]?.sortKey || 0,isGroup ,match.tournamentGroup?.id || null, match.type, match.isGold);

        if(!match.matchId || !match.matchId.includes(matchId)) {
            formikArg.setFieldValue(`tournamentMatches.${match.id}.matchId`,
                matchId
            );
        }

        if(!formikArg.values.tournamentMatches[match.id as number]?.court && courts[0]) {
            formikArg.setFieldValue(`tournamentMatches.${match.id}.court`,
                courts[0].id
            );
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGroup, match.id, match.matchId, match.sortKey, match.tournamentGroup?.id, match.type, match.isGold]);

    const isStatusButtonDisable = SettingsStatusEnum.STARTED !== status || !match.awayTeam || !match.homeTeam;

    const isMatchSaved = match.status !== undefined && typeof match.id === 'number';

    const isShowing = isMatchSaved && !(!match.awayTeam || !match.homeTeam);
        
    const filterMatchTypeDependsOnClassFormat = (isGroup
            ? matchType.filter((field) => !filterClassFormatValues.includes(field.value))
            : matchType).map(({ label, value }) => ({
                label: t(label, {roundNumber: value}),
                value,
            }));
    return (
        <Accordion>
            <Card>
                <Card.Header className="p-0 bg-white border-0 relative">
                    <CustomAccordionHeader eventKey={match.toString()}>
                        <div className="d-flex align-items-center">
                            <span className="ps-2 pe-1">{`${isGroup ? index + 1 : match.sortKey}.`}</span>
                            {formikArg.values.tournamentMatches[match.id as number]?.name}
                            {/* <FormTextField
                                className="match-section__match-input py-0 border-1 border-white"
                                name={`tournamentMatches.${match.id}.name`}
                                index={match.id}
                                placeholder={t(inputPrefix + 'placeholderMatchName')}
                            /> */}
                        </div>
                        <div className="match__accordion-status">
                            {isShowing && (<MatchStatus matchId={match.id} status={match.status} disabled={isStatusButtonDisable} results={match.results} />)}
                        </div>
                    </CustomAccordionHeader>
                </Card.Header>
                <Accordion.Collapse eventKey={match.toString()}>
                    <Card.Body className="p-2">
                    <div className="container-fluid px-0 px-lg-3">
                            <div className="tournament-match__match-id-title row d-flex">{t('creativeDetail-matchId')}</div>
                            <div className="row py-3 d-flex">
                                <div className="col-md-12 col-lg-4 mb-lg-0 order-1 order-lg-0">
                                    <MatchInformationItemWrapperGray>
                                        {match.matchId}
                                    </MatchInformationItemWrapperGray>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid px-0 px-lg-3">
                            <div className="row py-3 d-flex">
                                <div className="col-md-12 col-lg-4 mb-lg-0 order-1 order-lg-0">
                                    <MatchesLabelItem
                                        btnCb={() => handleAddTeam(match.id, 'homeTeam')}
                                        btnTitle={t(buttonPrefix + 'addChangeTeam')}
                                        label={t(prefix + 'homeTeam')}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-8 order-3 order-lg-0">
                                    <MatchesLabelItem
                                        btnCb={() =>
                                            handleAddPlayer(
                                                match.homeTeam?.id || '',
                                                'homePlayer',
                                                match.id
                                            )
                                        }
                                        withBtn={false}
                                        btnTitle={t(buttonPrefix + 'addChangePlayers')}
                                        label={t(prefix + 'homePlayers')}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-4 mb-md-3 mb-lg-0 py-3 order-2 order-lg-0">
                                    {match.homeTeam ? (
                                        <TeamPlayCard team={match.homeTeam} />
                                    ) : ( 
                                        <ShowDontKnowTeamOrError match={match} placeholderKey="homeTeamPlaceholder"/>
                                    )}
                                </div>
                                {(match.homeTeam && (<div className="col-md-12 col-lg-8 pt-3 pb-1 pb-lg-3 order-3 order-lg-0">
                                    <div className="d-flex h-100">
                                        {
                                            currentClass?.type === 4 ? (
                                                <>
                                                    {match.homePlayer1 ? (
                                                        <MemberTournamentCard onClick={() => {
                                                            handleAddPlayer(
                                                                match.homeTeam?.id || '',
                                                                'homePlayer',
                                                                match.id
                                                            )
                                                        }} member={match.homePlayer1} />
                                                    ) : (
                                                        <p className="fw-lighter">{t(prefix + 'warning5')}</p>
                                                    )}
                                                    {match.homePlayer2 ? (
                                                        <MemberTournamentCard onClick={() => {
                                                            handleAddPlayer(
                                                                match.homeTeam?.id || '',
                                                                'homePlayer',
                                                                match.id
                                                            )
                                                        }} member={match.homePlayer2} />
                                                    ) : null}
                                                </>
                                            ) : (
                                                <>
                                                    {match.homePlayer1 ? (
                                                        <MemberTournamentCard member={match.homePlayer1} />
                                                    ) : (
                                                        <p className="fw-lighter">{t(prefix + 'warning5')}</p>
                                                    )}
                                                    {match.homePlayer2 ? (
                                                        <MemberTournamentCard member={match.homePlayer2} />
                                                    ) : null}
                                                </>
                                            )
                                        }
                                    </div>
                                </div>)) || (<DontKnowPlayerPlaceholder placeholder={match.homeTeamPlaceholder} />)}
                            </div>
                        </div>
                        <div className="container-fluid px-0 px-lg-3">
                            <div className="row pt-3 d-flex">
                                <div className="col-md-12 col-lg-4 mb-1 mb-lg-4 order-1 order-lg-0">
                                    <MatchesLabelItem
                                        btnCb={() => handleAddTeam(match.id, 'awayTeam')}
                                        btnTitle={t(buttonPrefix + 'addChangeTeam')}
                                        label={t(prefix + 'awayTeam')}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-8 order-3 order-lg-0">
                                    <MatchesLabelItem
                                        btnCb={() => {
                                            handleAddPlayer(
                                                match.awayTeam?.id || '',
                                                'awayPlayer',
                                                match.id
                                            );
                                        }}
                                        withBtn={false}
                                        btnTitle={t(buttonPrefix + 'addChangePlayers')}
                                        label={t(prefix + 'awayPlayers')}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-4 mb-md-3 mb-lg-0 py-3 order-2 order-lg-0">
                                    {match.awayTeam ? (
                                        <TeamPlayCard team={match.awayTeam} />
                                    ) : (
                                        <ShowDontKnowTeamOrError match={match} placeholderKey="awayTeamPlaceholder"/>
                                    )}
                                </div>
                               {(match.awayTeam && (<div className="col-md-12 col-lg-8 pt-3 pb-1 pb-lg-3 order-3 order-lg-0">
                                    <div className="d-flex h-100">
                                        {
                                            currentClass?.type === 4 ? (
                                                <>
                                                    {match.awayPlayer1 ? (
                                                        <MemberTournamentCard onClick={() => {
                                                            handleAddPlayer(
                                                                match.awayTeam?.id || '',
                                                                'awayPlayer',
                                                                match.id
                                                            )
                                                        }} member={match.awayPlayer1} />
                                                    ) : (
                                                        <p className="fw-lighter">{t(prefix + 'warning5')}</p>
                                                    )}
                                                    {match.awayPlayer2 ? (
                                                        <MemberTournamentCard onClick={() => {
                                                            handleAddPlayer(
                                                                match.awayTeam?.id || '',
                                                                'awayPlayer',
                                                                match.id
                                                            )
                                                        }} member={match.awayPlayer2} />
                                                    ) : null}
                                                </>
                                            ) : (
                                                <>
                                                   {match.awayPlayer1 ? (
                                                        <MemberTournamentCard member={match.awayPlayer1} />
                                                    ) : (
                                                        <p className="fw-lighter">{t(prefix + 'warning5')}</p>
                                                    )}
                                                    {match.awayPlayer2 ? (
                                                        <MemberTournamentCard member={match.awayPlayer2} />
                                                    ) : null}
                                                </>
                                            )
                                        }
                                    </div>
                                </div>)) || (<DontKnowPlayerPlaceholder placeholder={match.awayTeamPlaceholder} />)}
                            </div>
                        </div>
                        <div className="container-fluid px-0 px-lg-3">
                            <div className="row">
                                <div className="col-md-12 col-lg-4 mb-md-3 mb-lg-0">
                                    <ReactSelectForm
                                        options={courts.map((court: any) => ({
                                            label: court.name,
                                            value: court.id,
                                        }))}
                                        name={`tournamentMatches.${match.id}.court`}
                                        label={t(prefix + 'court')}
                                    />
                                </div>
                                <div className="col">
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <FormTextField
                                                type="datetime-local"
                                                id="startDate"
                                                label={t(prefix + 'startTime')}
                                                name={`tournamentMatches.${match.id}.startDate`}
                                            />
                                        </div>
                                        <div className="w-50">
                                            <ReactSelectForm
                                                options={filterMatchTypeDependsOnClassFormat}
                                                name={`tournamentMatches.${match.id}.type`}
                                                label={t(prefix + 'type')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {Number(match.type) === 13 ? (
                                <div className="col col-lg-4 px-0 py-3">
                                    <ButtonFormikTab
                                        currValue={convertToBooleanString(match.isGold)}
                                        items={boolOption}
                                        name={`tournamentMatches.${match.id}.isGold`}
                                    />
                                </div>
                            ) : null}
                            { (currentClass?.format === ClassFormatEnum.BRACKET || currentClass?.format === ClassFormatEnum.GROUP_BRACKET) && !match.tournamentGroupId && (
                                <div className="d-flex offset-lg-4 px-0 py-3">
                                    <div className="col px-0">
                                        <div className="d-flex  align-items-center">
                                            <div className="me-3">
                                                <span>{t(prefix + 'bracket')}</span>
                                            </div>
                                            <div className="col-lg-7 col-sm-7 px-0">
                                                <ButtonFormikTab
                                                    currValue={match.bracket}
                                                    items={BracketOptions.map(item => traslateItemWithLabel(t, item))}
                                                    name={`tournamentMatches.${match.id}.bracket`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-start justify-content-lg-end">
                                <div className="d-flex flex-grow-1">
                                    {isShowing && (<CustomButton
                                        className="d-flex align-items-center justify-content-around"
                                        variant="outline-dark"
                                        disabled={isStatusButtonDisable}
                                        btnText={
                                            match.status === TournamentMatchStatusEnum.NOT_STARTED
                                                ? t(buttonPrefix + 'start')
                                                : match.status === TournamentMatchStatusEnum.STARTED
                                                ? t(buttonPrefix + 'stop')
                                                : t(buttonPrefix + 'edit')
                                        }
                                        onClick={() => {
                                            match.status !== undefined && match.status < 2
                                                ? dispatch(
                                                      updateStatusPlay(Number(match.id), {
                                                          status: Number(match.status) + 1,
                                                      })
                                                  )
                                                : handleOpenResultModal(match.results, match.id);
                                        }}
                                    >
                                        {match.status === TournamentMatchStatusEnum.NOT_STARTED ? (
                                            <PlayIcon w="35" h="35" f="#34C765" />
                                        ) : match.status === TournamentMatchStatusEnum.STARTED ? (
                                            <StopCircleIcon w="30" h="30"/>
                                        ) : (
                                            <EditIcon f="#909798" />
                                        )}
                                    </CustomButton>)}
                                    {isMatchSaved && (<div className={classnames({'match-section__score-button-padding': isShowing})}>
                                        <ScoreCardDownload match={match} matchId={match.matchId}/>
                                    </div> )}
                                </div>

                            <CustomButton
                                className="d-flex align-items-center"
                                btnText={t(buttonPrefix + 'removeMatch')}
                                variant="outline-dark"
                                onClick={() => {
                                    formikArg.setFieldValue(
                                        'tournamentMatches',
                                        Object.values(matches)
                                            .filter((m: any) => m.id !== match.id)
                                            .reduce((acc: any, item: any) => {
                                                acc[`${item.id}`] = item;
                                                return acc;
                                            }, {})
                                    );
                                }}
                            >
                                <CloseCircle />
                            </CustomButton>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};
