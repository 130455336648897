import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../components';
import { AddIcon } from '../../assets/icons/AddIcon';
import { Form, InputGroup } from 'react-bootstrap';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import { useHistory } from 'react-router-dom';

import './adminDashboard.scss';

export const AdminDashboard = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const prefix = 'dashboard-';
    const inputPrefix = 'input-';
    const buttonPrefix = 'button-';

    return (
        <div className="px-lg-5 px-sm-2 d-flex flex-grow-1 flex-column my-3 px-2 px-sm-0">
            <div className="row mt-5 mb-3">
                <div className="col d-flex align-items-center">
                    <h3 className="font-weight-bold my-2 title">{t(prefix + 'organisations')}</h3>
                </div>
                <div className="col">
                    <div className="d-flex justify-content-end">
                        <CustomButton
                            className="d-flex justify-content-around align-items-center"
                            btnText={t(buttonPrefix + 'create')}
                            variant="outline-dark"
                            cb={() => {
                                history.push('admin-organization');
                            }}
                        >
                            <AddIcon />
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <InputGroup>
                        <InputGroup.Text className="bg-white px-3 br-s-16">
                            <SearchIcon />
                        </InputGroup.Text>
                        <Form.Control placeholder={t(inputPrefix + 'placeholderSearch')} />
                    </InputGroup>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col">{t(inputPrefix + 'listOfOrganisationsLabel')}</div>
            </div>
        </div>
    );
};
