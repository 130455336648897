import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { adminTagConfig, TagIdsEnum } from '../../constant/navigation';

interface TagState {
    id: TagIdsEnum;
    title: string;
    isActive: boolean;
    component: () => JSX.Element;
}

const initialState: TagState[] = [...adminTagConfig];

export const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        currTag: (state, action: PayloadAction<TagState[]>) => {
            return action.payload;
        },
        translateTag: (state, action: PayloadAction<string[]>) => {
            return state.map((el, i) => ({ ...el, title: action.payload[i] }));
        },
    },
});

export const { currTag, translateTag } = tagSlice.actions;

export default tagSlice.reducer;
