import { FC } from 'react';
import { FormikProps } from 'formik'
import { FormTextField, Toggle } from '../../../../components';
import { ITournamentClasses, maxTeamsSingle } from '../../SellPage.constants';
import './ClassSwitchForm.scss';

type ClassSwitchFormProps = { index: number, formikArg: FormikProps<any>, count?: number } & ITournamentClasses;

export const ClassSwitchForm: FC<ClassSwitchFormProps> = ({formikArg, index, name, price, date, starts, team, count}) => {
    const isToggleDisabled = (!formikArg.getFieldProps(`class.${index}.toggle`)?.value && formikArg.values.class
        .filter(({ toggle }: {toggle: boolean}) => toggle).length > 2)  || (count || 0) >= maxTeamsSingle;
    return (<div className="class-switch-form">
    <div>
        <div className="class-switch-form__class-info">
            <div className="class-switch-form__class-info-container">
                <div className="class-switch-form__class-info-name">
                    <div>
                        <h6>{ name }</h6>
                    </div>
                    <div>
                        { price }€
                    </div>
                </div>
                <div className="class-switch-form__class-info-name class-switch-form__class-info-information">
                    <div>{ date }</div>
                    <div>{ starts }</div>
                </div>
            </div>
            <div className="d-flex">
                <Toggle name={`class.${index}.toggle`} disabled={isToggleDisabled}/>
            </div>
        </div>
    </div>
    {team && formikArg.getFieldProps(`class.${index}.toggle`)?.value && (<div className="d-flex row-cols-lg-2 d-sm-flex class-switch-form__form-wrapper">
        <div className="class-switch-form__form-input">
            <FormTextField
                name={`class.${index}.partnerFirstName`}
                placeholder={'Nombre del pareja'}
                label={'Nombre del pareja'}
            />
        </div>
        <div className="class-switch-form__form-input">
            <FormTextField
                name={`class.${index}.partnerLastName`}
                placeholder={'Apellido del pareja'}
                label={'Apellido del pareja'}
            />
        </div>
    </div>)}
</div>)};