import { api } from './api.config';

export const createBulkPaddles = async (data: any[]): Promise<any[]> => {
    const response = await api.post(`paddle/bulk`, {bulk: data});
    return response.data;
};

export const getAllPaddles = async (): Promise<any[]> => {
    const response = await api.get(`paddle`);
    return response.data;
};

export const removePaddle = async (id: number): Promise<any[]> => {
    const response = await api.delete(`paddle/${id}`);
    return response.data;
};