import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { initialData } from '../../../../../constant/location';
import { FormTextField } from '../../../../../components';
import { CourtSection } from '../CourtSection';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { updateTournamentInfo } from '../../../../../features/tournament/actionCreators';
import { locationTypeConverter } from '../../../../../utils/locationTypeConverter';
import { TagIdsEnum } from '../../../../../constant/navigation';
import { useParams } from 'react-router-dom';


export type DataCourts = {
    readonly id?: string | number;
    name: string;
    isEdit?: boolean;
};

export interface LocationValues {
    location: string;
    pinLocation: string | null;
    lat: number;
    lng: number;
    courtCount: number;
    courts: Array<DataCourts>;
}

export const LocationTag = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';
    const prefixValidation = 'validation-';
    const { communityId } = useParams<{ communityId: string }>();

    const LocationSchema = Yup.object().shape({
        courtCount: Yup.number().typeError(t(prefixValidation + 'mustBeANumber')).positive(t(prefixValidation + 'positive')),
    });
    const dispatch = useAppDispatch();
    const { data } = useAppSelector((state) => state.tournament);
    const formikInitialState = data
        ? {
              location: data.location || '',
              pinLocation: data.pinLocation || '',
              lat: data.lat,
              lng: data.lng,
              courtCount: data.courtCount || 1,
              courts: data.courts.map((court) => ({ ...court, isEdit: true })),
          }
        : { ...initialData };

    return (
        <div className="px-2">
            <Formik
                enableReinitialize={true}
                validationSchema={LocationSchema}
                initialValues={formikInitialState}
                onSubmit={(values: LocationValues) => {
                    data && dispatch(updateTournamentInfo(data.id, +communityId, locationTypeConverter(values)));
                }}
            >
                {({ values }) => (
                    <Form autoComplete="off" id={TagIdsEnum.LOCATION}>
                        <div className="my-3">
                            <h4 className="font-weight-bold">{t(prefix + 'address')}</h4>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 d-sm-block">
                                <FormTextField
                                    type="text"
                                    maxLength={50}
                                    placeholder={t(inputPrefix + 'placeholderName')}
                                    id="location"
                                    label={t(prefix + 'name')}
                                    name="location"
                                />
                                <FormTextField
                                    type="text"
                                    maxLength={50}
                                    placeholder={t(inputPrefix + 'placeholderPlace')}
                                    id="pinLocation"
                                    label={t(prefix + 'pin')}
                                    name="pinLocation"
                                />
                                {data?.pinLocation && <p>{data.pinLocation.toUpperCase()}</p>}
                            </div>
                        </div>
                        <CourtSection
                            data={{
                                dataCourts: values.courts,
                                courtAmount: values.courtCount,
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};
