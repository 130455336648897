import { isString } from './../../../../../utils/common.helpers';
import { IMatch } from '../PlayTag/PlayTag';

export const getMatchName = (t: (key: string) => string, match: IMatch) => {
    const homeTeamName = match.homeTeam?.name || match.homeTeamPlaceholder;
    const awayTeamName = match.awayTeam?.name || match.awayTeamPlaceholder;

    return (!homeTeamName && !awayTeamName && t('match-defaultMatchName')) || `${ homeTeamName } vs ${ awayTeamName }`;
};


export const getRoundCode = (round: string | number, isGold: boolean) => {
    switch (round) {
        case 11:
            return 'QF';
        case 12:
            return 'SF';
        case 13:
            return isGold ? 'FG' : 'FB';
        default:
            return `R${round}`;
    }
};


//New
export const getMatchId = (sortkey: string | number, isGroup: boolean, groupId: string | number | null, round: string | number, isGold: boolean | string) => {
    if(isString(isGold)) {
        isGold = isGold === 'true';
    }

    const groupStr = isGroup ? `G${groupId}` : 'E';

    return `M${sortkey}-${groupStr}-${getRoundCode(round, isGold as boolean)}`

};


export const convertToBooleanString = (val: any) => {
    if(typeof val === 'boolean') {
        return val.toString();
    } else if(typeof val === 'number') {
        return Boolean(val).toString();
    } else {
        return val;
    }
};
