import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BackIcon } from '../../assets/icons/BackIcon';

import { fetchTournamentWithClassListByTournamentId } from '../../apis/tournament';
import { useCallback, useEffect, useState } from 'react';
import { CustomSpinner, PlainDropdown } from '../../components';
import { TournamentScorecardPageContent } from './components/TournamentScorecardPageContent';

import './TournamentScorecardPage.scss';
import { TournamentState } from '../../types/tournament';

export const TournamentScorecardPage = () => {
    const prefix = 'tournamentInfo-'
    
    const { t } = useTranslation();
    const { id: tournamentId } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [classList, setClassList] = useState([] as any[]);
    const [selectedClass, setSelectedClass] = useState(null as any);
    const [selectedTournament, setSelectedTournament] = useState<TournamentState>();

    const getClassList = useCallback(async () => {
        setError(false);
        setLoading(true);
        try {
            if(isNaN(+tournamentId)) throw new Error();

            const { classes, ...tournament } = await fetchTournamentWithClassListByTournamentId(+tournamentId);
            setSelectedTournament(tournament as TournamentState);
            setClassList(classes);
            classes.length && setSelectedClass(classes[0]);
        } catch (e) {
            setError(true);
        }
        setLoading(false);
    }, [tournamentId])

    useEffect(() => {
        getClassList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId]);
    
    return (<div className="tournament-scorecard">
                <div className="tournament-scorecard__nav">
                    <>{window.localStorage.getItem('raqt-129001223') && (
                        <div className="tournament-scorecard__back-button-holder">
                            <NavLink to="/" className="tournament-scorecard__back-button chevronLeft">
                                <BackIcon h="20" w="16"/>
                            </NavLink>
                        </div>
                    )}</>

                    <div className="tournament-scorecard__title">
                        {selectedClass && <PlainDropdown selected={selectedClass} options={classList} callback={setSelectedClass} keyToShow="name"/>}
                    </div>
                </div>
                {!loading && !error && (<TournamentScorecardPageContent 
                                            tournament={selectedTournament}
                                            classId={selectedClass?.id} 
                                            selectedClassName={selectedClass?.name}
                                        />)}
                {loading && (<div className="tournament-scorecard__loading">
                    <div className="d-flex justify-content-center align-items-center">
                        <CustomSpinner />
                    </div>
                </div>)}
                {error && (<div className="tournament-scorecard__error" onClick={getClassList} role="button">
                                <h1>{t(prefix + 'error')}</h1>
                                <h2>{t(prefix + 'reload')}</h2>
                            </div>)}
            </div>);
};