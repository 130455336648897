import { FC, useEffect, useState } from "react";
import classnames from 'classnames';

import Popup from "reactjs-popup"
import { PopupProps } from "reactjs-popup/dist/types";

import { useDebounce } from "../../utils/hooks";

import './RaqtPopup.scss';

interface RaqtPopupProps {
    children: JSX.Element;
    content: JSX.Element | string;
    popupStyle: 'light' | 'dark';
    delay?: number;
}

export const RaqtPopup: FC<RaqtPopupProps & Omit<PopupProps, 'trigger'>> = ({children, content, popupStyle, delay = 1000, ...rest}) => {
    const [opened, setOpened] = useState<boolean>(false);

    const searchDebounced = useDebounce(opened, delay);

    useEffect(() => {
        opened && setOpened(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDebounced])

    return <div className={classnames([
                'raqt-popup',
                {
                    'raqt-popup_light': popupStyle === 'light',
                    'raqt-popup_dark': popupStyle === 'dark',
                }
            ])}>
                <Popup className={classnames([{
                        'raqt-popup_light': popupStyle === 'light',
                        'raqt-popup_dark': popupStyle === 'dark',
                    }])} open={opened} onOpen={() => setOpened(true)} trigger={ <div>{ children }</div> } {...rest}
                >
                    <div>{ content }</div>
                </Popup>
            </div>;
}