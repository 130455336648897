import { api } from './api.config';

import { ICurrentUser } from './../features/currentUser/currentUserSlice';

export const loginApi = async (data: { login: string, password: string }): Promise<ICurrentUser> => {
    const response = await api.post(`auth-password/sign-in`, {...data});
    return response.data;
};

export const checkPhoneApi = async (data: { phone: string }): Promise<{valid: boolean}> => {
    const response = await api.post(`auth-password/check-phone`, {...data});
    return response.data;
};

export const checkCodeApi = async (data: { phone: string, mfaCode: number }): Promise<{valid: boolean}> => {
    const response = await api.post(`auth-password/check-code`, {...data});
    return response.data;
};

export const signUpApi = async (data: { phone: string, password: string, mfaCode: number }): Promise<ICurrentUser> => {
    const response = await api.post(`auth-password/sign-up`, {...data});
    return response.data;
};


export const resetPasswordApi = async (data: { phone: string, password: string, mfaCode: number }): Promise<ICurrentUser> => {
    const response = await api.post(`auth-password/change-password`, {...data});
    return response.data;
};

export const checkTokenApi = async (): Promise<boolean> => {
    const response = await api.get(`auth-password/check-reset-password`);
    return response.data;
};

export const refreshApi = async (): Promise<Omit<ICurrentUser, 'refreshToken'>> => {
    const response = await api.get(`auth-password/refresh`);
    return response.data;
};
