import { ClassMatchType, MatchBracketType } from './enums';
import { Class, Court, Team, TournamentGroup, User } from './tournament';

export const itemsOnSameTimeSlot = (
    a: IMatchScheduleItem | IFreeSlotItem,
    b: IMatchScheduleItem | IFreeSlotItem
) => {
    return (
        (a.scheduleDate !== null || b.scheduleDate !== null) &&
        a.scheduleDate?.toString() === b.scheduleDate?.toString()
    );
};
export const isSameSlot = (a: IFreeSlotItem, b: IFreeSlotItem) => {
    return (
        a.day === b.day &&
        a.court.id === b.court.id &&
        a.scheduleDate.toString() === b.scheduleDate.toString()
    );
};

export const isFreeSlot = (item: any): item is IFreeSlotItem => !!item.isFreeSlot;
export type IFreeSlotItem = {
    isFreeSlot: boolean;
    day: number;
    court: Court;
    scheduleDate: string;
};

export const isScheduled = (item: any): item is IMatchScheduleItem | IFreeSlotItem =>
    !!item.scheduleDate;
export interface IMatchScheduleItem {
    id: number;
    name: string;
    classId: number;
    homeTeam: Team;
    awayTeam: Team;
    homePlayer1: User;
    homePlayer2: User | null;
    awayPlayer1: User;
    awayPlayer2: User | null;
    court: Court;
    type: ClassMatchType;
    status: number;
    isGold: boolean;
    tournamentGroup?: TournamentGroup;
    bracket: MatchBracketType;
    scheduleDate: string | null;
    startDate: string;
    endDate: string;
    sortKey: number;
    homeTeamPlaceholder: string;
    awayTeamPlaceholder: string;
    matchId: string;
    isAdditional: boolean;
}

export enum ScheduleMode {
    SEQUENTIAL,
}

export type ClassOption = {
    class: Class;
    courts: Court[];
    matchLengths: {
        [matchType in ClassMatchType]: number;
    };
};

export type IScheduleDayConstraints = {
    dayKey: number; // used to map form properly with Formik
    startTime: string;
    endTime: string;
    defaultMatchLength: number;
    defaultPauseLength: number;
    classes: Class[];
    classOptions: ClassOption[];
    courts: Court[];
};

export interface IScheduleConstraints {
    tournamentStartTime: string;
    tournamentEndTime: string;
    scheduleMode: ScheduleMode;
    days: IScheduleDayConstraints[];
    tournamentClasses: Class[];
    tournamentCourts: Court[];
}

export type IScheduleItem = IMatchScheduleItem | IFreeSlotItem;

export interface ICourtSchedule {
    court: Court;
    matches: IScheduleItem[];
}

export interface IDaySchedule {
    dayKey: number;
    courts: ICourtSchedule[];
}

export interface ITournamentSchedule {
    days: IDaySchedule[];
}

export interface ScheduleAndUnscheduledMatches {
    schedule: ITournamentSchedule;
    unscheduled: IMatchScheduleItem[];
}
