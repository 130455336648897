import { FC, useState } from 'react';
import classnames from 'classnames';

import './Switcher.scss';

interface ISwitcherOption {
    label: string;
    value: any;
}

interface SwitcherProps {
    switches: ISwitcherOption[];
    onSelect: (selected: any) => void;
    selectedValue?: any;
}

export const Switcher: FC<SwitcherProps> = ({
    switches = [],
    onSelect,
    selectedValue = null,
}) => {
    const [ active, setActive ] = useState<any | null>(selectedValue);

    const selectOption = (value: any) => {
        onSelect(value);
        setActive(value);
    } 

    return <div className="raqt-switcher">
        {switches.map(({label, value}, i) => 
                <div key={`switcher-key-${i}`} className={classnames('raqt-switcher__item', {
                    active: value === active
                })} onClick={() => selectOption(value)}>
                    { label }
                </div>)}
    </div>
};