import { useField } from "formik";
import { FC } from "react";
import { CheckIcon } from "../../assets/icons/CheckIcon";

import './Checkbox.scss';

interface CheckboxProps {
    name: string;
    disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({ name, disabled }) => {
    const [ , meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    const onCheckboxSelected = () => {
        setValue(!value);
    };
    
    return (
        <div className="checkbox__wrapper">
            {disabled && <div className="checkbox__disabled"></div>}
            <div className="checkbox" onClick={onCheckboxSelected}>
                { (value && <CheckIcon f="#2C2F30" h="35" w="35"/>) || null }
            </div>
        </div>
    );
}