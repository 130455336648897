import React, { FC } from 'react';
import './index.scss';
import { Button, ButtonProps } from 'react-bootstrap';

interface CustomButtonProps extends ButtonProps {
    btnText: string;
    cb?: () => void;
    children?: React.ReactNode;
    type?: 'submit' | 'reset' | 'button' | undefined;
}

export const CustomButton: FC<CustomButtonProps> = (props) => {
    const { btnText, cb, className, variant, children, type = 'button', ...rest } = props;
    return (
        <Button
            className={`raqtButton ${className || ''} my-2`}
            onClick={cb}
            variant={variant || 'dark'}
            type={type}
            {...rest}
        >
            <span className="px-2"> {btnText}</span>
            {children}
        </Button>
    );
};
