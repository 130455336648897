import { fetchClassListByTournamentId, updatePlayData } from './../../apis/tournament';
import { updateClassData, createClassData } from './../../apis/class';
import { selectClass } from './classSelectors';
import { RootState } from './../../app/store';
import { fetchClassesByTournamentId } from '../../apis/tournament';
import { AppDispatch } from '../../app/store';
import {classByIdUpdateSuccess, classFirstUpdateSuccess, classUpdating, classUpdatingError, setCurrentName } from './classSlice';

export const fetchTournamentClassList = (id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { classCollection, currentClassId } = selectClass(getState());

    if(classCollection.length && !currentClassId) {
        dispatch(setCurrentName(classCollection[0].classId));
    }

    if(classCollection.length) return;

    try {
        dispatch(classUpdating());
        const data = (await fetchClassesByTournamentId(id)).map((item) => ({...item, classId: item.id}));
        dispatch(classFirstUpdateSuccess(data.sort((a, b) => a.name.localeCompare(b.name))));
    } catch (e) {
        const error = e as Error;
        dispatch(classUpdatingError(error.message));
    }
};


export const fetchTournamentClassListPure = (id: number, force?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { classCollection, currentClassId } = selectClass(getState());

    if(classCollection.length && !currentClassId) {
        dispatch(setCurrentName(classCollection[0].classId));
    }

    if(!force && classCollection.length) return;

    try {
        dispatch(classUpdating());
        const data = (await fetchClassListByTournamentId(id)).map((item) => ({...item, classId: item.id}));
        const sortedData = data.reduce((acc, {...item}: any) => {
            if(!item.parentClassId) {
                item.subClasses = classCollection.filter(({classId, parentClassId}) => {
                    return classId === item.classId || item.classId === parentClassId;
                });
                acc.push(item);
            }
            return acc;
        }, [] as any[])
        .map(item => {
            return {
                ...item,
                subClasses: data.filter(cl => cl.parentClassId === item.classId),
            }
        })
        .filter(item => !item.parentClassId)
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce((acc, {subClasses, ...item}) => {
            acc.push(item);

            if(subClasses && subClasses.length) {
                acc.push(...subClasses);
            }

            return acc;
        }, []);
        dispatch(classFirstUpdateSuccess(sortedData));
    } catch (e) {
        const error = e as Error;
        dispatch(classUpdatingError(error.message));
    }
};

export const updateClassById =
    (tournamentId: number, classId: number | string, updatedData: any, formatChanged?: boolean, subclassesChanged?: boolean) =>
    async (dispatch: AppDispatch) => {
        try {
            let data; 

            dispatch(classUpdating());
            if(typeof classId === 'number') {
                data = await updateClassData(tournamentId, classId, updatedData);
            } else {
                const {id, classId, ...classDataForCreation} = updatedData;
                data = await createClassData(tournamentId, classDataForCreation);
            }

            if(formatChanged && (data.tournamentGroups?.length || data.tournamentCategories?.length || data.tournamentMatches?.length)) {
                await updatePlayData(data.id, {
                    id: data.id,
                    tournamentGroups: [],
                    tournamentCategories: [],
                    tournamentMatches: [],
                });
            }

            dispatch(classByIdUpdateSuccess({
                newData: data, 
                oldId: classId
            }));

            if(subclassesChanged) {
                dispatch(fetchTournamentClassListPure(tournamentId, true));
            }

        } catch (e) {
            const error = e as Error;
            dispatch(classUpdatingError(error.message));
        }
    };


