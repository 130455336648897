import { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { fetchPublicViewTournamentAPI, IPublicTournament } from '../../apis/tournament';
import { AppleIcon } from '../../assets/icons/AppleIcon';
import { BackIcon } from '../../assets/icons/BackIcon';
import { ChainIcon } from '../../assets/icons/ChainIcon';
import { GoogleStoreIcon } from '../../assets/icons/GoogleStoreIcon';
import { OpenIcon } from '../../assets/icons/OpenIcon';
import { ItemWrapper } from '../../components';
import { RaqtPopup } from '../../components/popup/RaqtPopup';
import { getReadableForPublioDate } from '../../utils/getStringDate';
import { AppLink } from '../signUpAndResetPassword/components/AppLinks';
import { CustomAccordionHeader } from '../tourDetails/common/components/CustomAccordionHeader';

import './PublicTournamentInfo.scss';

export const PublicTournamentInfo = () => {
    const prefix = 'publicTournament-';
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>()
    const [ tournament, setTournament ] = useState<IPublicTournament>({} as IPublicTournament)
    const [loading, setLoading] = useState<boolean>(true);

    const getData = async () => {
        try {
            setLoading(true);
            const data = await fetchPublicViewTournamentAPI(+id);
            setTournament(data);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }; 

    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return<div className='public-tournament-info'>
                {
                    (loading && 'loading...') || null
                }
                {
                    (!loading && <div>
                        <div className="tournament-players-stats__back-link">
                            <NavLink to={`/`} >
                                <BackIcon w="10" h="16" f="#0474EF" /> <span className="tournament-players-stats__back-text">{t(prefix + 'backButton')}</span>
                            </NavLink>
                        </div>
                        <div className="public-tournament-info__tournament-name">
                            {tournament.name}
                        </div>
                        <div className='public-tournament-info__tournament-card'>
                            <div className='public-tournament-info__tournament-card-wrapper'>
                                <Card>
                                    <div className='public-tournament-info__tournament-card-img-wrapper'>
                                        <img src={tournament.img || require('../../assets/images/noImage.png')} alt={tournament.name} />
                                    </div>
                                </Card>
                            </div>
                            <div className='public-tournament-info__tournament-card-wrapper'>
                                <div className="public-tournament-info__tournament-card-row">
                                    <div className="public-tournament-info__tournament-card-icons-wrapper public-tournament-info__tournament-card-icons-wrapper_center">
                                        <div className="public-tournament-info__tournament-card-open">
                                            <OpenIcon s="#2C2F30" h="42" w="42"/>
                                        </div>
                                        <div>
                                            <RaqtPopup popupStyle="dark" content={t('popup-copied')} position="top center">
                                                    { /* eslint-disable-next-line */}
                                                    <CopyToClipboard text={location.href}>
                                                        { /* eslint-disable-next-line */}
                                                        <a><ChainIcon  s="#2C2F30" h="42" w="42"/></a>
                                                    </CopyToClipboard>
                                            </RaqtPopup>
                                        </div>
                                    </div>
                                    <div className="public-tournament-info__tournament-card-download">
                                        <div className="public-tournament-info__tournament-card-icons-wrapper">
                                            <div>
                                                <AppleIcon />
                                            </div>
                                            <div>
                                                <GoogleStoreIcon />
                                            </div>
                                        </div>
                                        <div>
                                            <Trans i18nKey={prefix + 'download'} components={[
                                                /* eslint-disable-next-line */
                                                <AppLink link={location.href} title={t(prefix + 'downloadLink')} />,
                                            ]}/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="public-tournament-info__tournament-card-row">
                                        <div className="left">
                                            <div className="public-tournament-info__tournament-card-label">{t(prefix + 'commOrg')}</div>
                                            <div className="public-tournament-info__tournament-card-text">{tournament.creator?.firstName} {tournament.creator?.lastName}</div>
                                        </div>
                                        <div className="right">{tournament.icon && <img className="public-tournament-info__tournament-card-icon"
                                                                    src={tournament.icon} 
                                                                    alt={tournament.name} />}
                                        </div>
                                    </div>
                                    <div className="public-tournament-info__tournament-card-row">
                                        <div className="left">
                                            <div className="public-tournament-info__tournament-card-label">{t(prefix + 'about')}</div>
                                            <div className="public-tournament-info__tournament-card-text">{tournament.description}</div>
                                        </div>
                                        <div className="right"></div>
                                    </div>
                                </div>
                                <div className="public-tournament-info__tournament-card-location">
                                    <div className="public-tournament-info__tournament-card-label">{t(prefix + 'location')}</div>
                                    <div className="public-tournament-info__tournament-card-text">{tournament.location}</div>
                                </div>
                                <div>
                                    <div className="public-tournament-info__tournament-card-row">
                                        <div className="left">
                                            <div className="public-tournament-info__tournament-card-label">{t(prefix + 'regOpen')}</div>
                                            <ItemWrapper white={true}>
                                                {getReadableForPublioDate(tournament.registrationOpen || '')}
                                            </ItemWrapper>
                                        </div>
                                        <div className="right">
                                            <div className="public-tournament-info__tournament-card-label">{t(prefix + 'regClose')}</div>
                                            <ItemWrapper white={true}>
                                                {getReadableForPublioDate(tournament.registrationClose || '')}
                                            </ItemWrapper>
                                        </div>
                                    </div>
                                    <div className="public-tournament-info__tournament-card-row">
                                        <div className="left">
                                            <div className="public-tournament-info__tournament-card-label">{t(prefix + 'start')}</div>
                                            <ItemWrapper white={true}>
                                                {getReadableForPublioDate(tournament.startDate || '')}
                                            </ItemWrapper>
                                        </div>
                                        <div className="right">
                                            <div className="public-tournament-info__tournament-card-label">{t(prefix + 'end')}</div>
                                            <ItemWrapper white={true}>
                                                {getReadableForPublioDate(tournament.endDate || '')}
                                            </ItemWrapper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="public-tournament-info__additional-info">
                               {t(prefix + 'additionalInfo')}
                            </div>

                            {
                                tournament.sections?.map((i, index) => {
                                    return <Accordion className="my-3" key={`accordion-${index}`}>    
                                                <Card>
                                                    <Card.Header className="p-0 bg-white border-0">
                                                        <CustomAccordionHeader eventKey={index.toString()}>
                                                            <div className='public-tournament-info__accordion-title'>{i.title}</div>
                                                        </CustomAccordionHeader>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={index.toString()}>
                                                        <Card.Body className="p-2">
                                                            <div className='public-tournament-info__accordion-content' 
                                                                dangerouslySetInnerHTML={{ __html: i.description || ''}} >
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                }) || null
                            }
                        </div>

                    </div>) || null
                }
            </div>
}