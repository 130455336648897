import { Formik, Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { loginApi } from '../../apis/auth';
import { ChevronIcon } from '../../assets/icons/ChevronIcon';

import { CustomButton, FormTextField, PhoneInput } from '../../components';
import { selectUser } from '../../features/currentUser/currentUserSelectors';
import { setUser } from '../../features/currentUser/currentUserSlice';
import { useAppSelector } from '../../hooks/redux';

import './login.scss';


export const Login = () => {
    const prefixValidation = 'validation-'
    const prefix = 'signIn-';

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {accessToken, refreshToken} = useAppSelector(selectUser);
    const [ loginError, setLoginError] = useState(false);

    const loginSchema = Yup.object().shape({
        login: Yup.string().required(t(prefixValidation + 'phoneRequired')),
        password: Yup.string().required(t(prefixValidation + 'passwordRequred')).max(50),
    });

    useEffect(() => {
        accessToken && refreshToken && history.push('/communities');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[accessToken, refreshToken])

    const initialValues = {
        login: '',
        password: '',
        save: true,
    }

    const onSubmit = async ({ save, ...values }: {login: string, password: string, save: boolean}) => {
        try {
            setLoginError(false);
            const user = await loginApi(values);

            dispatch(setUser({...user, save}));
        } catch (e) {
            setLoginError(true);
            console.log(e);
        }
    };

    return (<div className="raqt-login">
                <div className="raqt-login__main">
                   <div className="raqt-login__header">
                        <span>{t(prefix + 'header')}</span>
                    </div>
                    <Formik validationSchema={loginSchema} initialValues={initialValues} onSubmit={onSubmit}>
                        <Form className="raqt-login__form">
                            <div>
                                <Field name="login" component={PhoneInput} label={t(prefix + 'phonePlaceholder')}/>
                            </div>
                            <div className="raqt-login__password-block">
                                <FormTextField
                                    name="password"
                                    type="password"
                                    label={t(prefix + 'passwordPlaceholder')}
                                />
                                {loginError && <div className="field-error text-danger fs-7 raqt-login__mobile-error">{t(prefix + 'loginError')}</div>}
                                <div className="raqt-login__submit">
                                    <CustomButton type="submit" btnText={t(prefix + 'submit')}>
                                        <ChevronIcon isClick={false} width="50px" height="25px" fill="#fff"/>
                                    </CustomButton>
                                </div>
                            </div>
                            {loginError && <div className="field-error text-danger fs-7 raqt-login__desktop-error">{t(prefix + 'loginError')}</div>}
                        </ Form>
                    </Formik>
                    <div className="raqt-login__links">
                        {/* <div className="raqt-login__link">
                            <Link to="/reset-password"><a href='replace'>{t(prefix + 'resetPassword')}</a></Link>
                        </div> */}
                       {/*  <div className="raqt-login__link raqt-login__link_padding">
                            <Link to="/sign-up"><a href='replace'>{t(prefix + 'createAccount')}</a></Link>
                        </div> */}
                    </div>
                </div>
            </div>)
};