import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { CloseCircle } from '../../assets/icons/CloseCircle';

import { User } from '../../types/tournament';
import { RoundedAvatar } from '..';

interface MemberTournamentCardProps {
    onClick?: (arg: number) => void;
    member: User;
}

export const MemberTournamentCard: FC<MemberTournamentCardProps> = (props) => {
    const {
        onClick,
        member: { userName, id, avatar, firstName, lastName },
    } = props;
    const fullName = firstName && lastName ? firstName + ' ' + lastName : userName;
    return (
        <div
            style={{ minHeight: '43px' }}
            className="d-flex justify-content-lg-center justify-content-between align-items-center mr-2 border bg-white my-2 br-16"
        >
            <RoundedAvatar avatar={avatar} alt="User avatar" name="user" />
            <div className="d-flex flex-grow-1">
                <span className="px-2">{fullName}</span>
            </div>
            {onClick ? (
                <Button
                    className="border-0 btnDeleteMember"
                    variant="outline-dark"
                    onClick={() => onClick(id)}
                >
                    <CloseCircle />
                </Button>
            ) : null}
        </div>
    );
};
