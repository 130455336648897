import { ClassTeam } from '../ClassesTag/ClassesTag';

export const shuffleTeams = (teams: ClassTeam[] = []): ClassTeam[] => {
    const maxNumber = teams.length;

    const newSeedArray = (new Array(maxNumber))
                            .fill(null)
                            .map((i,index) => index + 1)
                            .filter(v => !teams.find(({seed, shuffled}) => (seed === v && !shuffled)));

    return (JSON.parse(JSON.stringify(teams))).map((i: ClassTeam) => {
        if(!i.seed || i.shuffled) {
            const indexFromNewSeedArray = Math.floor(Math.random() * newSeedArray.length);
            i.seed = newSeedArray[indexFromNewSeedArray];
            newSeedArray.splice(indexFromNewSeedArray,1);
            i.shuffled = true;
        }
        return i;
    })
};

export const shuffleTeams2 = (teams: ClassTeam[] = []): ClassTeam[] => {
    const maxNumber = teams.length;

    const newSeedArray = (new Array(maxNumber))
                            .fill(null)
                            .map((i,index) => index + 1)
                            .filter(v => !teams.find(({seed}) => seed === v));
                            
    const updatedTeams = (JSON.parse(JSON.stringify(teams))).filter((i: ClassTeam) => Boolean(i.seed));

    return updatedTeams.map((i: ClassTeam) => {
        const indexFromNewSeedArray = Math.floor(Math.random() * newSeedArray.length);
        i.seed = newSeedArray[indexFromNewSeedArray];
        newSeedArray.splice(indexFromNewSeedArray,1);
        return i;
    })
};