import { FC } from "react";
import { useTranslation } from "react-i18next";

import { BubbleSequence } from "../BubbleSequence";
import { BubbleWithText } from "../BubbleWithText";

import './TournamentCardScoreSection.scss';

interface TournamentCardScoreSectionProps {
    sets?: number;
}

export const TournamentCardScoreSection: FC<TournamentCardScoreSectionProps> = ({ sets = 3 }) => {
    const prefix = 'printCardScore-';

    const { t } = useTranslation();

    const forMapingRows = (new Array(sets)).fill(null);

    return (<div className="tournament-card-score-section">
        <div className="tournament-card-score-section__server-subsection">
            <div className="tournament-card-score-section__title">
                {t(prefix + 'server')}
            </div>
            {forMapingRows.map((v, index) => (<div key={`server-${index}`} className="tournament-card-score-section__server-wrapper">
                <BubbleWithText content={t(prefix + 'serverBubble')} />
            </div>))}
        </div>
        <div className="tournament-card-score-section__score-subsection">
            <div className="tournament-card-score-section__title">
                {t(prefix + 'score')}
            </div>
            {forMapingRows.map((v, index) => (<BubbleSequence key={`score-${index}`} amount="21" />))}
        </div>
        <div className="tournament-card-score-timeouts-subsection">
            <div className="tournament-card-score-section__title">
                {t(prefix + 'timeouts')}
            </div>
            {forMapingRows.map((v, index) => (<BubbleSequence key={`timeouts-${index}`} amount="2" />))}
        </div>
    </div>);
};