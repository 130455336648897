import React from 'react';
import { Form } from 'react-bootstrap';
import { Field, FieldProps, ErrorMessage, FormikErrors } from 'formik';
import './index.scss';

interface FormTextFieldProps {
    label?: React.ReactNode;
    name: string;
    type?: 'text' | 'description' | 'password' | 'date' | 'time' | 'datetime-local' | 'number';
    className?: string;
    placeholder?: string;
    id?: string;
    index?: number | string | undefined;
    maxLength?: number;
    min?: number;
    max?: number;
    pattern?: string;
    disabled?: boolean;
}

export const FormTextField = ({
    label = undefined,
    maxLength,
    name,
    type = 'text',
    className = '',
    id = '',
    placeholder = '',
    index = undefined,
    max,
    min,
    pattern,
    disabled,
}: FormTextFieldProps) => {
    return (
        <div className={className || 'py-3'}>
            {label && (
                <label htmlFor={name} className="font-weight-normal">
                    {label}
                </label>
            )}
            
            <Field name={name} id={id} disabled={disabled}>
                {({ field, form: { touched, errors }, meta }: FieldProps) => {
                    const isError = touched && errors.hasOwnProperty(name) && errors[name];
                    const isErrorArField = index ? (errors[name.split('.')[0]] as FormikErrors<any>[]) : false;
                    const fieldError = isErrorArField && isErrorArField[index as number];

                    const textField =
                        type === 'description' ? (
                            <textarea
                                className="form-control descriptionTextArea"
                                placeholder={placeholder}
                                {...field}
                            />
                        ) : null;
                    return (
                        <>
                            {textField || (
                                <Form.Control
                                    {...field}
                                    type={type}
                                    disabled={disabled}
                                    maxLength={maxLength}
                                    min={min}
                                    max={max}
                                    pattern={pattern}
                                    className={`${className} form-control ${
                                        isError || fieldError ? 'is-invalid' : ''
                                    }`}
                                    placeholder={placeholder}
                                />
                            )}
                        </>
                    );
                }}
            </Field>
            <ErrorMessage component="div" name={name} className="field-error text-danger fs-7" />
        </div>
    );
};
