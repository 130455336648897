import { FC } from "react";

import './TournamentScorecardPageSection.scss';

interface TournamentScorecardPageSectionProps {
    title: string;
    children: JSX.Element | string;
}

export const TournamentScorecardPageSection: FC<TournamentScorecardPageSectionProps> = ({ title, children }) => 
(<div className="tournament-scorecard-page-section">
    <div className="tournament-scorecard-page-section__title">{title}</div>
    <div className="tournament-scorecard-page-section__children">{children}</div>
</div>)