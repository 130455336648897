import { useTranslation } from 'react-i18next';

import { getHhmmTimeString } from '../schedule.helpers';
import { useAppSelector } from '../../../../../hooks/redux';
import { selectSchedule } from '../../../../../features/schedule/scheduleSelectors';
import { ClassFormatEnum, ClassMatchType } from '../../../../../types/enums';
import { Class } from '../../../../../types/tournament';
import { IMatchScheduleItem, isScheduled } from '../../../../../types/schedule';

interface MatchSlotItemProps {
    item: IMatchScheduleItem;
}

export const MatchSlotItem = ({ item }: MatchSlotItemProps) => {
    const { classIdToObject } = useAppSelector(selectSchedule);

    const classColor: string = getColorForClass(+item.classId);
    const itemIsScheduled = isScheduled(item);

    return (
        <>
            <div className="card-header text-light " style={{ backgroundColor: classColor }}>
                <div className="row">
                    <div className="col-auto fw-bold">{classIdToObject[item.classId].name}</div>
                    <div className="col-auto fw-bold">{item.sortKey} </div>
                </div>
            </div>
            <ul className="list-group list-group-flush">
                <RoundType item={item} classObject={classIdToObject[item.classId]} />
                <li className="list-group-item">
                    <div className="row">
                        <div className="col">
                            {itemIsScheduled ? getHhmmTimeString(item.scheduleDate!) : '--:--'}
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col">
                            {itemIsScheduled ? item.court.name : '-'}
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col">{item.matchId}</div>
                    </div>
                </li>

                <PlayersFromMatches item={item} classObject={classIdToObject[item.classId]} />
            </ul>
        </>
    );
};

const PlayersFromMatches = ({
    item,
    classObject,
}: {
    item: IMatchScheduleItem;
    classObject: Class;
}) => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const isGroupFormat = classObject.format === ClassFormatEnum.GROUP;

    let players: string;
    if (isGroupFormat) {
        players = item.name;
    } else {
        const homePlayer = item.homePlayer1
            ? item.homePlayer2
                ? item.homePlayer1.userName + '/' + item.homePlayer2.userName
                : item.homePlayer1.userName
            : item.homeTeamPlaceholder
            ? item.homeTeamPlaceholder
            : '-';
        const awayPlayer = item.awayPlayer1
            ? item.awayPlayer2
                ? item.awayPlayer1.userName + '/' + item.awayPlayer2.userName
                : item.awayPlayer1.userName
            : item.awayTeamPlaceholder
            ? item.awayTeamPlaceholder
            : '-';
        if (homePlayer === '-' && awayPlayer === '-') {
            players = t(prefix + 'initialRound');
        } else {
            players = homePlayer + ' vs ' + awayPlayer;
        }
    }

    const setSmallFont = players.length > 25;
    return (
        /* Custom style set so that schedule card always have same dimensions */
        <li
            className={'list-group-item ' + (setSmallFont ? 'fs-7 d-flex align-items-center' : '')}
            style={{ height: '41px' }}
        >
            {players}
        </li>
    );
};

const RoundType = ({ item, classObject }: { item: IMatchScheduleItem; classObject: Class }) => {
    const isGroupFormat = classObject.format === ClassFormatEnum.GROUP;
    const isFinal = item.type === ClassMatchType.FINAL;
    const isWinnersBracket = item.bracket === 1;

    return (
        <li
            className="list-group-item"
            style={{
                backgroundColor: isFinal ? (item.isGold ? 'gold' : 'orange') : '',
            }}
        >
            <div className="row">
                <div className="col fs-7">
                    {isFinal
                        ? item.isGold
                            ? item.isAdditional
                                ? 'Add. Final'
                                : 'Final'
                            : isGroupFormat
                            ? 'Bronze'
                            : 'Losers Final'
                        : 'Round ' +
                          (isGroupFormat
                              ? item.type +
                                (item.tournamentGroup ? ' ' + item.tournamentGroup.name : '')
                              : isWinnersBracket
                              ? 'W' + item.type
                              : 'L' + item.type)}
                </div>
            </div>
        </li>
    );
};

function getColorForClass(classId: number) {
    return webColors[classId % webColors.length];
}

const webColors = [
    'goldenrod',
    'mediumorchid',
    'mediumvioletred',
    'brown',
    'navy',
    'darkolivegreen',
    'springgreen',
    'darkturquoise',
    'palevioletred',
    'saddlebrown',
    'deepskyblue',
    'coral',
    'burlywood',
    'maroon',
    'darkmagenta',
    'darkblue',
    'slateblue',
    'cornflowerblue',
    'green',
    'darksalmon',
    'blueviolet',
    'magenta',
    'indianred',
    'darkkhaki',
    'lightskyblue',
    'mediumturquoise',
    'chocolate',
    'teal',
    'darkred',
    'crimson',
    'lightsteelblue',
    'orangered',
    'sienna',
    'olivedrab',
    'lightsalmon',
    'palegreen',
    'darkslateblue',
    'red',
    'cadetblue',
    'rebeccapurple',
    'rosybrown',
    'limegreen',
    'orchid',
    'khaki',
    'purple',
    'darkviolet',
    'mediumseagreen',
    'dodgerblue',
    'salmon',
    'skyblue',
    'lawngreen',
    'blue',
    'aquamarine',
    'tomato',
    'royalblue',
    'mediumaquamarine',
    'firebrick',
    'darkorange',
    'chartreuse',
    'turquoise',
    'darkorchid',
    'lightseagreen',
    'midnightblue',
    'mediumblue',
    'indigo',
    'tan',
    'darkgoldenrod',
    'darkcyan',
    'hotpink',
    'steelblue',
    'seagreen',
    'lime',
    'violet',
    'yellowgreen',
    'fuchsia',
    'peru',
    'deeppink',
    'black',
    'lightcoral',
    'olive',
    'sandybrown',
    'mediumpurple',
];
