import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../../../components';
import { useAppDispatch } from '../../../../../hooks/redux';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';
import { useState } from 'react';
import { removeSchedule } from '../../../../../features/schedule/scheduleActions';

export const RemoveScheduleButton = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const dispatch = useAppDispatch();

    const [clicks, setClicks] = useState(0);

    const toggleFreeEditMode = () => {
        if (clicks === 1) {
            dispatch(removeSchedule(t));
        } else {
            setClicks(clicks + 1);
        }
    };

    return (
        <CustomButton btnText="" variant="outline-danger" cb={toggleFreeEditMode}>
            <CloseCircle />
            {clicks === 1 ? t(prefix + 'removeScheduleBtnConfirm') : t(prefix + 'removeScheduleBtn')}
        </CustomButton>
    );
};
