import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageListType } from 'react-images-uploading';
import { Form, ModalProps } from 'react-bootstrap';
import { ModalBox, ButtonImagePreview } from '../../../components';
import { useAppSelector } from '../../../hooks/redux';
import uniqId from 'uniqid';
import { ConfigData } from '../../../types/adminConfiguration';
import { uploadImage } from '../../../apis/tournament';

export const SponsorModal = (props: ModalProps) => {
    const { t } = useTranslation();
    const setConfigData = useAppSelector((state) => state.modals.data);
    const [sponsor, setSponsor] = useState({
        url: '',
        img: '',
        value: '',
    });

    const prefix = 'modal-';
    const inputPrefix = 'input-';

    const handleSave = useCallback(() => {
        setConfigData((prevSate: ConfigData) => ({
            ...prevSate,
            sponsors: [...prevSate.sponsors, { id: uniqId(), ...sponsor }],
        }));
        props.onHide && props.onHide();
    }, [props, setConfigData, sponsor]);

    const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSponsor((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value.trim(),
        }));
    };

    const onChange = useCallback(
        (imageList: ImageListType) => {
            const formData = new FormData();
            formData.append('file', imageList[0]?.file || '');
            imageList[0].file &&
                uploadImage(formData, 'sponsor')
                    .then((data) => {
                        setSponsor((prevState) => ({ ...prevState, img: data.url }));
                    })
                    .catch((e) => {
                        console.log(e);
                    });
        },
        []
    );

    return (
        <ModalBox
            {...props}
            handleSave={handleSave}
            isDisabled={!Boolean(sponsor.value && sponsor.url)}
        >
            <div className="d-flex flex-column">
                <p className="font-weight-normal mb-1">{t(prefix + 'sponsorName') + ':'}</p>
                <Form.Control
                    autoComplete="off"
                    name="value"
                    placeholder={t(inputPrefix + 'placeholderName')}
                    maxLength={50}
                    onChange={handleInput}
                />
                <p className="font-weight-normal my-1">{t(prefix + 'url') + ':'}</p>
                <Form.Control
                    autoComplete="off"
                    name="url"
                    placeholder={t(inputPrefix + 'placeholderUrl')}
                    onChange={handleInput}
                />
                <ButtonImagePreview
                    title={t(prefix + 'image') + ':'}
                    onChange={onChange}
                    src={sponsor.img}
                    alt="Sponsor logo"
                />
            </div>
        </ModalBox>
    );
};
