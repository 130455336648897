import classNames from 'classnames';
import { FC } from 'react';

import './TournamentPrintCardInfoBox.scss';

interface TournamentPrintCardInfoBoxProps {
    title: string;
    children: JSX.Element | string;
    className?: string;
} 

export const TournamentPrintCardInfoBox: FC<TournamentPrintCardInfoBoxProps> = ({ title, children, className = '' }) => 
(<div className={classNames('tournament-print-card-info-box',className)}>
    <div className="tournament-print-card-info-box__title">{title}</div>
    <div className="tournament-print-card-info-box__content">{children}</div>
</div>)