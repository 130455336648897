import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomSpinner } from '../../components';

import './CreativeTourDetail.scss';

const CreativeTags = lazy(() =>
    import('./common/components/CreativeTags/CreativeTags').then(({ CreativeTags }) => ({
        default: CreativeTags,
    }))
);

export const CreativeTourDetail = () => {
    const { t } = useTranslation();

    const prefix = 'creativeDetail-';

    return (
        <div className="creative-tour-detail px-lg-5 px-sm-2 d-flex flex-grow-1 flex-column">
            {
                <Suspense
                    fallback={
                        <div className="d-flex justify-content-center align-items-center">
                            <h2 className="mx-2 mb-0">{t(prefix + 'loading')}</h2>
                            <CustomSpinner />
                        </div>
                    }
                >
                    <CreativeTags />
                </Suspense>
            }
        </div>
    );
};
