import { FC, useEffect, useState } from 'react';
import { BubbleTabController } from '../BubbleTabController';
import { TournamentScorecardPageStandingsSwitcher } from '../TournamentScorecardPageStandingsSwitcher';

import { IStandingRound , IStandingGroup } from '../../TournamentScorecardPage.types';

import { ClassFormatEnum } from '../../../../types/enums';

import './StandingsTournametList.scss';

interface StandingsTournametListProps {
    matches: (IStandingRound | IStandingGroup)[];
    format: ClassFormatEnum;
}

export const StandingsTournametList: FC<StandingsTournametListProps> = ({ matches = [], format }) => {
  const [selectedStanding, setSelectedStanding] = useState(matches[0] || {} as IStandingRound | IStandingGroup);
  if((selectedStanding as IStandingGroup)?.teams) {
    (selectedStanding as IStandingGroup)?.teams.sort((a, b) => {
        switch (true) {
            case a.matchWin < b.matchWin: 
                return 1;
            case a.matchWin > b.matchWin: 
                return -1;
            case (a.matchWin === b.matchWin && a.gameWin < b.gameWin): 
                return 1;
            case (a.matchWin === b.matchWin && a.gameWin > b.gameWin): 
                return -1;
            case (a.matchWin === b.matchWin &&
                a.gameWin === b.gameWin &&
                a.points < b.points): 
                return 1;
            case (a.matchWin === b.matchWin &&
                a.gameWin === b.gameWin &&
                a.points > b.points): 
                return -1;
            default: 
                return b.id - a.id;    
        }
      });
  }
  useEffect(() => {
    setSelectedStanding(matches[0] || {} as IStandingRound | IStandingGroup)
  }, [matches]);
  
  return (<div className="standings-tournamet-list">
        <div className="standings-tournamet-list__select-holder">
            <BubbleTabController selected={selectedStanding} data={matches} keyToShow="name" callback={setSelectedStanding}/>
        </div>
        
        <div className="standings-tournamet-list__info-card">
            <TournamentScorecardPageStandingsSwitcher format={format} {...selectedStanding}/>
        </div>   
    </div>)
};