import { LocationValues } from '../pages/tourDetails/Location/components/LocationTag/LocationTag';

export const locationTypeConverter = (locationValues: LocationValues): LocationValues => {
    const courts = locationValues.courts.map((court) => {
        if (typeof court.id === 'string') {
            return { name: court.name };
        } else {
            return { id: court.id, name: court.name };
        }
    });
    return {
        location: locationValues.location,
        pinLocation: locationValues.pinLocation,
        lat: Number(locationValues.lat),
        lng: Number(locationValues.lng),
        courtCount: Number(locationValues.courtCount),
        courts,
    };
};
