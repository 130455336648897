import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { fetchClassesInfoAPI } from '../../apis/tournament';
import { BackIcon } from '../../assets/icons/BackIcon';
import { RaqtTable, Switcher } from '../../components';
import { IRaqtTableConfig } from '../../components/table/RaqtTable';

import './TournamentClassesStats.scss';

interface IClassListStats {
    price: string;
    vat: string;
    maxPlayers: number | string;
    registered: number | string;
    name: string;
}

export const TournamentClassesStats = () => {
    const prefix = 'tournamentStatsClasses-';

    const { t } = useTranslation();
    const [classListStats, setClassListStats] = useState<IClassListStats[]>([]);
    // const [isLoading, setIsloading] = useState(false);
    const { id , communityId } = useParams<{ id: string, communityId: string }>();

    const classList = [
        {label: t(prefix + 'defaultLabel'), value: null},
    ];

    const getClasses = async () => {
        // setIsloading(true);

        try {
            const { classesInfo }  = await fetchClassesInfoAPI(+id);
            const classes = [
                classesInfo.reduce((acc, item) => {
                    acc.registered += +item.registered;
                    acc.price += +item.price;
                    if(!acc.currency) {
                        acc.currency = item.currency;
                    }

                    return acc;
                }, {
                    currency: '',
                    maxPlayers: null,
                    name: t(prefix + 'total'),
                    price: 0,
                    registered: 0,
                    vat: null,
                }),
                ...classesInfo,
            ].map((item, index) => ({
                // if it's "total" then do not use default values
                price: (index && `${item.currency} ${item.price || 0}`.toLocaleUpperCase()) || '',
                vat: (index && `${item.vat || 0}`) || '',
                maxPlayers: (index && (item.maxPlayers || 0)) || '',
                registered: item.registered || 0,
                name: item.name
            }))
            setClassListStats(classes as IClassListStats[]);
        } catch (e) {
            setClassListStats([]);
        }

        // setIsloading(false);
    }

    const tableConfig: IRaqtTableConfig = {
        blankHead: true,
        columns: [
            {
                label: t(prefix + 'class'),
                key: 'name',
                position: 'left',
                columnSize: 'big',
            },
            {
                label: t(prefix + 'price'),
                key: 'price',
                position: 'right',
                columnSize: 'small',
            },
            {
                label: t(prefix + 'vat'),
                key: 'vat',
                position: 'center',
                columnSize: 'small',
            },
            {
                label: t(prefix + 'maxPlayers'),
                key: 'maxPlayers',
                position: 'center',
                columnSize: 'small',
            },
            {
                label: t(prefix + 'registered'),
                key: 'registered',
                position: 'center',
                columnSize: 'small',
            },
        ]
    }

    useEffect(() => {
        getClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]) 
    
    return <div className="tournament-classes-stats">
        <div className="tournament-players-stats__title">{t(prefix + 'title')}</div>
        <div className="tournament-players-stats__back-link">
            <NavLink to={`/community/${communityId}/tournament/${id}/tags/dashboard`} >
                <BackIcon w="10" h="16" f="#0474EF" /> <span className="tournament-players-stats__back-text">{t('tournamentDashboard-backButton')}</span>
            </NavLink>
        </div>
        <Switcher switches={classList} onSelect={() => {}} selectedValue={() => {}} />
        <div className="tournament-players-stats__table-holder">
            <RaqtTable config={tableConfig} data={classListStats} />
        </div>
    </div>
}