import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { ModalBox, RoundedAvatar } from '../../../../../components';
import { customStyles } from '../../../../../components/modal/MemberModal';
import { useAppSelector } from '../../../../../hooks/redux';
import { IMatch } from '../PlayTag/PlayTag';
import { User } from '../../../../../types/tournament';
import { imgOptionUserLabel } from '../../../../../utils/imgOptionUserLabel';

export const MatchPlayerModal = (props: ModalProps) => {
    const { t } = useTranslation();
    const { classCollection, currentClassId } = useAppSelector((state) => state.class);
    const { matches, setMatches, teamId, fieldName, matchId } = useAppSelector(
        (state) => state.modals.data
    );

    const prefix = 'modal-';
    const inputPrefix = 'input-';

    const [multiSelections, setMultiSelections] = useState<User[]>([]);
    const team = classCollection
        .find((el) => el.classId === currentClassId)
        ?.teams.find((team) => team.id === teamId);
    const userOptions = team?.users.map((user) => imgOptionUserLabel(user));

    //@ts-ignore
    const tournamentMatches = Object.values(matches).map((match: IMatch) =>
        match.id === matchId
            ? {
                  ...match,
                  [fieldName + 1]: multiSelections[0] || null,
                  [fieldName + 2]: multiSelections[1] || null,
              }
            : match
    );

    const handleSelect = useCallback(
        (e: MultiValue<{ value: string; label: string }>) => {
            const users = e.map((option) => {
                return team?.users.find((user) => user.id === Number(option.value)) || ({} as User);
            });

            setMultiSelections(users);
        },
        [team]
    );

    const handleSave = useCallback(() => {
        multiSelections.length &&
            setMatches(
                'tournamentMatches',
                tournamentMatches.reduce((acc: any, item) => {
                    acc[`${item.id}`] = item;
                    return acc;
                }, {})
            );
        props.onHide && props.onHide();
    }, [multiSelections.length, props, setMatches, tournamentMatches]);

    return (
        <ModalBox {...props} title={t(prefix + 'addPlayers2')} handleSave={handleSave}>
            <Select
                styles={customStyles}
                isMulti
                name="players"
                placeholder={t(inputPrefix + 'placeholderSelect')}
                options={userOptions}
                className="basic-multi-select holder-select"
                classNamePrefix="select"
                // @ts-ignore
                onChange={handleSelect}
                formatOptionLabel={(data: any) => (
                    <div className="d-flex align-items-center">
                        <RoundedAvatar avatar={data.avatar} alt="User avatar" name="user" />
                        {data.label}
                    </div>
                )}
            />
        </ModalBox>
    );
};
