import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signUpApi } from '../../apis/auth';
import { setUser } from '../../features/currentUser/currentUserSlice';
import { AppLink } from './components/AppLinks';
import { ChecksAndPasswordFormGroup } from './components/ChecksAndPasswordFormGroup';

import './SignUp.scss';

export const SignUp = () => {
    const prefix = 'signUp-'

    const history = useHistory();
    const { t } = useTranslation();    
    const dispatch = useDispatch();


    const submitSignUp = async (values: {phone: string, password: string, mfaCode: number}) => {
        try {
            const user = await signUpApi(values);

            dispatch(setUser({...user, save: true}));
            history.push('/communities');
        } catch (e) {
            console.log(e);
        }
    }

    return <div className="raqt-sign-up">
                <div className="raqt-sign-up__title">{t(prefix + 'title')}</div> 
                <div className="raqt-sign-up__subtitle">{t(prefix + 'subtitle')}</div>
                <div className="raqt-sign-up__requirements">
                    <Trans i18nKey={prefix + 'requirements'} components={[
                        <AppLink link="https://apps.apple.com/us/app/raqt-chat/id1617653147" title={t(prefix + 'apple')} />,
                        <AppLink link="https://play.google.com/store/apps/details?id=com.boilerplatee" title={t(prefix + 'google')} />,
                    ]}/>
                </div>
                <ChecksAndPasswordFormGroup onSubmit={submitSignUp}/>
            </div>
};