import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICurrentUser {
    accessToken: string;
    refreshToken: string;
};

const initialStateFunc = (): ICurrentUser => ({
    accessToken: localStorage.getItem('accessToken') || null, 
    refreshToken: localStorage.getItem('refreshToken') || null,
} as ICurrentUser);
const initialState = initialStateFunc();

export const currentUser = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        setUser: (state, {payload: {save, ...payload}}: PayloadAction<ICurrentUser & { save?: boolean }>) => {
            save && localStorage.setItem('accessToken', payload.accessToken);
            save && localStorage.setItem('refreshToken', payload.refreshToken);
            return payload;
        },
        refreshToken: (state, action: PayloadAction<Omit<ICurrentUser, 'refreshToken'>>) => {
            localStorage.setItem('accessToken', action.payload.accessToken)
            return {...state, ...action.payload };
        },
        logout: () => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            return initialStateFunc()
        },
    }
});


export const {
    setUser,
    logout,
    refreshToken,
} = currentUser.actions;

export default currentUser.reducer;