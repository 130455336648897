export const prepareForView = (data: any[]) => data.map(({name, ...rest}) => ({
    ...rest,
    value: name
}));

export const removeIdForNewAndPrerareForBackend = (data: any[]) => data
.filter(({id}) => typeof id === 'string')
.map(({value, ...rest}) => ({
    ...rest,
    name: value
}))
.map(({id, ...rest}) => {
    return typeof id === 'string' ? rest : Object.assign(rest, {id}); 
})

export const filterUnsavedItems = (data: any[]) => data.filter(({id}) => typeof id === 'string');