import { SettingValues } from './../pages/tourDetails/Settings/components/SettingTag/SettingTag';
import { stateClassOption, ticketSalesOption, tripleOption } from '../constant/setting';

const getBool = (arg: string | boolean): boolean => {
    if(typeof arg === 'boolean') return arg;

    return arg === 'true';
};

// TO DO: Working. Added double translation for safety. Rework after demo
export const settingTagTypeConverter = (settingValues: SettingValues, translationHook: (key: string) => string): any => {

    return {
        settings: {
            keywordSport: Number(settingValues.keywordSport),
            tour: Number(settingValues.tour),
            keywordTournamentType: Number(settingValues.keywordTournamentType),
            status: tripleOption.findIndex((option) => translationHook(option) === translationHook(settingValues.status)),
            showOnPublicPage: getBool(settingValues.showOnPublicPage),
            liveStreaming: getBool(settingValues.liveStreaming),
            liveStreamingLink: settingValues.liveStreamingLink,
            isRating: getBool(settingValues.isRating),
            ratingType: Number(settingValues.ratingType),
            isRanking: getBool(settingValues.isRanking),
            rankingType: Number(settingValues.rankingType),
            isCanRecordScores: getBool(settingValues.isCanRecordScores),
            // matchPoints: Number(settingValues.matchPoints),
        },
        isDiscount: getBool(settingValues.isDiscount),
        discount1:  Number(settingValues.discount1),
        discount2:  Number(settingValues.discount2),
        discount3:  Number(settingValues.discount3),
        classSettings: {
            stateOfClass: Boolean(
                stateClassOption.findIndex((state) => translationHook(state) === translationHook(settingValues.stateOfClass)),
            ),
            keywordBall: Number(settingValues.keywordBall),
            keywordSurface: Number(settingValues.keywordSurface),
            isTicketSales: Boolean(
                ticketSalesOption.findIndex((state) => translationHook(state) === translationHook(settingValues.isTicketSales)),
            ),
            isShowSalesButton: getBool(settingValues.isShowSalesButton),
            minPlayers: Number(settingValues.minPlayers),
            maxPlayers: Number(settingValues.maxPlayers),
            price: Number(settingValues.price),
            vat: Number(settingValues.vat),
        },
    };
};
