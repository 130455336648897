import { fetchOrganizationAPI } from './../../apis/organizations';
import { AppDispatch } from '../../app/store';
import { deleteTournament } from '../../apis/tournament';
import {
    tournamentsFetching,
    tournamentsFetchingError,
    tournamentsFetchingSuccess,
    tournamentDeleteSuccess,
} from './tournamentsSlice';
import { clearTournament } from '../tournament/tournamentSlice';
import { setStats } from '../organizations/organizationsSlice';

export const fetchTournaments = (communityId: number | string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tournamentsFetching());
        dispatch(clearTournament());
        const { tournaments, stats } = await fetchOrganizationAPI(communityId);
        dispatch(setStats(stats));
        dispatch(tournamentsFetchingSuccess(tournaments));
    } catch (e) {
        const error = e as Error;
        dispatch(tournamentsFetchingError(error.message));
    }
};

export const deleteTournaments = (id: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tournamentsFetching());
        const data = await deleteTournament(id);
        dispatch(tournamentDeleteSuccess(data));
    } catch (e) {
        const error = e as Error;
        dispatch(tournamentsFetchingError(error.message));
    }
};
