import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ModalBox } from "./ModalBox";

interface ConfirmModalProps {
    children?: JSX.Element;
    opened?: boolean;
    onHide?: () => void; 
    onConfirm: () => void;
    text?: string;
    disabled?: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({onConfirm, children, opened, onHide, text, disabled}) => {
    const prefix = 'modal-';
    const btnPrefix = 'button-'
    
    const [isOpen, setIsOpen] = useState(opened || false);
    const { t } = useTranslation();

    const openModal = () => !disabled && setIsOpen(true);

    const closeModal = () => {
        onHide && onHide();
        setIsOpen(false);
    };

    const confirm = () => {
        onConfirm();
        closeModal();
    }

    useEffect(() => {
        setIsOpen(!!opened);
    }, [opened]);

   return (<>
    {children && (<div onClick={openModal}>
        {children}
    </div>)}

    <ModalBox show={isOpen} handleSave={confirm} size="sm" onHide={closeModal} btnText={t(btnPrefix + 'confirm')} withoutMinHeight={true}>
        <h5>{text || t(prefix + 'confirmBody')}</h5>
    </ModalBox>
    </>)
};